import Vue from "vue";
import Vuex from "vuex";

import { authModule } from "@/store/authStore.js";
import { spreadsheetUploadModule } from "@/store/spreadsheetUploadStore.js";
import { dataUploadModule } from "@/store/dataUploadStore.js";
import { invoiceBotModule } from "@/store/invoiceBotStore.js";
import { myCueModule } from "@/store/myCueStore.js";
import { editorModule } from "@/store/editorStore.js";
import { assignmentModule } from "@/store/assignmentStore.js";
import { flashMessageModule } from "@/store/flashMessageStore.js";
import { projectsModule } from "@/store/projectsStore.js";
import { settingsModule } from "@/store/settingsStore";
import { nativeFormsModule } from "@/store/nativeFormStore";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: {
      namespaced: true,
      ...authModule,
    },
    spreadsheetUpload: {
      namespaced: true,
      ...spreadsheetUploadModule,
    },
    dataUpload: {
      namespaced: true,
      ...dataUploadModule,
    },
    invoiceBot: {
      namespaced: true,
      ...invoiceBotModule,
    },
    myCue: {
      namespaced: true,
      ...myCueModule,
    },
    editor: {
      namespaced: true,
      ...editorModule,
    },
    assignment: {
      namespaced: true,
      ...assignmentModule,
    },
    flashMessage: {
      namespaced: true,
      ...flashMessageModule,
    },
    projects: {
      namespaced: true,
      ...projectsModule,
    },
    settings: {
      namespaced: true,
      ...settingsModule,
    },
    nativeForms: {
      namespaced: true,
      ...nativeFormsModule,
    },
  },
});
