export const authModule = {
  state: () => ({
    user: {},
    profileModal: false,
    loginFailed: false,
    isAdmin: false,
    isManager: false,
    isDubEditor: false,
  }),
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    HANDLE_PROFILE_MODAL(state, profileModal) {
      state.profileModal = profileModal;
    },
    SET_LOGIN_FAILED(state, loginFailed) {
      state.loginFailed = loginFailed;
    },
    SET_IS_ADMIN(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    SET_IS_MANAGER(state, isManager) {
      state.isManager = isManager;
    },
    SET_IS_DUB_EDITOR(state, isDubEditor) {
      state.isDubEditor = isDubEditor;
    },
  },
  actions: {
    setUser(context, user) {
      context.commit("SET_USER", user);
    },
    handleProfileModal(context, profileModal) {
      context.commit("HANDLE_PROFILE_MODAL", profileModal);
    },
    setLoginFailed(context, loginFailed) {
      context.commit("SET_LOGIN_FAILED", loginFailed);
    },
    setIsAdmin(context, isAdmin) {
      context.commit("SET_IS_ADMIN", isAdmin);
    },
    setIsManager(context, isManager) {
      context.commit("SET_IS_MANAGER", isManager);
    },
    setIsDubEditor(context, isDubEditor) {
      context.commit("SET_IS_DUB_EDITOR", isDubEditor);
    },
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    profileModal: (state) => {
      return state.profileModal;
    },
    profileLock: (state) => {
      const requiredFields = [
        { dto: "user", key: "email" },
        { dto: "user", key: "firstName" },
        { dto: "user", key: "lastName" },
        { dto: "personalDetails", key: "preferredName" },
        { dto: "personalDetails", nested: "language1", key: "language" },
        { dto: "personalDetails", key: "bio" },
        { dto: "hrDetails", key: "country" },
        { dto: "hrDetails", key: "homeAddress" },
        { dto: "hrDetails", key: "legalName" },
        { dto: "hrDetails", key: "taxResidency" },
        { dto: "skill", key: "copyediting" },
        { dto: "skill", key: "copywriting" },
        { dto: "skill", key: "curation" },
        { dto: "skill", key: "design" },
        { dto: "skill", key: "editingTranslations" },
        { dto: "skill", key: "metadata" },
        { dto: "skill", key: "projectManagement" },
        { dto: "skill", key: "translation" },
        { dto: "generalExpertise", nested: "book", key: "years" },
        { dto: "generalExpertise", nested: "film", key: "years" },
        { dto: "generalExpertise", nested: "lifestyle", key: "years" },
        {
          dto: "generalExpertise",
          nested: "mobileAndVideoGames",
          key: "years",
        },
        { dto: "generalExpertise", nested: "mobileApps", key: "years" },
        { dto: "generalExpertise", nested: "music", key: "years" },
        { dto: "generalExpertise", nested: "podcast", key: "years" },
        { dto: "generalExpertise", nested: "sports", key: "years" },
      ];
      return state.isAdmin || state.user.user.userRole === "CLIENT"
        ? false
        : !requiredFields.every((field) =>
            field.hasOwnProperty("nested")
              ? state.user?.[field.dto][field.nested][field.key]
              : state.user?.[field.dto][field.key]
          );
    },
    loginFailed: (state) => {
      return state.loginFailed;
    },
    isAdmin: (state) => {
      return state.isAdmin;
    },
    isManager: (state) => {
      return state.isManager;
    },
    isDubEditor: (state) => {
      return state.isDubEditor;
    },
  },
};
