<template>
  <v-expansion-panel class="staff-expansion-panel grey lighten-4">
    <v-expansion-panel-header
      v-if="(!xContent || cueLoc) && !shakespeareLoc"
      class="staff-expansion-panel__header"
    >
      <span>
        {{ groupItem }}
        <v-chip class="identifier-count-chip white" small label>
          {{ identifierCount }}
        </v-chip></span
      >
    </v-expansion-panel-header>
    <v-expansion-panel-content
      ref="staffPanel"
      class="staff-expansion-panel__content"
      :class="{ 'pt-5': (xContent && !cueLoc) || shakespeareLoc }"
    >
      <h3 v-if="cueLoc" class="mb-4 text-center">
        {{ staffAssignments[groupItem].assignmentTitle }}
      </h3>
      <div
        v-for="panelItem in panelGroup"
        :key="panelItem"
        class="staff-expansion-panel__content__row"
        :class="[
          shakespeare || backstageCopywriting || cueCopywriting
            ? [
                'flex-wrap',
                'grey',
                'lighten-5',
                'rounded-lg',
                'elevation-1',
                'pt-6',
                'pb-2',
                'mb-4',
                'px-4',
              ]
            : '',
        ]"
      >
        <div
          v-if="shakespeare || backstageCopywriting || cueCopywriting"
          class="pb-4 d-flex justify-center align-center"
        >
          {{ panelItem }}
          <v-btn
            v-if="shakespeare"
            class="white ml-4"
            x-small
            @click="handleApplyToAll(panelItem)"
            >Apply to all [GEO]</v-btn
          >
          <v-btn
            v-if="shakespeare || backstageCopywriting"
            class="white"
            :class="backstageCopywriting ? 'ml-4' : 'ml-2'"
            x-small
            @click="handleApplyToAll(panelItem, true)"
            >Apply to all [Batch]</v-btn
          >
          <v-chip
            v-if="cueCopywriting"
            class="ml-4"
            :color="getStatusData(panelItem).color"
            >{{ getStatusData(panelItem).name }}</v-chip
          >
        </div>
        <v-row class="align-center justify-center">
          <v-col
            v-if="!shakespeare && !backstageCopywriting && !cueCopywriting"
            cols="16"
            sm="1"
            class="text-center"
            >{{ panelItem }}
          </v-col>
          <v-col
            :cols="
              !shakespeare && !backstageCopywriting && !cueCopywriting ? 16 : 12
            "
            :sm="
              !shakespeare && !backstageCopywriting && !cueCopywriting ? 3 : 4
            "
          >
            <v-autocomplete
              label="Primary"
              :items="
                shakespeare || cueCopywriting
                  ? staffDatasForGeo(groupItem, formValues.writingDueDate)
                  : staffDatasForGeo(panelItem, formValues.writingDueDate)
              "
              :value="
                getValue(
                  panelItem,
                  groupItem,
                  'writer',
                  formValues.writingDueDate
                )
              "
              :loading="staffLoading"
              clearable
              item-text="name"
              @change="
                (v) =>
                  handleValueSelectChange(panelItem, 'writer', v, groupItem)
              "
              @click:clear="
                handleOverloadingReset(panelItem, 'writer', groupItem)
              "
              :rules="[rules.availabile('writer')]"
            ></v-autocomplete>
          </v-col>
          <v-col
            :cols="
              !shakespeare && !backstageCopywriting && !cueCopywriting ? 16 : 12
            "
            :sm="
              !shakespeare && !backstageCopywriting && !cueCopywriting ? 3 : 4
            "
          >
            <v-autocomplete
              label="Secondary"
              :items="
                shakespeare || cueCopywriting
                  ? staffDatasForGeo(groupItem, formValues.editingDueDate)
                  : staffDatasForGeo(panelItem, formValues.editingDueDate)
              "
              :value="
                getValue(
                  panelItem,
                  groupItem,
                  'editor',
                  formValues.editingDueDate
                )
              "
              :loading="staffLoading"
              clearable
              item-text="name"
              @change="
                (v) =>
                  handleValueSelectChange(panelItem, 'editor', v, groupItem)
              "
              @click:clear="
                handleOverloadingReset(panelItem, 'editor', groupItem)
              "
              :rules="[rules.availabile('editor')]"
            ></v-autocomplete>
          </v-col>
          <v-col
            :cols="
              !shakespeare && !backstageCopywriting && !cueCopywriting ? 16 : 12
            "
            :sm="
              !shakespeare && !backstageCopywriting && !cueCopywriting ? 3 : 4
            "
          >
            <v-autocomplete
              label="QC"
              :items="
                shakespeare || cueCopywriting
                  ? staffDatasForGeo(groupItem, formValues.reviewingDueDate)
                  : staffDatasForGeo(panelItem, formValues.reviewingDueDate)
              "
              :value="
                getValue(
                  panelItem,
                  groupItem,
                  'reviewer',
                  formValues.reviewingDueDate
                )
              "
              :loading="staffLoading"
              clearable
              item-text="name"
              @change="
                (v) =>
                  handleValueSelectChange(panelItem, 'reviewer', v, groupItem)
              "
              :rules="[rules.availabile('reviewer')]"
            ></v-autocomplete>
          </v-col>
          <v-col
            v-if="
              !xContent &&
              !shakespeareLoc &&
              !shakespeare &&
              !backstageCopywriting &&
              !cueCopywriting
            "
            cols="16"
            sm="2"
            class="text-center"
          >
            <v-btn class="white" x-small @click="handleApplyToAll(panelItem)"
              >Apply to all [GEO]</v-btn
            >
          </v-col>
        </v-row>
        <v-row v-if="shakespeare || backstageCopywriting">
          <v-col v-for="i in [1, 2, 3, 4, 5, 6]" :key="i" cols="12" sm="2">
            <v-autocomplete
              class="py-2"
              :label="`Work Type ${i} Reference`"
              :clearable="i !== 1"
              :items="getReferencesOptions(i, shakespeare ? groupItem : panelItem)"
              :value="getValue(panelItem, groupItem, `workType${i}Reference`)"
              :disabled="adaptationMode(shakespeare ? groupItem : panelItem)"
              @change="
                (v) =>
                  handleValueSelectChange(
                    panelItem,
                    `workType${i}Reference`,
                    v,
                    groupItem
                  )
              "
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="shakespeare || backstageCopywriting">
          <v-col v-for="i in [1, 2, 3, 4, 5, 6]" :key="i" cols="12" sm="2">
            <v-text-field
              class="py-2"
              :label="`Work Type ${i} Quantity`"
              type="number"
              :clearable="i !== 1"
              :value="getValue(panelItem, groupItem, `workType${i}Quantity`)"
              :disabled="adaptationMode(shakespeare ? groupItem : panelItem)"
              :rules="[rules.negativeCheck]"
              @change="
                (v) =>
                  handleValueSelectChange(
                    panelItem,
                    `workType${i}Quantity`,
                    v,
                    groupItem
                  )
              "
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="cueCopywriting">
          <v-col cols="12" sm="6">
            <v-autocomplete
              class="py-2"
              label="Work Type"
              :items="allWorkTypes"
              item-text="workTypeName"
              :loading="workTypesLoading"
              :value="getValue(panelItem, groupItem, 'workType1Reference')"
              @change="
                (v) =>
                  handleValueSelectChange(
                    panelItem,
                    'workType1Reference',
                    v,
                    groupItem
                  )
              "
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              class="py-2"
              label="Quantity"
              type="number"
              :value="getValue(panelItem, groupItem, 'workType1Quantity')"
              :rules="[rules.negativeCheck]"
              @change="
                (v) =>
                  handleValueSelectChange(
                    panelItem,
                    'workType1Quantity',
                    v,
                    groupItem
                  )
              "
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// libraries
import _ from "lodash";
// internal
import { isAdaptation, getFCWorkType } from "@/utils/spreadsheetUploadUtils";
import { STATUS_COLORS } from "@/utils/constants";
import { getCustomerPriceList } from "@/utils/newDbUtils";

export default {
  name: "StaffExpansionPanel",
  props: {
    groupItem: String,
    data: Object,
    staffDatas: Array,
    identifierCount: Number,
    workType1References: Array,
    workType2References: Array,
    workType3References: Array,
    workType4References: Array,
    workType5References: Array,
    workType6References: Array,
  },
  data() {
    return {
      generatorGroupItem: "",
      generatorPanelItem: "",
      workTypesLoading: true,
      allWorkTypes: [],
      newSpecialists: {
        writer: "primary",
        editor: "secondary",
        reviewer: "qc",
      },
      rules: {
        availabile: (specialist) =>
          !this[`${specialist}Unavailable`] ||
          `Unavailable on selected due date for ${this.newSpecialists[specialist]}.`,
        negativeCheck: (value) => value >= 0 || "Quantity can't be nagative.",
      },
    };
  },
  methods: {
    ...mapActions("spreadsheetUpload", [
      "setStaffAssignments",
      "setStaffAssignmentOverloading",
    ]),
    getStatusData(task) {
      if (this.tableRows) {
        const taskData = this.tableRows.find((row) => row.task_name === task);
        const name = taskData.status;
        const color = STATUS_COLORS.find((color) =>
          color.statuses.includes(name)
        )?.value;
        return { name, color };
      }
    },
    getValue(panelItem, groupItem, key, date) {
      const keyItem =
        this.shakespeare || this.cueCopywriting
          ? this.staffAssignments[
              this.findShakespeareAssignment(panelItem, groupItem)
            ]?.[groupItem]?.[key]
          : this.staffAssignments?.[this.groupItem][panelItem]?.[key];

      if (key === "writer" || key === "editor" || key === "reviewer")
        if (keyItem && !this.formValues.overrideStaff) {
          const staffDatas = this.staffDatasForGeo(
            this.shakespeare || this.cueCopywriting
              ? this.groupItem
              : panelItem,
            date
          );
          this[`${key}Unavailable`] = staffDatas.find(
            (el) => el.name === keyItem
          )?.disabled;
        } else this[`${key}Unavailable`] = false;
      this.setStaffAssignmentOverloading({
        taskName:
          this.shakespeare || this.cueCopywriting ? panelItem : this.groupItem,
        geo: this.shakespeare || this.cueCopywriting ? groupItem : panelItem,
        key: `${key}Overloaded`,
        value: this[`${key}Unavailable`],
      });
      if (this.shakespeare || this.cueCopywriting) {
        if (this.shakespeare && key.includes("workType")) {
          return isAdaptation({
            locale: groupItem,
            allLocales: this.tableRows ? this.tableRows.map(el => el.geo) : [],
            tableRows: this.tableRows,
            shakespeare: this.shakespeare,
          })
            ? key === "workType1Reference"
              ? getFCWorkType({
                  requestType: this.tableRows[0].request_type,
                  locale: groupItem,
                  allLocales: this.tableRows.map((el) => el.geo),
                  shakespeare: this.shakespeare,
                  tableRows: this.tableRows,
                })
                : ""
            : keyItem;
        }
        return this.findShakespeareAssignment(panelItem, groupItem, key, true);
      } else {
        if (this.backstageCopywriting && key.includes("workType")) {
          return isAdaptation({
            locale: panelItem,
            allLocales: this.tableRows ? this.tableRows[0].geos : [],
            sourceGeo: this.tableRows[0].source_geo
          })
            ? key === "workType1Reference"
              ? getFCWorkType({
                  locale: panelItem,
                  allLocales: this.tableRows ? this.tableRows[0].geos : [],
                  workType1: true,
                  sourceGeo: this.tableRows[0].source_geo
                })
              : ""
            : keyItem;
        }
        return keyItem;
      }
    },
    referencesByRequestType(panelItem) {
      return this.adaptationMode(panelItem)
        ? [
            getFCWorkType({
              locale: panelItem,
              workType1: true,
              sourceGeo: this.tableRows[0].source_geo,
              requestType: this.tableRows[0].request_type,
              allLocales: this.tableRows && !this.shakespeare ? this.tableRows[0].geos : this.shakespeare ? this.tableRows.map((el) => el.geo) : [],
              shakespeare: this.shakespeare,
              tableRows: this.tableRows,
            }),
          ]
        : this.workType1References;
    },
    adaptationMode(panelItem) {
      return this.backstageCopywriting || this.shakespeare
        ? isAdaptation({
            tableRows: this.tableRows,
            shakespeare: this.shakespeare,
            locale: panelItem,
            allLocales: this.tableRows && !this.shakespeare ? this.tableRows[0].geos : this.shakespeare ? this.tableRows.map((el) => el.geo) : [],
            sourceGeo: this.tableRows[0].source_geo
          })
        : false;
    },
    getReferencesOptions(i, panelItem) {
      if (i === 1) return this.referencesByRequestType(panelItem);
      else if (i === 4)
        return this.prepopulationFlow === 0
          ? ["CWR_1000-380-3800", "CWR_1500-380-3800"]
          : this.workType4References;
      else return this[`workType${i}References`];
    },
    staffDatasForGeo(panelItem, date) {
      if (!this.staffDatas) return ["none"];

      const retVal = this.staffDatas
        .filter((el) => el.geos.includes(panelItem))
        .map(({ name, disabled }) => {
          return { name, disabled };
        });

      if (date)
        retVal.forEach((el) => {
          const unavailablePerson = this.adjustedCapacityPool.find(
            (item) => item.name == el.name && item.dates.includes(date)
          );
          if (unavailablePerson) {
            const workloads = [
                "adjustedEditingWorkload",
                "adjustedWritingWorkload",
              ],
              unavailabilityValidator = workloads.map((item) => {
                if (unavailablePerson[item] == 0) return true;
              });
            unavailabilityValidator.every((item) => item === true) &&
            !this.formValues.overrideStaff
              ? (el.disabled = true)
              : (el.disabled = false);
          } else {
            el.disabled = false;
          }
        });

      return retVal;
    },
    handleValueSelectChange(panelItem, key, value, groupItem) {
      // copy existing object
      const newAssignments = { ...this.staffAssignments };
      // find Shakespeare assignment
      const shakespeareAssignment = this.findShakespeareAssignment(
        panelItem,
        groupItem
      );
      // update in copy
      if (value) {
        newAssignments[
          this.shakespeare || this.cueCopywriting
            ? shakespeareAssignment
            : this.groupItem
        ][this.shakespeare || this.cueCopywriting ? groupItem : panelItem][
          key
        ] = value;
      } else {
        newAssignments[
          this.shakespeare || this.cueCopywriting
            ? shakespeareAssignment
            : this.groupItem
        ][this.shakespeare || this.cueCopywriting ? groupItem : panelItem][
          key
        ] = null;
      }
      // update global state object
      this.setStaffAssignments(newAssignments);
      // reset the field unavailability error
      if (this.backstageCopywriting)
        this.resetSpecialistAvailabilityStatus(panelItem, key);
    },
    handleApplyToAll(panelItem, batchMode) {
      // copy existing object
      const newAssignments = { ...this.staffAssignments };

      // update in copy
      const assignmentsList = _.keys(newAssignments);

      const keysArray = [
        "workType1Reference",
        "workType2Reference",
        "workType3Reference",
        "workType4Reference",
        "workType5Reference",
        "workType6Reference",
        "workType1Quantity",
        "workType2Quantity",
        "workType3Quantity",
        "workType4Quantity",
        "workType5Quantity",
        "workType6Quantity",
      ];

      assignmentsList.forEach((assignment) => {
        const words = newAssignments[assignment]?.wordCount;
        delete newAssignments[assignment].wordCount;
        if (this.shakespeare || this.cueCopywriting) {
          if (batchMode) {
            const allGeos = [
              ...new Set(
                this.tableRows.map((el) => {
                  return el.geo;
                })
              ),
            ];
            allGeos.forEach((glGeo) => {
              if (newAssignments[assignment][glGeo])
                keysArray.forEach((key) => {
                  newAssignments[assignment][glGeo][key] =
                    newAssignments[
                      this.findShakespeareAssignment(panelItem, this.groupItem)
                    ][this.groupItem][key];
                });
            });
          } else {
            keysArray.push("editor", "reviewer");
            if (newAssignments[assignment][this.groupItem])
              keysArray.forEach((key) => {
                newAssignments[assignment][this.groupItem][key] =
                  newAssignments[
                    this.findShakespeareAssignment(panelItem, this.groupItem)
                  ][this.groupItem][key];
              });
          }
        } else if (this.backstageCopywriting) {
          const geos = Object.keys(newAssignments[assignment]);
          keysArray.forEach((key) => {
            geos.forEach((geo) => {
              if (!isAdaptation({
                locale: geo,
                allLocales: this.tableRows ? this.tableRows[0].geos : [],
                sourceGeo: this.tableRows[0].source_geo
              }))
              newAssignments[assignment][geo][key] =
                this.staffAssignments[assignment][panelItem][key];
            });
          });
        } else {
          if (newAssignments[assignment][panelItem]) {
            newAssignments[assignment][panelItem]["writer"] =
              newAssignments[this.groupItem][panelItem]["writer"];
            newAssignments[assignment][panelItem]["editor"] =
              newAssignments[this.groupItem][panelItem]["editor"];
            newAssignments[assignment][panelItem]["reviewer"] =
              newAssignments[this.groupItem][panelItem]["reviewer"];
          }
        }
        newAssignments[assignment].wordCount = words;
      });

      // update global state object
      this.setStaffAssignments(newAssignments);
    },
    findShakespeareAssignment(panelItem, groupItem, key, valueOnly) {
      let searchedAssignment;
      Object.entries(this.staffAssignments).forEach((el) => {
        if (el[1].hasOwnProperty(groupItem) && el[1].task_name === panelItem)
          searchedAssignment = el[0];
      });
      return valueOnly
        ? this.staffAssignments[searchedAssignment]?.[groupItem][key]
        : searchedAssignment;
    },
    handleOverloadingReset(panelItem, role, groupItem) {
      this.shakespeare || this.cueCopywriting
        ? (this.staffAssignments[
            this.findShakespeareAssignment(panelItem, groupItem)
          ][groupItem][`${role}Overloaded`] = false)
        : (this.staffAssignments[groupItem][panelItem][
            `${role}Overloaded`
          ] = false);
    },
    resetSpecialistAvailabilityStatus(geo, specialist) {
      this[`${specialist}Unavailable`] = false;
      if (
        specialist === "writer" ||
        specialist === "editor" ||
        specialist === "reviewer"
      )
        this.setStaffAssignmentOverloading({
          taskName:
            this.shakespeare || this.cueCopywriting ? geo : this.groupItem,
          geo: this.shakespeare || this.cueCopywriting ? this.groupItem : geo,
          key: `${specialist}Overloaded`,
          value: this[`${specialist}Unavailable`],
        });
    },
  },
  computed: {
    ...mapGetters("spreadsheetUpload", [
      "staffAssignments",
      "staffLoading",
      "tableRows",
      "formValues",
      "adjustedCapacityPool",
      "xContent",
      "shakespeare",
      "shakespeareLoc",
      "backstageCopywriting",
      "cueLoc",
      "cueCopywriting",
      "prepopulationFlow",
    ]),
    panelGroup() {
      return this.shakespeare || this.cueCopywriting
        ? _.keys(this.data)
        : _.without(_.keys(this.data), "wordCount", "assignmentTitle");
    },
  },
  mounted() {
    if ((this.xContent && !this.cueLoc) || this.shakespeareLoc)
      this.$refs.staffPanel?.isActive
        ? (this.$refs.staffPanel.isActive = !this.$refs.staffPanel.isActive)
        : (this.$refs.staffPanel.isActive = true);
    if (this.cueCopywriting)
      getCustomerPriceList().then((data) => {
        this.allWorkTypes = data;
        this.workTypesLoading = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.staff-expansion-panel {
  &__header {
    font-weight: 500;
    .identifier-count-chip {
      margin-left: 10px;
    }
  }
}
.v-chip {
  overflow: visible;
}
</style>
