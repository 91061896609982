<template>
  <v-app>
    <NavBar />
    <FlashMessage />
    <v-main
      :class="{ 
        'grey lighten-4': $route.name !== 'Reimbursement Request Form' 
          || $route.name !== 'Request an Assignment'
          || $route.name !== 'Vendor Invoice Form',
        'grey darken-2': $route.name === 'Reimbursement Request Form' 
          || $route.name === 'Request an Assignment'
          || $route.name === 'Vendor Invoice Form'
        }">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// vuex
import { mapActions } from "vuex";
// internal
import "@/styles/styles.scss";
import { validateToken, refreshSession } from "@/utils/axiosInstance";
// components
import NavBar from "@/components/ui/NavBar";
import FlashMessage from "@/components/ui/FlashMessage";

export default {
  name: "App",
  components: { NavBar, FlashMessage },
  methods: {
    ...mapActions("auth", [
      "setIsAdmin",
      "setIsManager",
      "setUser",
      "setIsDubEditor",
    ]),
    ...mapActions("flashMessage", ["handleFlash"]),
    dropSession() {
      this.setUser(null);
      this.setIsAdmin(false);
      this.setIsManager(false);
      this.setIsDubEditor(false);
    },
  },
  mounted() {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const handleAuth = (auth) => {
      this.setIsAdmin(auth.data.user.userRole === "ADMIN");
      this.setIsManager(auth.data.user.userRole === "MANAGER");
      this.setIsDubEditor(!!auth.data?.adminDetails?.dubEditorAccess);
      this.setUser(auth.data);
      if (this.$route.path === "/login") {
        if (this.$route?.query?.to) this.$router.push(this.$route.query.to);
        else this.$router.push("/");
      }
    };
    if (!token) {
      this.dropSession();
      if (this.$route.path !== "/login") this.$router.push("/login");
    } else {
      validateToken(token).then((validResp) => {
        if (validResp.status === 200) {
          handleAuth(validResp);
        } else {
          refreshSession(refreshToken).then((refResp) => {
            if (refResp.status === 200) {
              localStorage.setItem("token", refResp.data.accessToken);
              validateToken(refResp.data.accessToken).then((refResp) => {
                handleAuth(refResp);
              });
            } else {
              this.dropSession();
              if (this.$route.path !== "/login") this.$router.push("/login");
              this.handleFlash({ response: refResp, show: true });
            }
          });
        }
      });
    }
  },
};
</script>
