<template>
  <v-card>
    <v-form ref="form" v-model="valid" lazy-validation class="pa-6">
      <v-container>
        <v-row>
          <v-col cols="12" sm="4" class="pb-0">
            <v-menu
              v-model="pcRequestDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedPcRequestDate"
                  label="Request Date"
                  prepend-icon="mdi-calendar-edit"
                  readonly
                  :error="requestConversionErrors"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="pcRequestDate"
                @input="pcRequestDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-menu
              ref="pcRequestTimeMenu"
              v-model="pcRequestTimeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="pcRequestTime"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="pcRequestTime12hrs"
                  label="Request Time"
                  prepend-icon="mdi-clock-edit-outline"
                  readonly
                  :error="requestConversionErrors"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="pcRequestTime"
                full-width
                format="ampm"
                @click:minute="$refs.pcRequestTimeMenu.save(pcRequestTime)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-autocomplete
              class="pc-time-zone"
              outlined
              append-icon="mdi-account-clock"
              label="Your Time Zone"
              :error="requestConversionErrors"
              v-model="pcTimeZone"
              :items="utcTimeZones"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" class="d-flex justify-center pb-0">
            <v-btn
              width="auto"
              height="56px"
              color="accent"
              outlined
              @click="convertDues(true)"
              >Convert to ET</v-btn
            >
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-menu
              v-model="requestDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedRequestDate"
                  :rules="[rules.required]"
                  label="Request Date (ET)"
                  prepend-icon="mdi-calendar-plus"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="requestDate"
                @input="requestDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-menu
              ref="requestTimeMenu"
              v-model="requestTimeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="requestTime"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="requestTime12hrs"
                  :rules="[rules.required]"
                  label="Request Time (ET)"
                  prepend-icon="mdi-clock-plus-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="requestTime"
                full-width
                format="ampm"
                @click:minute="$refs.requestTimeMenu.save(requestTime)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="pb-0">
            <v-text-field
              outlined
              label="Task ID"
              v-model="taskID"
              :disabled="
                isEditMode && itemToEdit.cueTaskId.split('_').length > 2
              "
            />
          </v-col>
          <v-col cols="12" sm="6" class="pb-0">
            <v-text-field
              outlined
              label="Cue Task ID"
              readonly
              v-model="cueTaskID"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-textarea
              outlined
              clearable
              rows="1"
              clear-icon="mdi-close-circle-outline"
              label="Task / Email Subject Line"
              v-model="emailSubjectLine"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" class="pb-0">
            <v-autocomplete
              outlined
              label="Related Project"
              :items="projects"
              v-model="relatedProject"
              item-text="name"
              return-object
              :rules="[rules.required]"
              @change="updateRelatedProjectSmartFields($event)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-autocomplete
              outlined
              label="Related Client Requester"
              :item-text="fullName"
              return-object
              :items="clientRequesters"
              v-model="clientRequester"
              :rules="[rules.requiredObject]"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-autocomplete
              outlined
              label="Media Type"
              :items="mediaTypes"
              v-model="mediaType"
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" class="pb-0">
            <v-autocomplete
              outlined
              label="DRI"
              :item-text="fullName"
              return-object
              :items="employees"
              v-model="dri"
              :rules="[rules.requiredObject]"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-autocomplete
              outlined
              label="Task Status"
              :items="taskStatuses"
              v-model="status"
              item-text="value"
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-autocomplete
              outlined
              label="Priority"
              :items="priorities"
              v-model="priority"
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" class="pb-0">
            <v-menu
              v-model="clientDeliveryDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedClientDeliveryDate"
                  label="Delivery Date (Client)"
                  prepend-icon="mdi-calendar"
                  readonly
                  :error="conversionErrors"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="clientDeliveryDate"
                @input="clientDeliveryDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-select
              :items="hoursList"
              v-model="clientDeliveryDue"
              item-text="time"
              label="Delivery Time (Client)"
              prepend-icon="mdi-clock-time-five-outline"
              :error="conversionErrors"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-autocomplete
              outlined
              append-icon="mdi-airplane-clock"
              label="Time Zone (Client)"
              :error="conversionErrors"
              v-model="timeZone"
              :items="utcTimeZones"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" class="d-flex justify-center pb-0">
            <v-btn
              width="auto"
              height="56px"
              color="accent"
              outlined
              @click="convertDues()"
              >Convert to ET</v-btn
            >
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-menu
              v-model="deliveryDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedDeliveryDate"
                  :rules="[rules.required]"
                  label="Delivery Date (ET)"
                  prepend-icon="mdi-calendar-check"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="deliveryDate"
                @input="deliveryDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-select
              :items="hoursList"
              v-model="deliveryDue"
              item-text="time"
              :rules="[rules.required]"
              label="Delivery Time (ET)"
              prepend-icon="mdi-clock-check-outline"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-card-actions class="justify-end pb-6">
      <v-btn
        v-if="Object.keys(itemToEdit).length"
        class="ma-1"
        height="36px"
        small
        color="secondary"
        :loading="deleteProcessing"
        @click="handletaskDeletion()"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        width="20%"
        color="secondary"
        @click="handleTask()"
        :loading="updateProcessing"
      >
        {{ Object.keys(itemToEdit).length ? "Update" : "Save" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import { processTask, deleteTask } from "@/utils/newDbUtils";
import { timeUnits } from "@/utils/mixins";

export default {
  name: "PMDashboardForm",
  props: {
    mediaTypes: {
      type: Array,
      required: true,
    },
    taskStatuses: {
      type: Array,
      required: true,
    },
    priorities: {
      type: Array,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
    projectsList: {
      type: Array,
      required: true,
    },
    itemToEdit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      requestDateMenu: false,
      requestDate: this.$moment(
        new Date().toLocaleString("en-US", {
          timeZone: "America/New_York",
        })
      ).format("YYYY-MM-DD"),
      requestTime: this.$moment(
        new Date().toLocaleString("en-US", {
          timeZone: "America/New_York",
        })
      ).format("HH:mm"),
      requestTimeMenu: false,
      projects: [],
      relatedProject: null,
      clientRequester: null,
      clientDeliveryDateMenu: false,
      clientDeliveryDate: null,
      clientDeliveryDue: null,
      emailSubjectLine: null,
      mediaType: null,
      taskID: null,
      status: "Confirmed",
      priority: null,
      dri: null,
      timeZone: null,
      deliveryDateMenu: false,
      deliveryDate: null,
      deliveryDue: null,
      conversionErrors: false,
      requestConversionMode: false,
      pcRequestDateMenu: false,
      pcRequestDate: null,
      pcRequestTimeMenu: false,
      pcRequestTime: null,
      pcTimeZone: null,
      requestConversionErrors: false,
      updateProcessing: false,
      deleteProcessing: false,
      valid: true,
      rules: {
        required: (value) => !!value || value === 0 || "Required",
        requiredObject: (value) =>
          (value ? !!Object.keys(value).length : !!value) || "Required",
      },
    };
  },
  mounted() {
    this.handleITunesProject();
    this.handleEditMode();
  },
  watch: {
    itemToEdit() {
      this.handleEditMode();
    },
  },
  mixins: [timeUnits],
  computed: {
    ...mapGetters("auth", ["user"]),
    formattedRequestDate: {
      get() {
        return this.formatPickerDate(this.requestDate);
      },
      set() {
        this.requestDate = null;
      },
    },
    formattedPcRequestDate: {
      get() {
        return this.formatPickerDate(this.pcRequestDate);
      },
      set() {
        this.pcRequestDate = null;
      },
    },
    formattedClientDeliveryDate: {
      get() {
        return this.formatPickerDate(this.clientDeliveryDate);
      },
      set() {
        this.clientDeliveryDate = null;
      },
    },
    formattedDeliveryDate: {
      get() {
        return this.formatPickerDate(this.deliveryDate);
      },
      set() {
        this.deliveryDate = null;
      },
    },
    clientRequesters() {
      return this.relatedProject?.clientRequesters
        ?.filter((el) => el.active)
        ?.sort((a, b) => a?.user?.firstName?.localeCompare(b?.user?.firstName));
    },
    cueTaskID() {
      return this.isEditMode
        ? this.itemToEdit.cueTaskId.split("_").length === 2
          ? `${this.requestDate}_${this.taskID}`
          : `${this.requestDate}_${this.requestTime}_${
              this.itemToEdit.cueTaskId.split("_")[2]
            }`
        : `${this.requestDate}_${
            this.taskID
              ? this.taskID
              : Math.floor(100000 + Math.random() * 900000)
          }`;
    },
    isEditMode() {
      return !!Object.keys(this.itemToEdit).length;
    },
    pcRequestTime12hrs() {
      return this.pcRequestTime
        ? this.$moment(this.pcRequestTime, ["HH:mm"]).format("hh:mm A")
        : null;
    },
    requestTime12hrs() {
      return this.requestTime
        ? this.$moment(this.requestTime, ["HH:mm"]).format("hh:mm A")
        : null;
    },
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    fullName(item) {
      return `${item?.user?.firstName} ${item?.user?.lastName}`;
    },
    handleITunesProject() {
      this.projects = this.projectsList.filter((el) => el.name === "iTunes FC");
    },
    updateRelatedProjectSmartFields(selectedProject, itemToEditHandling) {
      if (selectedProject) {
        this.clientRequester = itemToEditHandling
          ? this.clientRequesters.find(
              (el) => el?.user?.id === this.itemToEdit.clientRequester?.user?.id
            )
          : null;
      } else {
        if (!itemToEditHandling) this.clientRequester = null;
      }
    },
    convertDues(requestMode) {
      if (
        !this[`${requestMode ? "pcRequest" : "clientDelivery"}Date`] ||
        !this[`${requestMode ? "pcRequestTime" : "clientDeliveryDue"}`] ||
        !this[`${requestMode ? "pcT" : "t"}imeZone`]
      ) {
        this[`${requestMode ? "requestC" : "c"}onversionErrors`] = true;
      } else {
        this[`${requestMode ? "requestC" : "c"}onversionErrors`] = false;
        const data = new Date(
          `${this[`${requestMode ? "pcRequest" : "clientDelivery"}Date`]}T${
            requestMode
              ? this.pcRequestTime
              : this.$moment(this.clientDeliveryDue, "hh:mm A").format("HH:mm")
          }:00.000${this[`${requestMode ? "pcT" : "t"}imeZone`]
            .split(" ")
            .at(-1)}`
        );
        const convertedData = data.toLocaleString("en-US", {
          timeZone: "America/New_York",
        });
        this[`${requestMode ? "request" : "delivery"}Date`] =
          this.$moment(convertedData).format("YYYY-MM-DD");
        requestMode
          ? (this.requestTime = this.$moment(convertedData).format("HH:mm"))
          : (this.deliveryDue = data.toLocaleString("en-US", {
              timeZone: "America/New_York",
              hour: "2-digit",
              minute: "2-digit",
            }));
      }
    },
    handleTask() {
      if (this.$refs.form.validate()) {
        this.updateProcessing = true;
        const data = {
          projectId: this.relatedProject.id,
          clientRequesterId: this.clientRequester.user.id,
          driId: this.dri.user.id,
          cueTaskId: this.cueTaskID,
          deliveryDate: this.deliveryDate,
          deliveryTime: this.$moment(this.deliveryDue, ["hh:mm A"]).format(
            "HH:mm:ss"
          ),
          task: this.emailSubjectLine,
          mediaType: this.mediaType,
          priority: this.priority,
          requestDate: this.requestDate,
          requestTime: this.$moment(this.requestTime12hrs, ["hh:mm A"]).format(
            "HH:mm:ss"
          ),
          taskId: this.taskID,
          taskStatus: this.status,
          qc: this.itemToEdit?.qc ?? false,
          validation: this.itemToEdit?.validation ?? null,
        };
        processTask({ data, id: this.itemToEdit?.id })
          .then((resp) => {
            this.updateProcessing = false;
            this.$emit("close-task-form");
            this.handleFlash({ response: resp, show: true });
          })
          .catch((err) => {
            this.updateProcessing = false;
            console.error(err);
            this.handleFlash({ response: err, show: true });
          });
      }
    },
    handletaskDeletion() {
      this.deleteProcessing = true;
      deleteTask(this.itemToEdit.id)
        .then((resp) => {
          this.deleteProcessing = false;
          this.$emit("close-task-form");
          this.handleFlash({ response: resp, show: true });
        })
        .catch((err) => {
          this.deleteProcessing = false;
          console.error(err);
          this.handleFlash({ response: err, show: true });
        });
    },
    handleEditMode() {
      if (!this.isEditMode) this.$refs.form.resetValidation();
      this.relatedProject = this.isEditMode
        ? this.projects.find((el) => el.id === this.itemToEdit.project?.id) ??
          null
        : null;
      this.clientRequester = this.isEditMode
        ? this.relatedProject?.clientRequesters?.find(
            (el) => el.user.id === this.itemToEdit.clientRequester?.user?.id
          ) ?? null
        : null;
      this.dri =
        this.employees.find(
          (el) =>
            el.user.id ===
            (this.isEditMode
              ? this.itemToEdit.dri?.user?.id
              : this.user.user.id)
        ) ?? null;
      this.deliveryDate = this.isEditMode ? this.itemToEdit.deliveryDate : null;
      this.deliveryDue = this.isEditMode
        ? this.$moment(`2023-12-05 ${this.itemToEdit.deliveryTime}`, ["YYYY-MM-DD hh:mm A"]).format(
            "hh:mm A"
          )
        : null;
      this.emailSubjectLine = this.isEditMode ? this.itemToEdit.task : null;
      this.mediaType = this.isEditMode ? this.itemToEdit.mediaType : null;
      this.priority = this.isEditMode ? this.itemToEdit.priority : null;
      this.requestDate = this.isEditMode
        ? this.itemToEdit.requestDate
        : this.$moment(
            new Date().toLocaleString("en-US", {
              timeZone: "America/New_York",
            })
          ).format("YYYY-MM-DD");
      this.requestTime = this.isEditMode
        ? this.$moment(this.itemToEdit.requestTime, ["HH:mm:ss"]).format(
            "HH:mm"
          )
        : this.$moment(
            new Date().toLocaleString("en-US", {
              timeZone: "America/New_York",
            })
          ).format("HH:mm");
      this.taskID = this.isEditMode ? this.itemToEdit.taskId : null;
      this.status = this.isEditMode ? this.itemToEdit.taskStatus : "Confirmed";
    },
  },
};
</script>

<style>
.pc-time-zone.v-autocomplete.v-select--is-menu-active
  .v-input__icon--append
  .v-icon {
  transform: none;
}
</style>
