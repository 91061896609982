// vuex
import store from "@/store/index";
// internal
import {
  getFCWorkType,
  getFCWordCount,
  getFCRequestType,
  isAdaptation,
} from "./spreadsheetUploadUtils";
import { amountOfEngilshAdaptations } from "./assignmentUtils";
// libraries
const { GoogleSpreadsheet } = require("google-spreadsheet");
import _ from "lodash";
import moment from "moment";

export const SPREADSHEET_ID = "1oCg59Zp6OoDUjZhUdbkGm_oE_rfX5MjoyHXD969sQSY";

const tabName = "Tool Import";

const headerCols = [
  "Related Purchase Order",
  "Related Project",
  "Pay Period",
  "Request Date",
  "E-mail Subject Line",
  "Task ID",
  "Content ID",
  "Assignment ID",
  "Related Client Requester",
  "Assignment Title",
  "Assignment Detail",
  "Source GEO",
  "GEO",
  "Media Type",
  "Keyword",
  "Request Type",
  "Word Count",
  "Work Type 1 Reference",
  "Work Type 1 Quantity",
  "Work Type 2 Reference",
  "Work Type 2 Quantity",
  "Work Type 3 Reference",
  "Work Type 3 Quantity",
  "Work Type 4 Reference",
  "Work Type 4 Quantity",
  "Work Type 5 Reference",
  "Work Type 5 Quantity",
  "Work Type 6 Reference",
  "Work Type 6 Quantity",
  "Related Writer",
  "Writing Due Date",
  "Writing Due (Time of Day)",
  "Related Editor",
  "Editing Due Date",
  "Editing Due (Time of Day)",
  "Reviewer",
  "Reviewing Due Date",
  "Reviewing Due (Time of Day)",
  "Delivery Date",
  "Delivery Time",
  "File Location",
  "Link",
  "Assignment Status",
];

const getRowValues = (props) => {
  const { formValues, tableRow, geoVal, staffAssignments, tableRows } = props,
    shakespeare = store.getters["spreadsheetUpload/shakespeare"],
    shakespeareLoc = store.getters["spreadsheetUpload/shakespeareLoc"],
    backstageCopywriting =
      store.getters["spreadsheetUpload/backstageCopywriting"],
    newAssignmentForm = tableRow.hasOwnProperty("document"),
    geo = shakespeareLoc ? geoVal.geo : geoVal,
    getGeoIndex = (geo, array) =>
      array.findIndex((el) => el.hasOwnProperty(geo)),
    geoIndividualData = newAssignmentForm
      ? null
      : formValues.individualGeosDeadlines[
          getGeoIndex(geo, formValues.individualGeosDeadlines)
        ]?.[geo];
  const rowInfo = [
    {
      col: "Related Purchase Order",
      value: newAssignmentForm
        ? tableRow.pricing.purchaseOrder.purchaseOrderNumber
        : formValues.po?.purchaseOrderNumber,
    },
    {
      col: "Related Project",
      value: newAssignmentForm
        ? tableRow.project.name
        : formValues.relatedProject?.name,
    },
    {
      col: "Pay Period",
      value: newAssignmentForm
        ? tableRow.schedule.payPeriod
        : formValues.payPeriod,
    },
    {
      col: "Request Date",
      value: newAssignmentForm
        ? tableRow.schedule.requestDate
        : moment(tableRow.request_date).format("YYYY-MM-DD"),
    },
    {
      col: "E-mail Subject Line",
      value: shakespeare
        ? formValues.emailSubjectLine === "Assignment Detail"
          ? tableRow.task_name
          : formValues.emailSubjectLine
        : newAssignmentForm
        ? tableRow.assignmentDetails.emailSubjectLine
        : tableRow.subject_line,
    },
    {
      col: "Task ID",
      value: newAssignmentForm
        ? tableRow.assignmentDetails.taskId
        : tableRow["task_id"],
    },
    {
      col: "Content ID",
      value:
        shakespeare || shakespeareLoc
          ? tableRow?.content_id ?? tableRow?.video_content_id
          : tableRow?.assignmentDetails?.contentId ?? "",
    },
    {
      col: "Assignment ID",
      value: shakespeare
        ? ""
        : newAssignmentForm
        ? tableRow.assignmentDetails.assignmentId
        : staffAssignments[tableRow["task_name"]][geo]["assignmentId"] ?? "",
    },
    {
      col: "Related Client Requester",
      value:
        shakespeare || shakespeareLoc
          ? formValues.clientRequester
          : newAssignmentForm
          ? (tableRow.clientRequester ?`${tableRow.clientRequester.user.firstName} ${tableRow.clientRequester.user.lastName}` : "")
          : tableRow["client_requester"],
    },
    {
      col: "Assignment Title",
      value: newAssignmentForm
        ? tableRow.assignmentDetails.assignmentTitle
        : formValues.assignmentTitle,
    },
    {
      col: "Assignment Detail",
      value: shakespeareLoc
      ? tableRow.true_task_name
      : newAssignmentForm
        ? tableRow.assignmentDetails.assignmentDetail
        : tableRow.task_name,
    },
    {
      col: "Source GEO",
      value: tableRow.request_type === "Adaptation" || isAdaptation({locale: geo, allLocales: tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type})
        ? tableRow.source_geo
        : shakespeare
        ? ""
        : backstageCopywriting
        ? isAdaptation({
            locale: geo,
            allLocales: tableRow.geos,
          })
          ? isAdaptation({
              locale: geo,
              getSourceLocale: true,
            })
          : ""
        : newAssignmentForm
        ? tableRow.assignmentDetails.sourceGeo
        : tableRow["source_geo"],
    },
    { col: "GEO", value: geo },
    {
      col: "Media Type",
      value: shakespeare
        ? formValues.mediaType
        : newAssignmentForm
        ? tableRow.assignmentDetails.mediaType
        : tableRow["media_type"],
    },
    { col: "Keyword", value: tableRow.keyword ? tableRow.keyword : tableRow?.assignmentDetails?.keyword },
    {
      col: "Request Type",
      value: backstageCopywriting
        || (shakespeare && isAdaptation({
            locale: geo,
            allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,
            sourceGeo: tableRow.source_geo,
            requestType: tableRow.request_type,
            tableRows: tableRows,
            shakespeare: shakespeare,
          }))
        ? getFCRequestType({
            locale: geo,
            allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,
            requestType: tableRow["request_type"],
            sourceGeo: tableRow.source_geo,
            tableRows: tableRows,
            shakespeare: shakespeare,
          })
        : newAssignmentForm
        ? tableRow.assignmentDetails.requestType
        : isAdaptation({
          locale: geo,
          allLocales: tableRow.geos,
          sourceGeo: tableRow.source_geo,
        })
        ? getFCRequestType({
            locale: geo,
            allLocales: tableRow.geos,
            requestType: tableRow["request_type"],
            sourceGeo: tableRow.source_geo,
          })
        : tableRow["request_type"],
    },
    {
      col: "Word Count",
      value:
        shakespeare ||
        backstageCopywriting ||
        tableRow?.request_type === "Adaptation" && !amountOfEngilshAdaptations(tableRow.geos)
          ? ""
          : newAssignmentForm
          ? tableRow.assignmentDetails.wordCount
          : tableRow["word_count"],
    },
    {
      col: "Work Type 1 Reference",
      value: shakespeare && !isAdaptation({locale: geo, allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type})
        ? staffAssignments[tableRow["task_id"]][geo]["workType1Reference"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType1Reference"]
          : ""
        : backstageCopywriting || 
          tableRow?.request_type === "Adaptation" ||
          isAdaptation({
            locale: geo,
            allLocales: tableRow.geos,
            sourceGeo: tableRow.source_geo,
            requestType: tableRow["request_type"],
          })
          || (shakespeare && isAdaptation({locale: geo, allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type}))
        ? getFCWorkType({
            workType: shakespeare ?
              null :
              staffAssignments[tableRow["task_name"]][geo][
                "workType1Reference"
              ],
            workType1: shakespeare ? null : true,
            locale: geo,
            sourceGeo: tableRow.source_geo,
            allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,
            adaptationRequest: tableRow.request_type === "Adaptation",
            shakespeare: shakespeare,
            requestType: tableRow.request_type,
            tableRows: tableRows,
          })
        : newAssignmentForm
        ? tableRow.pricing.workTypes[0].customerPriceList.workTypeName
        : getFCWorkType({
            locale: geo,
            sourceLocale: tableRow.source_geo,
            localizationMode: true,
          }),
    },
    {
      col: "Work Type 1 Quantity",
      value: shakespeare && !isAdaptation({locale: geo, allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type})
        ? staffAssignments[tableRow["task_id"]][geo]["workType1Quantity"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType1Quantity"]
          : ""
        : backstageCopywriting
        ? getFCWordCount({
            wordCount:
              staffAssignments[tableRow["task_name"]][geo]["workType1Quantity"],
            locale: geo,
            allLocales: tableRow.geos,
          })
        : tableRow?.request_type === "Adaptation" && !amountOfEngilshAdaptations(tableRow.geos)
        ? ""
        : newAssignmentForm
        ? tableRow.pricing.workTypes[0].quantity
        : tableRow.word_count,
    },
    {
      col: "Work Type 2 Reference",
      value: tableRow.request_type === "Adaptation" || isAdaptation({locale: geo, allLocales: tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type})
        || (shakespeare && isAdaptation({locale: geo, allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type}))
        ? ''
        : shakespeare
        ? staffAssignments[tableRow["task_id"]][geo]["workType2Reference"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType2Reference"]
          : ""
        : backstageCopywriting || tableRow?.request_type === "Adaptation"
        ? getFCWorkType({
            workType:
              staffAssignments[tableRow["task_name"]][geo][
                "workType2Reference"
              ],
            workType1: false,
            locale: geo,
            allLocales: tableRow.geos,
            adaptationRequest: tableRow.request_type === "Adaptation",
          })
        : newAssignmentForm && tableRow.pricing.workTypes[1]
        ? tableRow.pricing.workTypes[1].customerPriceList.workTypeName
        : "",
    },
    {
      col: "Work Type 2 Quantity",
      value: shakespeare && !isAdaptation({locale: geo, allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type})
        ? staffAssignments[tableRow["task_id"]][geo]["workType2Quantity"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType2Quantity"]
          : ""
        : backstageCopywriting
        ? getFCWordCount({
            wordCount:
              staffAssignments[tableRow["task_name"]][geo]["workType2Quantity"],
            locale: geo,
            allLocales: tableRow.geos,
          })
        : newAssignmentForm && tableRow.pricing.workTypes[1]
        ? tableRow.pricing.workTypes[1].quantity
        : "",
    },
    {
      col: "Work Type 3 Reference",
      value: tableRow.request_type === "Adaptation" || isAdaptation({locale: geo, allLocales: tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type})
        || (shakespeare && isAdaptation({locale: geo, allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type}))
        ? ''
        : shakespeare
        ? staffAssignments[tableRow["task_id"]][geo]["workType3Reference"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType3Reference"]
          : ""
        : backstageCopywriting || tableRow?.request_type === "Adaptation"
        ? getFCWorkType({
            workType:
              staffAssignments[tableRow["task_name"]][geo][
                "workType3Reference"
              ],
            workType1: false,
            locale: geo,
            allLocales: tableRow.geos,
            adaptationRequest: tableRow.request_type === "Adaptation",
          })
        : newAssignmentForm && tableRow.pricing.workTypes[2]
        ? tableRow.pricing.workTypes[2].customerPriceList.workTypeName
        : "",
    },
    {
      col: "Work Type 3 Quantity",
      value: shakespeare && !isAdaptation({locale: geo, allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type})
        ? staffAssignments[tableRow["task_id"]][geo]["workType3Quantity"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType3Quantity"]
          : ""
        : backstageCopywriting
        ? getFCWordCount({
            wordCount:
              staffAssignments[tableRow["task_name"]][geo]["workType3Quantity"],
            locale: geo,
            allLocales: tableRow.geos,
          })
        : newAssignmentForm && tableRow.pricing.workTypes[2]
        ? tableRow.pricing.workTypes[2].quantity
        : "",
    },
    {
      col: "Work Type 4 Reference",
      value: tableRow.request_type === "Adaptation" || isAdaptation({locale: geo, allLocales: tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type})
        || (shakespeare && isAdaptation({locale: geo, allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type}))
        ? ''
        : shakespeare
        ? staffAssignments[tableRow["task_id"]][geo]["workType4Reference"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType4Reference"]
          : ""
        : backstageCopywriting || tableRow?.request_type === "Adaptation"
        ? getFCWorkType({
            workType:
              staffAssignments[tableRow["task_name"]][geo][
                "workType4Reference"
              ],
            workType1: false,
            locale: geo,
            allLocales: tableRow.geos,
            adaptationRequest: tableRow.request_type === "Adaptation",
          })
        : newAssignmentForm && tableRow.pricing.workTypes[3]
        ? tableRow.pricing.workTypes[3].customerPriceList.workTypeName
        : "",
    },
    {
      col: "Work Type 4 Quantity",
      value: shakespeare && !isAdaptation({locale: geo, allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type})
        ? staffAssignments[tableRow["task_id"]][geo]["workType4Quantity"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType4Quantity"]
          : ""
        : backstageCopywriting
        ? getFCWordCount({
            wordCount:
              staffAssignments[tableRow["task_name"]][geo]["workType4Quantity"],
            locale: geo,
            allLocales: tableRow.geos,
          })
        : newAssignmentForm && tableRow.pricing.workTypes[3]
        ? tableRow.pricing.workTypes[3].quantity
        : "",
    },
    {
      col: "Work Type 5 Reference",
      value: tableRow.request_type === "Adaptation" || isAdaptation({locale: geo, allLocales: tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type})
        || (shakespeare && isAdaptation({locale: geo, allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type}))
        ? ''
        : shakespeare
        ? staffAssignments[tableRow["task_id"]][geo]["workType5Reference"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType5Reference"]
          : ""
        : backstageCopywriting || tableRow?.request_type === "Adaptation"
        ? getFCWorkType({
            workType:
              staffAssignments[tableRow["task_name"]][geo][
                "workType5Reference"
              ],
            workType1: false,
            locale: geo,
            allLocales: tableRow.geos,
            adaptationRequest: tableRow.request_type === "Adaptation",
          })
        : newAssignmentForm && tableRow.pricing.workTypes[4]
        ? tableRow.pricing.workTypes[4].customerPriceList.workTypeName
        : "",
    },
    {
      col: "Work Type 5 Quantity",
      value: shakespeare && !isAdaptation({locale: geo, allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type})
        ? staffAssignments[tableRow["task_id"]][geo]["workType5Quantity"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType5Quantity"]
          : ""
        : backstageCopywriting
        ? getFCWordCount({
            wordCount:
              staffAssignments[tableRow["task_name"]][geo]["workType5Quantity"],
            locale: geo,
            allLocales: tableRow.geos,
          })
        : newAssignmentForm && tableRow.pricing.workTypes[4]
        ? tableRow.pricing.workTypes[4].quantity
        : "",
    },
    {
      col: "Work Type 6 Reference",
      value: tableRow.request_type === "Adaptation" || isAdaptation({locale: geo, allLocales: tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type})
        || (shakespeare && isAdaptation({locale: geo, allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type}))
        ? ''
        : shakespeare
        ? staffAssignments[tableRow["task_id"]][geo]["workType6Reference"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType6Reference"]
          : ""
        : backstageCopywriting || tableRow?.request_type === "Adaptation"
        ? getFCWorkType({
            workType:
              staffAssignments[tableRow["task_name"]][geo][
                "workType6Reference"
              ],
            workType1: false,
            locale: geo,
            allLocales: tableRow.geos,
            adaptationRequest: tableRow.request_type === "Adaptation",
          })
        : newAssignmentForm && tableRow.pricing.workTypes[5]
        ? tableRow.pricing.workTypes[5].customerPriceList.workTypeName
        : "",
    },
    {
      col: "Work Type 6 Quantity",
      value: shakespeare && !isAdaptation({locale: geo, allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type})
        ? staffAssignments[tableRow["task_id"]][geo]["workType6Quantity"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType6Quantity"]
          : ""
        : backstageCopywriting
        ? getFCWordCount({
            wordCount:
              staffAssignments[tableRow["task_name"]][geo]["workType6Quantity"],
            locale: geo,
            allLocales: tableRow.geos,
          })
        : newAssignmentForm && tableRow.pricing.workTypes[5]
        ? tableRow.pricing.workTypes[5].quantity
        : "",
    },
    {
      col: "Related Writer",
      value: newAssignmentForm
        ? (tableRow.primary ? `${tableRow.primary.user.firstName} ${tableRow.primary.user.lastName}` : "")
        : staffAssignments[tableRow[shakespeare ? "task_id" : "task_name"]][
            geo
          ]["writer"],
    },
    {
      col: "Writing Due Date",
      value: newAssignmentForm
        ? tableRow.schedule.primaryDueDate
        : geoIndividualData?.writingDueDate ?? formValues.writingDueDate,
    },
    {
      col: "Writing Due (Time of Day)",
      value: newAssignmentForm
        ? tableRow.schedule.primaryDueTime
        : geoIndividualData?.writingDue ?? formValues.writingDue,
    },
    {
      col: "Related Editor",
      value: newAssignmentForm
        ? (tableRow.secondary ? `${tableRow.secondary.user.firstName} ${tableRow.secondary.user.lastName}` : "")
        : staffAssignments[tableRow[shakespeare ? "task_id" : "task_name"]][
            geo
          ]["editor"],
    },
    {
      col: "Editing Due Date",
      value: newAssignmentForm
        ? tableRow.schedule.secondaryDueDate
        : staffAssignments[tableRow[shakespeare ? "task_id" : "task_name"]][
            geo
          ]["editor"]
        ? geoIndividualData?.editingDueDate ?? formValues.editingDueDate ?? ""
        : "",
    },
    {
      col: "Editing Due (Time of Day)",
      value: newAssignmentForm
        ? tableRow.schedule.secondaryDueTime
        : staffAssignments[tableRow[shakespeare ? "task_id" : "task_name"]][
            geo
          ]["editor"]
        ? geoIndividualData?.editingDue ?? formValues.editingDue
        : "",
    },
    {
      col: "Reviewer",
      value: newAssignmentForm
        ? (tableRow.qc ? `${tableRow.qc.user.firstName} ${tableRow.qc.user.lastName}` : "")
        : staffAssignments[tableRow[shakespeare ? "task_id" : "task_name"]][
            geo
          ]["reviewer"],
    },
    {
      col: "Reviewing Due Date",
      value: newAssignmentForm
        ? tableRow.schedule.qcDueDate
        : staffAssignments[tableRow[shakespeare ? "task_id" : "task_name"]][
            geo
          ]["reviewer"]
        ? geoIndividualData?.reviewingDueDate ??
          formValues.reviewingDueDate ??
          ""
        : "",
    },
    {
      col: "Reviewing Due (Time of Day)",
      value: newAssignmentForm
        ? tableRow.schedule.qcDueTime
        : staffAssignments[tableRow[shakespeare ? "task_id" : "task_name"]][
            geo
          ]["reviewer"]
        ? geoIndividualData?.reviewingDue ?? formValues.reviewingDue
        : "",
    },
    {
      col: "Delivery Date",
      value: newAssignmentForm
        ? tableRow.schedule.deliveryDate
        : formValues.deliveryDate,
    },
    {
      col: "Delivery Time",
      value: newAssignmentForm
        ? tableRow.schedule.deliveryTime
        : formValues.deliveryDue,
    },
    {
      col: "File Location",
      value: newAssignmentForm
        ? tableRow.assignmentDetails.fileLocation
        : formValues.fileLocation,
    },
    {
      col: "Link",
      value: shakespeare
        ? ""
        : newAssignmentForm
        ? tableRow.assignmentDetails.link
        : tableRow.link
        ? tableRow.link
        : tableRow.shakespeare_link?.length
        ? tableRow.shakespeare_link.find((el) => el.geo === geo).shakespeare_link
        : "",
    },
    {
      col: "Assignment Status",
      value: newAssignmentForm
        ? tableRow.assignmentDetails.assignmentStatus
        : "Assigned",
    },
  ];

  const retVal = [];
  rowInfo.forEach((el) => {
    retVal[el.col] = el.value;
  });
  
  if(retVal["Request Type"] === "Adaptation" && retVal["GEO"] === "de-CH") {
    retVal["Source GEO"] = "de-DE";
  }

  return retVal;
};

export const addRowsToGoogleSheet = (props) => {
  const { tableRows, formValues, staffAssignments } = props,
    shakespeare = store.getters["spreadsheetUpload/shakespeare"],
    backstageCopywriting =
      store.getters["spreadsheetUpload/backstageCopywriting"],
    newAssignmentForm = tableRows[0].hasOwnProperty("document"),
    incrsTableRows = [...tableRows];

  if (backstageCopywriting || shakespeare)
    Object.entries(staffAssignments).forEach((el) => {
      const assgn = el[0],
        geos = Object.keys(el[1]),
        parent = tableRows.find(
          (tableRow) =>
            tableRow[shakespeare ? "task_id" : "task_name"] === assgn
        );
      geos.forEach((geo) => {
        if (staffAssignments[assgn][geo]?.incrementals?.length) {
          const incrsArray = staffAssignments[assgn][geo].incrementals;
          incrsArray.forEach((inc) => {
            if (shakespeare) {
              incrsTableRows.push({
                ...parent,
                index: incrsArray.indexOf(inc),
              });
            } else {
              const incrRow = { ...parent };
              Object.assign(incrRow, {
                geos: parent.geos.filter((parentGeo) => parentGeo === geo),
                index: incrsArray.indexOf(inc),
              });
              incrsTableRows.push(incrRow);
            }
          });
        }
      });
    });

  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
  let sheet;

  return new Promise((resolve, reject) => {
    doc
      .useServiceAccountAuth({
        client_email: process.env.VUE_APP_GDOCS_CLIENT_EMAIL,
        private_key: process.env.VUE_APP_G_KEY,
      })
      .then(() => {
        // populate document info
        return doc.loadInfo();
      })
      .then(() => {
        // get worksheet
        sheet = doc.sheetsByTitle[tabName];
        // clear sheet
        return sheet.clear();
      })
      .then(() => {
        // set sheet size
        return sheet.resize({ rowCount: 500, columnCount: headerCols.length });
      })
      .then(() => {
        // add header row
        return sheet.setHeaderRow(headerCols);
      })
      .then(() => {
        let rowsToAdd = [];
        (backstageCopywriting || shakespeare
          ? incrsTableRows
          : tableRows
        ).forEach((tableRow) => {
          (shakespeare
            ? [tableRow.geo]
            : newAssignmentForm
            ? [tableRow.assignmentDetails.geo]
            : tableRow.geos
          ).forEach((geoVal) => {
            const row = getRowValues({
              formValues,
              tableRow,
              geoVal,
              staffAssignments,
              tableRows
            });
            rowsToAdd.push(_.values(row));
          });
        });

        const sortRank = {
          "cs-CZ": 1,
          "el-GR": 2,
          "es-ES": 3,
          "es-MX": 4,
          "hi-IN": 5,
        };

        const sorted = _.sortBy(rowsToAdd, [
          (el) => el[6], // producer
          (el) => el[4], // subject line
          (el) => el[8], // task name
          (el) => {
            return sortRank[el[9]]; // geo
          },
        ]);

        sheet.addRows(sorted, { insert: false });
      })
      .then(() => resolve("Import Successful"))
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};
