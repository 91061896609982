<template>
  <div>
    <v-form
      v-if="!calendarMode && !adminCalendarMode"
      ref="form"
      v-model="valid"
      lazy-validation
      class="staff-form v-card v-sheet theme--light"
    >
      <div
        v-if="isEditMode && !profileView"
        class="d-flex justify-center align-center py-2"
      >
        <v-img
          max-width="36"
          width="36"
          height="36"
          :src="defaultAvatar"
          class="mr-2"
        ></v-img>
        <h2>{{ fullName }}</h2>
      </div>
      <v-tabs v-model="tab" background-color="transparent" grow>
        <v-tab
          v-for="(step, index) in steps"
          :key="index"
          class="rounded"
          active-class="secondary"
        >
          <v-icon left>{{ `mdi-${step.icon}` }}</v-icon> {{ step.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <!-- OVERVIEW -->
        <v-tab-item>
          <v-container class="d-flex">
            <v-col cols="12" sm="4" class="pb-0">
              <v-text-field
                filled
                shaped
                readonly
                label="Name"
                v-model="fullName"
              />
              <v-text-field
                filled
                shaped
                readonly
                label="Preferred Name"
                v-model="personalDetails.preferredName"
              />
              <v-text-field
                filled
                shaped
                readonly
                label="Pronouns"
                v-model="personalDetails.pronouns"
              />
              <v-text-field
                filled
                shaped
                readonly
                label="Location"
                v-model="hrDetails.country"
              />
              <v-text-field
                filled
                shaped
                readonly
                label="Start Date"
                v-model="formattedStartDate"
              ></v-text-field>
            </v-col>
            <v-divider vertical></v-divider>
            <v-card class="d-flex align-center pa-2 mx-4" width="65%" shaped>
              <div
                class="staff-form__profile-chips-container d-flex flex-column align-center justify-center"
              >
                <v-chip
                  v-if="skillsBadges.length"
                  x-large
                  label
                  class="staff-form__profile-chip overline text-center py-3 my-2 elevation-3"
                  color="blue lighten-2"
                  text-color="white"
                >
                  {{ skillsBadges[0].skill }}
                </v-chip>
                <v-chip
                  v-if="skillsBadges[1]"
                  x-large
                  label
                  class="staff-form__profile-chip overline text-center py-3 my-2 elevation-3"
                  color="blue lighten-2"
                  text-color="white"
                >
                  {{ skillsBadges[1].skill }}
                </v-chip>
                <v-chip
                  v-if="personalDetails.language1.language"
                  x-large
                  label
                  class="staff-form__profile-chip overline text-center py-3 my-2 elevation-3"
                  color="pink lighten-3"
                  text-color="white"
                >
                  {{ personalDetails.language1.language }}
                </v-chip>
                <v-chip
                  v-if="expertiseBadges.length"
                  x-large
                  label
                  class="staff-form__profile-chip overline text-center py-3 my-2 elevation-3"
                  color="green lighten-2"
                  text-color="white"
                >
                  {{ expertiseBadges[0].expertise }}
                </v-chip>
                <v-chip
                  v-if="expertiseBadges[1]"
                  x-large
                  label
                  class="staff-form__profile-chip overline text-center py-3 my-2 elevation-3"
                  color="green lighten-2"
                  text-color="white"
                >
                  {{ expertiseBadges[1].expertise }}
                </v-chip>
              </div>
              <div
                class="staff-form__profile-avatar-container d-flex flex-column align-center"
              >
                <v-badge
                  :color="
                    adminDetails.status === 'Available'
                      ? 'success'
                      : 'secondary'
                  "
                  overlap
                  bottom
                  :value="adminDetails.status"
                >
                  <v-skeleton-loader
                    v-if="personalDetails.photo ? filesLoading : false"
                    width="350px"
                    type="image"
                  ></v-skeleton-loader>
                  <v-img
                    v-else
                    width="350"
                    height="350"
                    :src="
                      itemToEdit?.personalDetails?.photo
                        ? avatar
                        : defaultAvatar
                    "
                    alt="Profile photo"
                  ></v-img>
                </v-badge>
                <div
                  class="staff-form__profile-avatar-info d-flex align-center pt-4 px-4"
                >
                  <div class="d-flex align-center mr-auto">
                    <span class="text-h5">Active:</span>
                    <v-icon
                      v-if="itemToEdit?.adminDetails?.activeStaff"
                      x-large
                      color="success"
                      >mdi-checkbox-marked-outline</v-icon
                    >
                    <v-icon
                      v-else
                      x-large
                      color="secondary"
                      :disabled="!isEditMode"
                      >mdi-close-box-outline</v-icon
                    >
                  </div>
                  <v-icon v-if="appleLogo" x-large class="mr-2" color="primary"
                    >mdi-food-apple</v-icon
                  >
                  <v-icon
                    v-if="androidLogo"
                    x-large
                    class="mr-2"
                    color="primary"
                    >mdi-android</v-icon
                  >
                </div>
              </div>
              <v-card-actions
                class="staff-form__profile-actions-container flex-column align-start pa-0"
              >
                <v-btn
                  icon
                  x-large
                  color="accent"
                  :disabled="!isEditMode"
                  @click="handleCalendar"
                >
                  <v-icon>mdi-calendar-month</v-icon>
                </v-btn>
                <v-btn
                  v-if="!profileView"
                  x-large
                  text
                  class="slack-btn ml-0"
                  :href="`slack://user?team=${cueFC}&id=${adminDetails.slackIdFc}`"
                  :disabled="!adminDetails.slackIdFc"
                >
                  <v-icon large color="accent" class="mr-1"> mdi-slack </v-icon
                  >FC</v-btn
                >
                <v-btn
                  v-if="!profileView"
                  x-large
                  text
                  class="slack-btn ml-0"
                  :href="`slack://user?team=${cueStaff}&id=${adminDetails.slackIdStaff}`"
                  :disabled="!adminDetails.slackIdStaff"
                >
                  <v-icon large color="accent" class="mr-1"> mdi-slack </v-icon
                  >Staff</v-btn
                >
                <v-btn
                  v-if="!profileView && user.userRole === 'MANAGER'"
                  x-large
                  text
                  class="slack-btn ml-0"
                  :href="`slack://user?team=${cuePM}&id=${adminDetails.slackIDpm}`"
                  :disabled="!adminDetails.slackIDpm"
                >
                  <v-icon large color="accent" class="mr-1"> mdi-slack </v-icon
                  >PM</v-btn
                >
                <v-btn
                  v-if="!profileView"
                  icon
                  x-large
                  class="ml-0"
                  color="accent"
                  :disabled="!user.email"
                  @click="sendEmail(user.email)"
                >
                  <v-icon>mdi-email-outline</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-container>
        </v-tab-item>
        <!-- PERSONAL DETAILS -->
        <v-tab-item eager>
          <v-container class="d-flex flex-wrap">
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                label="First Name"
                v-model="user.firstName"
                :rules="[rules.required]"
                @change="addKeyToModified('firstName', $event)"
              />
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                label="Last Name"
                v-model="user.lastName"
                :rules="[rules.required]"
                @change="addKeyToModified('lastName', $event)"
              />
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                label="Preferred Name"
                v-model="personalDetails.preferredName"
                :rules="isAdmin ? [] : [rules.required]"
                @change="
                  handleEmptyString($event, 'personalDetails', 'preferredName')
                "
              />
              <v-combobox
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                label="Pronouns"
                :items="pronounsList"
                v-model="personalDetails.pronouns"
                @change="
                  handleEmptyString($event, 'personalDetails', 'pronouns')
                "
              ></v-combobox>
              <v-row>
                <v-col cols="12" sm="6" class="pb-0">
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    label="LinkedIn"
                    :clearable="!isViewMode"
                    v-model="personalDetails.linkedIn"
                    @change="
                      handleEmptyString($event, 'personalDetails', 'linkedIn')
                    "
                /></v-col>
                <v-col cols="12" sm="6" class="pb-0">
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    label="Website"
                    :clearable="!isViewMode"
                    v-model="personalDetails.website"
                    @change="
                      handleEmptyString($event, 'personalDetails', 'website')
                    "
                /></v-col>
              </v-row>
            </v-col>
            <v-divider inset vertical></v-divider>
            <v-col cols="12" sm="6" class="pb-0 text-center">
              <v-row v-if="!isViewMode" class="mt-0"
                ><v-col cols="12" sm="6" class="py-0">
                  <v-file-input
                    outlined
                    v-model="photoBlob"
                    accept=".png, .jpg, .jpeg"
                    truncate-length="20"
                    label="Photo"
                    :loading="
                      itemToEdit?.personalDetails?.photo ? filesLoading : false
                    "
                    :disabled="
                      itemToEdit?.personalDetails?.photo ? filesLoading : false
                    "
                    @change="convertFile($event, 'photo')"
                    :rules="[rules.isImage]"
                /></v-col>
                <v-col cols="12" sm="6" class="py-0"
                  ><v-file-input
                    outlined
                    v-model="portfolioBlob"
                    accept=".pdf"
                    truncate-length="20"
                    label="Portfolio"
                    :loading="
                      itemToEdit?.personalDetails?.portfolio
                        ? filesLoading
                        : false
                    "
                    :disabled="
                      itemToEdit?.personalDetails?.portfolio
                        ? filesLoading
                        : false
                    "
                    @change="convertFile($event, 'portfolio')"
                    :rules="[rules.isPDF]"
                /></v-col>
              </v-row>
              <v-btn
                v-else
                x-large
                width="70%"
                height="58px"
                color="accent"
                class="mb-7"
                :disabled="!itemToEdit?.personalDetails?.portfolio"
                :loading="
                  itemToEdit.personalDetails.portfolio ? filesLoading : false
                "
                @click="downloadFile('portfolio')"
              >
                Portfolio<v-icon right>mdi-download</v-icon>
              </v-btn>
              <v-row>
                <v-col cols="12" sm="6" :class="isViewMode ? 'pb-0' : 'py-0'">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Language 1 (Native)"
                    item-text="value"
                    :items="languagesList"
                    v-model="personalDetails.language1.language"
                    :loading="dropdownsLoading"
                    :rules="[rules.required]"
                    @change="validateLanguage1Level($event)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" :class="isViewMode ? 'pb-0' : 'py-0'">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Language 1 Level"
                    :items="languageLevelOptions"
                    v-model="personalDetails.language1.languageLevel"
                    disabled
                    :rules="
                      personalDetails.language1.language ? [rules.required] : []
                    "
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Language 2"
                    item-text="value"
                    :items="languagesList"
                    :loading="dropdownsLoading"
                    v-model="personalDetails.language2.language"
                    @change="
                      handleEmptyString($event, 'personalDetails', 'language2')
                    "
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Language 2 Level"
                    :items="languageLevelOptions"
                    v-model="personalDetails.language2.languageLevel"
                    :disabled="!personalDetails.language2.language"
                    :rules="
                      personalDetails.language2.language ? [rules.required] : []
                    "
                    @change="addKeyToModified('language2Level', $event)"
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Language 3"
                    item-text="value"
                    :items="languagesList"
                    :loading="dropdownsLoading"
                    v-model="personalDetails.language3.language"
                    @change="
                      handleEmptyString($event, 'personalDetails', 'language3')
                    "
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Language 3 Level"
                    :items="languageLevelOptions"
                    v-model="personalDetails.language3.languageLevel"
                    :disabled="!personalDetails.language3.language"
                    :rules="
                      personalDetails.language3.language ? [rules.required] : []
                    "
                    @change="addKeyToModified('language3Level', $event)"
                  ></v-autocomplete
                ></v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" class="pb-0">
              <v-textarea
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                rows="3"
                :clearable="!isViewMode"
                clear-icon="mdi-close-circle-outline"
                label="Bio"
                v-model="personalDetails.bio"
                :rules="isAdmin ? [] : [rules.required]"
                @change="handleEmptyString($event, 'personalDetails', 'bio')"
              />
            </v-col>
          </v-container>
        </v-tab-item>
        <!-- HR DETAILS -->
        <v-tab-item eager>
          <v-container class="d-flex">
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :disabled="!isViewMode && isEditMode"
                :append-outer-icon="
                  user.email ? 'mdi-email-arrow-right-outline' : null
                "
                label="Email"
                v-model="user.email"
                :rules="[rules.required, rules.validEmail]"
                @click:append-outer="sendEmail(user.email)"
              />
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :disabled="!isViewMode && !isAdmin"
                :append-outer-icon="
                  hrDetails.cueEmail ? 'mdi-email-arrow-right-outline' : null
                "
                label="Cue Email"
                :clearable="!isViewMode"
                v-model="hrDetails.cueEmail"
                @click:append-outer="sendEmail(hrDetails.cueEmail)"
                @change="handleEmptyString($event, 'hrDetails', 'cueEmail')"
              />
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-outer-icon="
                  hrDetails.gmail ? 'mdi-email-arrow-right-outline' : null
                "
                label="Gmail"
                :clearable="!isViewMode"
                v-model="hrDetails.gmail"
                @click:append-outer="sendEmail(hrDetails.gmail)"
                @change="handleEmptyString($event, 'hrDetails', 'gmail')"
              />
              <v-autocomplete
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                label="Country"
                :items="countryOptions"
                v-model="hrDetails.country"
                :loading="dropdownsLoading"
                :rules="[rules.required]"
                @change="addKeyToModified('country', $event)"
              ></v-autocomplete>
              <v-autocomplete
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                :append-outer-icon="hrDetails.homeAddress ? 'mdi-earth' : null"
                label="Address"
                no-filter
                :items="addresses"
                :loading="loadPredictions"
                v-model="hrDetails.homeAddress"
                @click:append-outer="seeOnMap"
                @update:search-input="getAddress($event)"
                :rules="isAdmin ? [] : [rules.required]"
                @change="handleEmptyString($event, 'hrDetails', 'homeAddress')"
              ></v-autocomplete>
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                label="Zip Code"
                :rules="isAdmin ? [] : [rules.required]"
                :error-messages="zipCodeError ? ['ZIP Code not found for this address. Please add manually'] : []"
                :clearable="!isViewMode"
                v-model="hrDetails.zipCode"
                @change="handleEmptyString($event, 'hrDetails', 'zipCode')"
              />
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                label="Phone Number"
                :clearable="!isViewMode"
                v-model="hrDetails.phoneNumber"
                @change="handleEmptyString($event, 'hrDetails', 'phoneNumber')"
              />
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                label="Legal Name"
                :clearable="!isViewMode"
                v-model="hrDetails.legalName"
                :rules="isAdmin ? [] : [rules.required]"
                @change="handleEmptyString($event, 'hrDetails', 'legalName')"
              />
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="6" class="pb-0 text-center">
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                label="SSN/Tax ID"
                :clearable="!isViewMode"
                v-model="hrDetails.ssnOrTaxId"
                @change="handleEmptyString($event, 'hrDetails', 'ssnOrTaxId')"
              />
              <v-row class="my-0">
                <v-col
                  cols="12"
                  sm="6"
                  class="text-center"
                  :class="isViewMode ? 'pt-0' : isAdmin ? 'py-0' : 'pt-0'"
                >
                  <v-file-input
                    v-if="!isViewMode && isAdmin"
                    outlined
                    v-model="taxDocumentsBlob"
                    accept=".pdf"
                    truncate-length="20"
                    label="Tax Document"
                    :loading="
                      itemToEdit?.hrDetails?.taxDocuments ? filesLoading : false
                    "
                    :disabled="
                      itemToEdit?.hrDetails?.taxDocuments ? filesLoading : false
                    "
                    @change="convertFile($event, 'taxDocuments')"
                    :rules="[rules.isPDF]"
                  />
                  <v-btn
                    v-else
                    block
                    x-large
                    height="56px"
                    color="accent"
                    class="mb-6"
                    :disabled="!itemToEdit?.hrDetails?.taxDocuments"
                    :loading="filesLoading"
                    @click="downloadFile('taxDocuments')"
                  >
                    Tax Document<v-icon right>mdi-download</v-icon>
                  </v-btn></v-col
                >
                <v-col
                  cols="12"
                  sm="6"
                  class="text-center"
                  :class="isViewMode ? 'pt-0' : 'py-0'"
                >
                  <v-file-input
                    v-if="!isViewMode && isAdmin"
                    outlined
                    v-model="ndaBlob"
                    accept=".pdf"
                    truncate-length="20"
                    label="NDA"
                    :loading="itemToEdit?.hrDetails?.nda ? filesLoading : false"
                    :disabled="
                      itemToEdit?.hrDetails?.nda ? filesLoading : false
                    "
                    @change="convertFile($event, 'nda')"
                    :rules="
                      userRoleUpdate === 'CANDIDATE'
                        ? []
                        : isAdmin
                        ? [rules.requiredFile, rules.isPDF,]
                        : [rules.isPDF]
                    "
                  />
                  <v-btn
                    v-else
                    block
                    x-large
                    height="56px"
                    color="accent"
                    class="mb-6"
                    :disabled="!itemToEdit?.hrDetails?.nda"
                    :loading="filesLoading"
                    @click="downloadFile('nda')"
                  >
                    NDA<v-icon right>mdi-download</v-icon>
                  </v-btn></v-col
                >
              </v-row>
              <v-row
                class="mt-0 justify-center"
                :class="isViewMode ? 'mb-3' : 'mb-0'"
              >
                <v-col
                  cols="12"
                  sm="8"
                  class="text-center"
                  :class="isViewMode ? 'pt-0' : 'py-0'"
                >
                  <v-file-input
                    v-if="!isViewMode && isAdmin"
                    outlined
                    v-model="contractBlob"
                    accept=".pdf"
                    truncate-length="50"
                    label="Contract"
                    :loading="
                      itemToEdit?.hrDetails?.contract ? filesLoading : false
                    "
                    :disabled="
                      itemToEdit?.hrDetails?.contract ? filesLoading : false
                    "
                    @change="convertFile($event, 'contract')"
                    :rules="
                      userRoleUpdate === 'CANDIDATE'
                        ? []
                        : isAdmin
                        ? [rules.requiredFile, rules.isPDF]
                        : [rules.isPDF]
                    "
                  />
                  <v-btn
                    v-else
                    block
                    x-large
                    height="56px"
                    color="accent"
                    :class="{ 'mb-6': !isViewMode && !isAdmin }"
                    :disabled="!itemToEdit?.hrDetails?.contract"
                    :loading="filesLoading"
                    @click="downloadFile('contract')"
                  >
                    Contract<v-icon right>mdi-download</v-icon>
                  </v-btn></v-col
                >
              </v-row>
              <v-autocomplete
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                label="Tax Residency"
                :items="countryOptions"
                v-model="hrDetails.taxResidency"
                :loading="dropdownsLoading"
                :rules="isAdmin ? [] : [rules.required]"
                @change="handleEmptyString($event, 'hrDetails', 'taxResidency')"
              ></v-autocomplete>
              <v-row class="my-0">
                <v-col
                  cols="12"
                  :sm="
                    hrDetails.paymentType === 'PayPal' ||
                    hrDetails.paymentType === 'Wise'
                      ? 3
                      : 6
                  "
                  class="py-0 text-center"
                >
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Payment Type"
                    :items="paymentTypeOptions"
                    v-model="hrDetails.paymentType"
                    :loading="dropdownsLoading"
                    :rules="userRoleUpdate === 'CANDIDATE' ? [] : isAdmin ? [rules.required] : []"
                    @change="
                      handleEmptyString($event, 'hrDetails', 'paymentType')
                    "
                  ></v-autocomplete>
                </v-col>
                <v-col
                  v-if="hrDetails.paymentType === 'PayPal'"
                  cols="12"
                  sm="6"
                  class="py-0 text-center"
                >
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    label="PayPal Email"
                    :clearable="!isViewMode"
                    v-model="hrDetails.payPalEmail"
                    :rules="
                      hrDetails.paymentType === 'PayPal' && isAdmin
                        ? [rules.required]
                        : []
                    "
                    @change="
                      handleEmptyString($event, 'hrDetails', 'payPalEmail')
                    "
                  />
                </v-col>
                <v-col
                  v-else-if="hrDetails.paymentType === 'Wise'"
                  cols="12"
                  sm="6"
                  class="py-0 text-center"
                >
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    label="Wise Email"
                    :clearable="!isViewMode"
                    v-model="hrDetails.wiseEmail"
                    :rules="
                      hrDetails.paymentType === 'Wise' && isAdmin
                        ? [rules.required]
                        : []
                    "
                    @change="
                      handleEmptyString($event, 'hrDetails', 'wiseEmail')
                    "
                  />
                </v-col>
                <v-col
                  cols="12"
                  :sm="
                    hrDetails.paymentType === 'PayPal' ||
                    hrDetails.paymentType === 'Wise'
                      ? 3
                      : 6
                  "
                  class="py-0 text-center"
                >
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Currency"
                    :items="currencyOptions"
                    v-model="hrDetails.currency"
                    :loading="dropdownsLoading"
                    :rules="userRoleUpdate === 'CANDIDATE' ? [] : isAdmin ? [rules.required] : []"
                    @change="handleEmptyString($event, 'hrDetails', 'currency')"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-expansion-panels v-if="isEditMode && isViewMode">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Cue Hardware
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-skeleton-loader
                      v-if="hardwareLoading"
                      type="list-item-avatar-two-line"
                    ></v-skeleton-loader>
                    <v-list v-else>
                      <v-list-item
                        v-for="hardware in cueHardware"
                        :key="hardware.mainInfo"
                      >
                        <v-list-item-avatar>
                          <v-icon large color="primary">
                            {{ `mdi-${hardware.icon}` }}
                          </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            v-text="hardware.mainInfo"
                          ></v-list-item-title>

                          <v-list-item-subtitle
                            v-if="hardware.onHandDate"
                            v-text="hardware.onHandDate"
                          ></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action
                          v-if="hardware.onHandDate && isAdmin"
                        >
                          <v-btn
                            icon
                            :href="`${fullHardwareDashboardPath}?Category=${hardware.category}&User=${user.firstName} ${user.lastName}`"
                            target="_blank"
                          >
                            <v-icon large color="accent"
                              >mdi-information</v-icon
                            >
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item></v-list
                    >
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Cue Software
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-skeleton-loader
                      v-if="softwareLoading"
                      type="list-item-avatar-two-line"
                    ></v-skeleton-loader>
                    <v-list v-else>
                      <v-list-item
                        v-for="software in cueSoftware"
                        :key="software.name"
                      >
                        <v-list-item-avatar>
                          <v-icon large color="primary">
                            {{ `mdi-${software.provider}` }}
                          </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            v-text="software.name"
                          ></v-list-item-title>

                          <v-list-item-subtitle
                            v-if="software.type"
                            v-text="software.type"
                          ></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action v-if="software.type && isAdmin">
                          <v-btn
                            icon
                            :href="`${fullSoftwareDashboardPath}?Service=${software.name}`"
                            target="_blank"
                          >
                            <v-icon large color="accent"
                              >mdi-information</v-icon
                            >
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item></v-list
                    >
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-container>
        </v-tab-item>
        <!-- ADMIN DETAILS -->
        <v-tab-item v-if="isAdmin || isManager" eager>
          <v-container class="d-flex flex-wrap">
            <v-col cols="12" sm="6" class="pb-0">
              <v-row>
                <v-col cols="12" sm="4" class="pb-0">
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    label="Slack ID (Staff)"
                    v-model="adminDetails.slackIdStaff"
                    @change="
                      handleEmptyString($event, 'adminDetails', 'slackIdStaff')
                    "
                /></v-col>
                <v-col cols="12" sm="4" class="pb-0">
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    label="Slack ID (FC)"
                    :clearable="!isViewMode"
                    v-model="adminDetails.slackIdFc"
                    @change="
                      handleEmptyString($event, 'adminDetails', 'slackIdFc')
                    "
                /></v-col>
                <v-col cols="12" sm="4" class="pb-0">
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    label="Slack ID (PM)"
                    :clearable="!isViewMode"
                    v-model="adminDetails.slackIdPm"
                    @change="
                      handleEmptyString($event, 'adminDetails', 'slackIdPm')
                    "
                /></v-col>
              </v-row>
              <v-row class="align-center justify-space-between staff-form__permissions">
                <v-col
                  cols="12"
                  :sm="profileView || !itemToEdit?.user?.cueHubAccess ? 6 : 4"
                  class="d-flex pb-0"
                >
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Role"
                    :items="userRoles"
                    v-model="userRoleUpdate"
                    :loading="dropdownsLoading"
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col v-if="(user.userRole === 'STAFF' && !adminDetails?.vendor)" cols="12" sm="6" class="d-flex justify-center pb-0 pl-0">
                  <v-autocomplete
                    multiple
                    small-chips
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    :items="operationalRolesList"
                    label="Operational Role"
                    :clearable="!isViewMode"
                    v-model="operationalRoles"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="align-center">
                <v-col cols="12" sm="4" class="d-flex justify-center pb-0 pr-0">
                  <v-checkbox
                    readonly
                    :disabled="!isViewMode"
                    class="mt-0 pt-0"
                    prepend-icon="mdi-account-badge-outline"
                    label="Active Staff"
                    v-model="adminDetails.activeStaff"
                  ></v-checkbox
                ></v-col>
                <v-col
                  cols="12"
                  :sm="profileView || !itemToEdit?.user?.cueHubAccess ? 6 : 4"
                  class="d-flex justify-center pb-0"
                >
                  <v-checkbox
                    class="mt-0 pt-0"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    prepend-icon="mdi-account-key-outline"
                    label="CueHub"
                    v-model="user.cueHubAccess"
                  ></v-checkbox>
                </v-col>
                <v-col
                  v-if="!profileView && itemToEdit?.user?.cueHubAccess"
                  cols="12"
                  sm="4"
                  class="d-flex justify-center py-0"
                >
                  <v-btn
                    :disabled="
                      !user.cueHubAccess ||
                      !isAdmin ||
                      !isEditMode ||
                      isViewMode ||
                      !itemToEdit?.user?.cueHubAccess
                    "
                    class="mb-3"
                    :loading="resetLoading"
                    @click="sendPasswordReset"
                  >
                    <v-icon class="mr-2">mdi-lock-reset</v-icon>Reset Pw
                  </v-btn></v-col
                >
                
              </v-row>
              <v-row>
                <v-col cols="12" sm="4" class="pb-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Status"
                    :items="userStatusOptions"
                    v-model="adminDetails.status"
                    :loading="dropdownsLoading"
                    :rules="[rules.required]"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" sm="4" class="pb-0">
                  <v-menu
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedStartDate"
                        :rules="isAdmin ? [rules.required] : []"
                        :class="{ disabledPicker: isViewMode }"
                        label="Start Date"
                        prepend-icon="mdi-calendar-start"
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        readonly
                        :disabled="!isViewMode && !isAdmin"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="startDateMenu = false"
                      v-model="startDateNotTimestamp"
                      @change="handleDate($event, 'adminDetails', 'startDate')"
                    ></v-date-picker> </v-menu
                ></v-col>
                <v-col cols="12" sm="4" class="pb-0"
                  ><v-menu
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedEndDate"
                        :class="{ disabledPicker: isViewMode }"
                        label="End Date"
                        prepend-icon="mdi-calendar-end"
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        readonly
                        :disabled="!isViewMode && !isAdmin"
                        :clearable="!isViewMode"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="endDateMenu = false"
                      v-model="endDateNotTimestamp"
                      @change="handleDate($event, 'adminDetails', 'endDate')"
                    ></v-date-picker> </v-menu
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" :sm="isEditMode ? 8 : 12" class="pb-0">
                  <v-autocomplete
                    multiple
                    chips
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    :items="geoOptions"
                    label="GEOs"
                    :clearable="!isViewMode"
                    v-model="adminDetails.geos"
                  ></v-autocomplete
                ></v-col>
                <v-col v-if="isEditMode" cols="12" sm="4" class="pb-0">
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    readonly
                    height="68px"
                    :disabled="!isViewMode"
                    label="Initials"
                    v-model="adminDetails.initials"
                    @change="
                      handleEmptyString($event, 'adminDetails', 'initials')
                    "
                /></v-col>
              </v-row>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="6" class="pb-0">
              <v-row class="align-center">
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    class="mt-0 pt-0"
                    prepend-icon="mdi-dropbox"
                    label="Dropbox"
                    v-model="adminDetails.dropboxAccess"
                  ></v-checkbox
                ></v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    class="mt-0 pt-0"
                    prepend-icon="mdi-trello"
                    label="Trello"
                    v-model="adminDetails.trelloAccess"
                  ></v-checkbox
                ></v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    class="mt-0 pt-0"
                    label="SmartCat"
                    v-model="adminDetails.smartCatAccess"
                  ></v-checkbox
                ></v-col>
              </v-row>
              <v-row class="align-center">
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="
                      !isViewMode &&
                      (!isAdmin ||
                        !user.cueHubAccess ||
                        user.userRole === 'ADMIN')
                    "
                    class="mt-0 pt-0"
                    label="DubEditor"
                    v-model="adminDetails.dubEditorAccess"
                  ></v-checkbox
                ></v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    class="mt-0 pt-0"
                    label="TryRating"
                    v-model="adminDetails.tryRating"
                  ></v-checkbox
                ></v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    class="mt-0 pt-0"
                    label="Box"
                    v-model="adminDetails.boxAccess"
                  ></v-checkbox
                ></v-col>
              </v-row>
              <v-row
                class="justify-space-between align-center px-3 ma-0 mt-4 rounded elevation-2"
              >
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <v-icon>mdi-fingerprint</v-icon>
                  <h3>Smartling</h3>
                </v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    class="mt-5 pa-0"
                    label="FC"
                    v-model="adminDetails.smartlingAccessApple"
                  ></v-checkbox
                ></v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    class="mt-5 pa-0"
                    label="Other"
                    v-model="adminDetails.smartlingAccess"
                  ></v-checkbox
                ></v-col>
              </v-row>
              <div class="rounded elevation-2 pt-4 mt-4">
                <v-row class="justify-space-between align-center">
                  <v-col cols="12" sm="4" class="d-flex align-center ml-2">
                    <v-img
                      :src="SHK"
                      max-width="40px"
                      width="40px"
                      height="36px"
                      alt="Shakespeare"
                    />
                    <h3>Access</h3>
                  </v-col>
                  <v-col cols="12" sm="5" class="d-flex justify-center align-center v-message-none">
                    <v-checkbox
                      :readonly="isViewMode"
                      :disabled="!isViewMode && !isAdmin"
                      class="mt-4 pt-0"
                      prepend-icon="mdi-food-apple"
                      label="Copy Portal"
                      v-model="adminDetails.appleCopyPortal"
                    ></v-checkbox
                  ></v-col>
                </v-row>
                <v-row class="align-center ma-0">
                  <v-col cols="12" sm="3" class="d-flex justify-center pl-0 pr-1">
                    <v-checkbox
                      :readonly="isViewMode"
                      :disabled="!isViewMode && !isAdmin"
                      class="mt-0 pt-0"
                      label="Apps: Writer"
                      v-model="adminDetails.shakespeareAppsWriter"
                    ></v-checkbox
                  ></v-col>
                  <v-col cols="12" sm="3" class="d-flex justify-center pl-0 pr-1">
                    <v-checkbox
                      :readonly="isViewMode"
                      :disabled="!isViewMode && !isAdmin"
                      class="mt-0 pt-0"
                      label="Music: Writer"
                      v-model="adminDetails.shakespeareMusicWriter"
                    ></v-checkbox
                  ></v-col>
                  <v-col cols="12" sm="3" class="d-flex justify-center pl-0 pr-1">
                    <v-checkbox
                      :readonly="isViewMode"
                      :disabled="!isViewMode && !isAdmin"
                      class="mt-0 pt-0"
                      label="Video: Writer"
                      v-model="adminDetails.shakespeareVideoWriter"
                    ></v-checkbox
                  ></v-col>
                  <v-col cols="12" sm="3" class="d-flex justify-center pl-0 pr-1">
                    <v-checkbox
                      :readonly="isViewMode"
                      :disabled="!isViewMode && !isAdmin"
                      class="mt-0 pt-0"
                      label="CW: Writer"
                      v-model="adminDetails.cwwriter"
                    ></v-checkbox
                  ></v-col>
                </v-row>
                <v-row class="align-center ma-0">
                  <v-col cols="12" sm="3" class="d-flex justify-center pl-0 pr-1">
                    <v-checkbox
                      :readonly="isViewMode"
                      :disabled="!isViewMode && !isAdmin"
                      class="mt-0 pt-0"
                      label="Apps: Editor"
                      v-model="adminDetails.shakespeareAppsEditor"
                    ></v-checkbox
                  ></v-col>
                  <v-col cols="12" sm="3" class="d-flex justify-center pl-0 pr-1">
                    <v-checkbox
                      :readonly="isViewMode"
                      :disabled="!isViewMode && !isAdmin"
                      class="mt-0 pt-0"
                      label="Music: Editor"
                      v-model="adminDetails.shakespeareMusicEditor"
                    ></v-checkbox
                  ></v-col>
                  <v-col cols="12" sm="3" class="d-flex justify-center pl-0 pr-1">
                    <v-checkbox
                      :readonly="isViewMode"
                      :disabled="!isViewMode && !isAdmin"
                      class="mt-0 pt-0"
                      label="Video: Editor"
                      v-model="adminDetails.shakespeareVideoEditor"
                    ></v-checkbox
                  ></v-col>
                  <v-col cols="12" sm="3" class="d-flex justify-center pl-0 pr-1">
                    <v-checkbox
                      :readonly="isViewMode"
                      :disabled="!isViewMode && !isAdmin"
                      class="mt-0 pt-0"
                      label="CW: Editor"
                      v-model="adminDetails.cweditor"
                    ></v-checkbox
                  ></v-col>
                </v-row>
              </div>
            </v-col>
            <v-col
              v-if="isEditMode && !profileView"
              cols="12"
              sm="12"
              class="d-flex justify-center pb-0"
            >
              <!-- locked until assignments capacity implementation -->
              <v-btn x-large @click="adminCalendarMode = true" disabled>
                Availability<v-icon right>mdi-calendar-month</v-icon>
              </v-btn>
            </v-col>
          </v-container>
        </v-tab-item>
        <!-- EXPERTISE -->
        <v-tab-item eager>
          <v-container class="d-flex flex-wrap">
            <v-col cols="12" sm="4" class="py-0">
              <h2 class="text-center mb-2">Skills</h2>
              <v-row
                v-for="item in Object.keys(skill)"
                :key="item"
                class="mt-0"
              >
                <v-col cols="12" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    :label="getYearsOfExperienceLabel(item)"
                    :items="yearsOfExperienceOptions"
                    :value="handleNestedData(`skill.${item}`)"
                    @change="handleNestedData(`skill.${item}`, $event)"
                    :rules="isAdmin ? [] : [rules.required]"
                  ></v-autocomplete
                ></v-col>
              </v-row>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="8" class="py-0">
              <h2 class="text-center mb-2">Genre Expertise and Specialty</h2>
              <v-expansion-panels :value="0">
                <v-expansion-panel
                  v-for="(item, index) in Object.keys(generalExpertise)"
                  :key="index"
                >
                  <v-expansion-panel-header
                    :class="{
                      'expertise-error':
                        !isAdmin &&
                        !isViewMode &&
                        !generalExpertise[`${item}`].years,
                    }"
                  >
                    {{ expertiseTitles[index] }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Years of Experience"
                        :items="yearsOfExperienceOptions"
                        :value="
                          handleNestedData(`generalExpertise.${item}.years`)
                        "
                        @change="
                          handleNestedData(
                            `generalExpertise.${item}.years`,
                            $event
                          )
                        "
                        :rules="isAdmin ? [] : [rules.required]"
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Genre/Topic Expertise"
                        multiple
                        chips
                        deletable-chips
                        :clearable="!isViewMode"
                        :items="getGenreExpertiseOptions(item)"
                        :value="
                          handleNestedData(`generalExpertise.${item}.genre`)
                        "
                        @change="
                          handleNestedData(
                            `generalExpertise.${item}.genre`,
                            $event
                          )
                        "
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Specialty"
                        multiple
                        chips
                        deletable-chips
                        :clearable="!isViewMode"
                        :items="specialityOptions"
                        :value="
                          handleNestedData(
                            `generalExpertise.${item}.specialities`
                          )
                        "
                        @change="
                          handleNestedData(
                            `generalExpertise.${item}.specialities`,
                            $event
                          )
                        "
                      ></v-autocomplete
                    ></v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-container>
        </v-tab-item>
        <!-- SYSTEMS AND DEVICES -->
        <v-tab-item>
          <v-container class="d-flex flex-wrap">
            <v-col cols="12" sm="6" class="py-0">
              <h2 class="text-center">Software</h2>
              <p class="text-center caption mb-2">
                SOFTWARE YOU HAVE STRONG KNOWLEDGE OF
              </p>
              <v-autocomplete
                v-for="software in Object.keys(
                  softwareAndDevices.staffSoftware
                ).sort()"
                :key="software"
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                :label="`${staffSoftwareLabels[software]}`"
                multiple
                chips
                deletable-chips
                :items="getStaffSoftareOptions(software)"
                :value="
                  handleNestedData(
                    `softwareAndDevices.staffSoftware.${software}`
                  )
                "
                @change="
                  handleNestedData(
                    `softwareAndDevices.staffSoftware.${software}`,
                    $event
                  )
                "
              ></v-autocomplete>
              <h2 class="text-center">Operating System</h2>
              <p class="text-center caption mb-2">
                OPERATING SYSTEM YOUR DEVICE RUNS ON
              </p>
              <v-row class="mt-0">
                <v-col cols="12" class="d-flex flex-wrap py-0">
                  <v-autocomplete
                    v-for="(os, index) in Object.keys(
                      softwareAndDevices.staffOS
                    ).sort()"
                    :key="index"
                    class="staff-form__staff-os-year"
                    :class="`p${(index + 1) % 2 ? 'r' : 'l'}-3`"
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    :label="getOsLabel(os)"
                    :items="yearOptions"
                    :value="
                      handleNestedData(`softwareAndDevices.staffOS.${os}`)
                    "
                    @change="
                      handleNestedData(
                        `softwareAndDevices.staffOS.${os}`,
                        $event
                      )
                    "
                  ></v-autocomplete
                ></v-col>
              </v-row>
            </v-col>
            <v-divider inset vertical></v-divider>
            <v-col cols="12" sm="6" class="py-0">
              <h2 class="text-center">Hardware</h2>
              <p class="text-center caption mb-2">
                HARDWARE YOU CAN USE FOR COMPANY CUE PROJECTS
              </p>
              <v-autocomplete
                v-for="(hardware, index) in Object.keys(
                  softwareAndDevices.staffHardware
                )"
                :key="index"
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                :label="`${staffHardwareLabels[index]}`"
                multiple
                chips
                deletable-chips
                :clearable="!isViewMode"
                :items="getStaffHardwareOptions(hardware)"
                :value="
                  handleNestedData(
                    `softwareAndDevices.staffHardware.${hardware}`
                  )
                "
                @change="
                  handleNestedData(
                    `softwareAndDevices.staffHardware.${hardware}`,
                    $event
                  )
                "
              ></v-autocomplete>
            </v-col>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions
        :class="isEditMode ? 'justify-space-between' : 'justify-end'"
      >
        <div v-if="isEditMode">
          <v-btn
            v-if="profileView ? itemToEdit.adminDetails.lastDeliveryDate : true"
            class="ml-2"
            color="primary"
            :href="`${getDashboardPath('assignmentDashboard')}${
              profileView
                ? ''
                : `?ppm=${$moment(
                    itemToEdit.adminDetails.lastDeliveryDate
                  ).format('MMMM')}&ppy=${$moment(
                    itemToEdit.adminDetails.lastDeliveryDate
                  ).format('YYYY')}&Person=${fullName}`
            }`"
            target="_blank"
            :disabled="!itemToEdit?.adminDetails?.lastDeliveryDate"
            >Assignments
          </v-btn>
        </div>
        <v-scale-transition>
          <div v-if="deleteConfirmation" class="d-flex align-center">
            <h3>Are you sure?</h3>
            <v-btn class="ml-2" color="success" @click="deleteStaff">
              Yes
            </v-btn>
            <v-btn
              class="ml-2"
              color="error"
              @click="deleteConfirmation = false"
            >
              No
            </v-btn>
          </div>
        </v-scale-transition>
        <v-scale-transition>
          <v-container
            v-if="closingConfirmation"
            class="d-flex align-center staff-form__close-conf-block"
          >
            <h5>
              {{
                `Do you want to leave without saving your changes? To save your changes, press "No" and then press "Update"${
                  profileLock ? " on your SYSTEMS AND DEVICES tab" : ""
                }.`
              }}
            </h5>
            <v-btn class="ml-2" color="success" @click="closeForm"> Yes </v-btn>
            <v-btn
              class="ml-2"
              color="error"
              @click="closingConfirmation = false"
            >
              No
            </v-btn>
          </v-container>
        </v-scale-transition>
        <div>
          <v-scroll-x-reverse-transition>
            <v-btn
              v-if="!isViewMode && isEditMode && !profileView && isAdmin"
              min-width="unset"
              color="secondary"
              :loading="staffHandling"
              :disabled="deleteConfirmation"
              @click="deleteConfirmation = true"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-scroll-x-reverse-transition>
          <v-scroll-x-reverse-transition>
            <v-btn
              v-if="!isViewMode && (!isManager || profileView) && showUpateBtn"
              class="ml-2"
              color="secondary"
              :loading="staffHandling"
              :disabled="deleteConfirmation || closingConfirmation"
              @click="handleStaff"
            >
              {{ isEditMode ? "Update" : "Save" }}
            </v-btn>
          </v-scroll-x-reverse-transition>
          <v-btn
            v-if="
              isEditMode &&
              ((!isManager &&
                $store.getters['auth/user'].user.id !== itemToEdit.user.id) ||
                profileView)
            "
            class="ml-2"
            color="secondary"
            :disabled="deleteConfirmation || closingConfirmation"
            @click="handleValidationOnModeChange"
          >
            {{ isViewMode ? "Edit Mode" : "View Mode" }}
          </v-btn>
          <v-btn
            class="ml-2"
            color="secondary"
            :loading="staffHandling"
            :disabled="deleteConfirmation || closingConfirmation"
            @click="handleClose"
          >
            {{ isViewMode ? "Close" : "Cancel" }}
          </v-btn>
        </div></v-card-actions
      >
    </v-form>
    <Calendar v-if="calendarMode" :clientView="true" :employee="user" />
    <AdminCalendar
      v-if="adminCalendarMode"
      :profileView="true"
      :mode="1"
      :employeeToSearch="{ name: fullName }"
    />
    <v-btn
      v-if="calendarMode || adminCalendarMode"
      min-width="45px"
      height="45px"
      class="staff-form__return-btn px-0"
      color="primary"
      @click="calendarMode = adminCalendarMode = false"
      ><v-icon>mdi-arrow-right</v-icon></v-btn
    >
    <!-- tabs validation note -->
    <v-snackbar
      v-model="formProgressSnackbar"
      min-width="fit-content"
      elevation="12"
      timeout="-1"
      color="secondary"
      rounded="pill"
      content-class="text-center"
      centered
    >
      {{
        `Please check all form tabs and make sure that required fields are
      completed${
        isAdmin
          ? ""
          : " (don't forget the dropdown menus in the Expertise section)"
      }.`
      }}
      <v-btn icon @click="formProgressSnackbar = false">
        <v-icon> mdi-close-circle-outline </v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// library
import { saveAs } from "file-saver";
// internal
import {
  processUser,
  deleteUser,
  resetPassword,
  toTitleCase,
  getEnum,
  getFile,
  getProjectTools,
} from "@/utils/newDbUtils";
import defaultAvatar from "@/assets/cue_logo.svg";
import { workspaces } from "@/utils/slackUtils";
import { DOMAIN, ROUTES } from "@/utils/constants";
import SHK from "@/assets/SHK.svg";
import { timeUnits } from "@/utils/mixins";
// components
import Calendar from "@/components/AvailabilityCalendar/Calendar";
import AdminCalendar from "@/components/AvailabilityCalendar/AdminCalendar";

export default {
  name: "StaffForm",
  components: {
    Calendar,
    AdminCalendar,
  },
  props: {
    itemToEdit: {
      type: Object,
      required: true,
    },
    profileView: {
      type: Boolean,
      required: true,
    },
    isOperating: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      tab: 1,
      formProgressSnackbar: false,
      dropdownsLoading: true,
      defaultAvatar,
      adminDetails: {
        appleCopyPortal: false,
        boxAccess: false,
        cweditor: false,
        cwwriter: false,
        defaultDailyEditing: null,
        defaultDailyLocalization: null,
        defaultWeeklyEditing: null,
        defaultWeeklyWriting: null,
        dropboxAccess: false,
        dubEditorAccess: false,
        endDate: null,
        geos: [],
        leadEditor: false,
        vendor: false,
        shakespeareAppsEditor: false,
        shakespeareAppsWriter: false,
        shakespeareMusicEditor: false,
        shakespeareMusicWriter: false,
        shakespeareVideoEditor: false,
        shakespeareVideoWriter: false,
        slackIdFc: null,
        slackIdPm: null,
        slackIdStaff: null,
        smartCatAccess: false,
        smartlingAccess: false,
        smartlingAccessApple: false,
        startDate: null,
        status: null,
        trelloAccess: false,
        tryRating: false,
      },
      startDateNotTimestamp: null,
      endDateNotTimestamp: null,
      SHK,
      geoOptions: [],
      userStatusOptions: [],
      startDateMenu: false,
      endDateMenu: false,
      resetLoading: false,
      generalExpertise: {
        book: {
          genre: [],
          specialities: [],
          years: null,
        },
        film: {
          genre: [],
          specialities: [],
          years: null,
        },
        lifestyle: {
          genre: [],
          specialities: [],
          years: null,
        },
        mobileAndVideoGames: {
          genre: [],
          specialities: [],
          years: null,
        },
        mobileApps: {
          genre: [],
          specialities: [],
          years: null,
        },
        music: {
          genre: [],
          specialities: [],
          years: null,
        },
        podcast: {
          genre: [],
          specialities: [],
          years: null,
        },
        sports: {
          genre: [],
          specialities: [],
          years: null,
        },
      },
      expertiseTitles: [
        "Books",
        "Film & TV",
        "Lifestyle",
        "Mobile/Video Games",
        "Mobile Apps",
        "Music",
        "Podcasts",
        "Sports",
      ],
      bookOptions: [],
      filmOptions: [],
      lifestyleOptions: [],
      mobileAndVideoGamesOptions: [],
      mobileAppsOptions: [],
      musicOptions: [],
      podcastOptions: [],
      sportsOptions: [],
      specialityOptions: [],
      yearsOfExperienceOptions: [],
      hrDetails: {
        contract: null,
        country: null,
        cueEmail: null,
        currency: null,
        gmail: null,
        homeAddress: null,
        zipCode: null,
        legalName: null,
        nda: null,
        paymentType: null,
        payPalEmail: null,
        wiseEmail: null,
        phoneNumber: null,
        ssnOrTaxId: null,
        taxDocuments: null,
        taxResidency: null,
      },
      paymentTypeOptions: [],
      currencyOptions: [],
      countryOptions: [],
      loadPredictions: false,
      addresses: [],
      contractBlob: null,
      ndaBlob: null,
      taxDocumentsBlob: null,
      personalDetails: {
        bio: null,
        language1: { language: null, languageLevel: "Native" },
        language2: { language: null, languageLevel: null },
        language3: { language: null, languageLevel: null },
        linkedIn: null,
        photo: null,
        portfolio: null,
        preferredName: null,
        pronouns: null,
        website: null,
      },
      avatar: "",
      pronounsList: ["She/Her", "He/Him", "They/Them"],
      languageOptions: [],
      languageLevelOptions: [],
      hardwareLoading: false,
      cueHardware: [],
      softwareLoading: false,
      cueSoftware: [],
      photoBlob: null,
      portfolioBlob: null,
      skill: {
        copyediting: null,
        copywriting: null,
        curation: null,
        design: null,
        editingTranslations: null,
        metadata: null,
        projectManagement: null,
        translation: null,
      },
      softwareAndDevices: {
        staffHardware: {
          desktops: [],
          laptops: [],
          phones: [],
          streamingTvs: [],
          tablets: [],
          vrAr: [],
          watch: [],
        },
        staffOS: {
          chromeOs: null,
          linux: null,
          macOs: null,
          windows: null,
        },
        staffSoftware: {
          catTools: [],
          filmTVStreamingServices: [],
          musicStreamingServices: [],
          productivityTools: [],
        },
      },
      staffSoftwareLabels: {
        catTools: "CAT Tools",
        filmTVStreamingServices: "Film/TV Streaming Services",
        musicStreamingServices: "Music Streaming Services",
        productivityTools: "Productivity Tools",
      },
      deviceBrandOptions: [],
      phoneOptions: [],
      streamingTvOptions: [],
      tabletOptions: [],
      vrArOptions: [],
      watcheOptions: [],
      staffHardwareLabels: [
        "Desktop Computer",
        "Laptop",
        "Phone",
        "Tablet",
        "Streaming TV Device",
        "VR/AR Device",
        "Watch",
      ],
      catToolsOptions: [],
      filmTVStreamingServicesOptions: [],
      musicStreamingServicesOptions: [],
      productivityToolsOptions: [],
      user: {
        cueHubAccess: false,
        email: null,
        firstName: null,
        lastName: null,
        userRole: null,
      },
      operationalRolesList: [
        "Lead Editor",
      ],
      userRoleOptions: [],
      staffDocument: {
        portfolio: null,
        photo: null,
        taxDocuments: null,
        nda: null,
        contract: null,
      },
      modifiedKeys: [],
      filesLoading: false,
      isViewMode: false,
      calendarMode: false,
      adminCalendarMode: false,
      staffHandling: false,
      deleteConfirmation: false,
      closingConfirmation: false,
      showUpateBtn: true,
      zipCodeError: false,
      valid: true,
      rules: {
        required: (value) => !!value || "Required",
        requiredFile: (value) =>
          (value ? !!(value?.size + "") : !!value) || "Required",
        isImage: (value) =>
          value?.type == "image/jpeg" ||
          value?.type == "image/jpg" ||
          value?.type == "image/png" ||
          value === null ||
          "Only JPEG (JPG) or PNG images are allowed.",
        isPDF: (value) =>
          value?.type == "application/pdf" ||
          value === null ||
          "Only PDF attachments are allowed.",
        validEmail: (value) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          "E-mail must be valid",
      },
    };
  },
  mounted() {
    this.getDropdowns();
    this.dataViewsHandler();
  },
  mixins: [timeUnits],
  computed: {
    ...mapGetters("auth", ["isAdmin", "isManager", "profileLock"]),
    steps() {
      const allSteps = [
        { icon: "card-account-details-outline", title: "OVERVIEW" },
        { icon: "account-circle-outline", title: "PERSONAL DETAILS" },
        { icon: "file-account-outline", title: "HR DETAILS" },
        { icon: "star-circle-outline", title: "EXPERTISE" },
        { icon: "devices", title: "SYSTEMS AND DEVICES" },
      ];
      if (this.isAdmin || this.isManager) {
        if (!allSteps.includes({ icon: "security", title: "ADMIN DETAILS" }))
          allSteps.splice(3, 0, { icon: "security", title: "ADMIN DETAILS" });
      } else {
        if (allSteps.includes({ icon: "security", title: "ADMIN DETAILS" }))
          allSteps.splice(3, 1);
      }
      return allSteps;
    },
    isEditMode() {
      return !!Object.keys(this.itemToEdit).length;
    },
    formattedStartDate: {
      get() {
        return this.formatPickerDate(this.startDateNotTimestamp);
      },
      set() {
        this.startDateNotTimestamp = null;
      },
    },
    formattedEndDate: {
      get() {
        return this.formatPickerDate(this.endDateNotTimestamp);
      },
      set() {
        this.endDateNotTimestamp = null;
      },
    },
    appleLogo() {
      return (
        this.handleLogo("Apple") ||
        this.softwareAndDevices.staffOS.macOs ||
        this.handleLogo("Apple Music") ||
        this.handleLogo("Apple TV") ||
        this.handleLogo("Apple Keynote") ||
        this.handleLogo("Apple Numbers") ||
        this.handleLogo("Apple Pages")
      );
    },
    androidLogo() {
      return (
        this.handleLogo("Lenovo") ||
        this.handleLogo("Samsung") ||
        this.handleLogo("Xiaomi")
      );
    },
    languagesList() {
      return this.languageOptions.map((el) => {
        return {
          value: el,
          disabled: [
            this.personalDetails.language1.language,
            this.personalDetails.language2.language,
            this.personalDetails.language3.language,
          ].includes(el),
        };
      });
    },
    operationalRoles: {
      get() {
        const roles = [];
        if (this.itemToEdit?.adminDetails?.leadEditor) {
          roles.push("Lead Editor");
        }
        return roles;
      },
      set(value) {
        if (value.includes("Lead Editor")) {
          this.adminDetails.leadEditor = true;
        }
      },
    },
    userRoleUpdate: {
      get() {
        if (this.user?.userRole === "STAFF" && this.adminDetails?.vendor) return "VENDOR";
        return this.user.userRole;
      },
      set(newVal) {
        if (newVal && newVal === "VENDOR") {
          this.adminDetails.vendor = true;
          this.user.userRole = "STAFF";
        } else {
          this.user.userRole = newVal;
          this.adminDetails.vendor = false;
        }
      }
    },
    fullName: {
      get() {
        return `${this.user.firstName} ${this.user.lastName}`;
      },
      set(newVal) {
        if (newVal)
          [this.user.firstName, this.user.lastName] = newVal.split(" ");
        else this.user.firstName = this.user.lastName = null;
      },
    },
    userRoles() {
      this.userRoleOptions.push("VENDOR");
      return this.userRoleOptions.filter((el) => el !== "CLIENT").sort();
    },
    cueFC() {
      return workspaces.find((el) => el.name === "cue-fc").id;
    },
    cueStaff() {
      return workspaces.find((el) => el.name === "Cue Staff").id;
    },
    cuePM() {
      return workspaces.find((el) => el.name === "PM").id;
    },
    skillsBadges() {
      const skillsList = Object.keys(this.skill)
        .map((el) => ({
          skill: toTitleCase(el),
          index: this.getExpIndex("skill", el),
        }))
        .filter((el) => el.index !== 4 && el.index !== undefined)
        .sort((a, b) => a.index - b.index);
      return skillsList;
    },
    expertiseBadges() {
      const expertiseList = Object.keys(this.generalExpertise)
        .map((el) => ({
          expertise:
            this.expertiseTitles[
              Object.keys(this.generalExpertise).indexOf(el)
            ],
          index: this.getExpIndex("generalExpertise", el),
        }))
        .filter((el) => el.index !== 4 && el.index !== undefined)
        .sort((a, b) => a.index - b.index);
      return expertiseList;
    },
    fullHardwareDashboardPath() {
      return `${DOMAIN}/${ROUTES.cueHardware}`;
    },
    fullSoftwareDashboardPath() {
      return `${DOMAIN}/${ROUTES.cueSoftware}`;
    },
    isFormCompleted() {
      const required = [
          "user.firstName",
          "user.lastName",
          "personalDetails.language1.language",
          "user.email",
          "hrDetails.country",
        ],
        candidateRequired = required.concat([
          "adminDetails.status",
          "adminDetails.startDate",
        ]),
        adminRequired = required.concat([
          "hrDetails.paymentType",
          "hrDetails.currency",
          "hrDetails.nda",
          "hrDetails.contract",
          "adminDetails.status",
          "adminDetails.startDate",
          "user.userRole",
        ]),
        userRequired = required.concat([
          "personalDetails.preferredName",
          "personalDetails.bio",
          "hrDetails.homeAddress",
          "hrDetails.legalName",
          "hrDetails.taxResidency",
          "skill.copyediting",
          "skill.copywriting",
          "skill.curation",
          "skill.design",
          "skill.editingTranslations",
          "skill.metadata",
          "skill.projectManagement",
          "skill.translation",
          "generalExpertise.book.years",
          "generalExpertise.film.years",
          "generalExpertise.lifestyle.years",
          "generalExpertise.mobileAndVideoGames.years",
          "generalExpertise.mobileApps.years",
          "generalExpertise.music.years",
          "generalExpertise.podcast.years",
          "generalExpertise.sports.years",
        ]);
      if (this.userRoleUpdate === "CANDIDATE") {
        return candidateRequired.every((el) =>
          this.$data.hasOwnProperty(`${el.split(".")[1]}Blob`)
            ? this[`${el.split(".")[1]}Blob`] !== null
            : el.split(".").length === 2
            ? !!this[`${el.split(".")[0]}`][`${el.split(".")[1]}`]
            : !!this[`${el.split(".")[0]}`][`${el.split(".")[1]}`][
                `${el.split(".")[2]}`
              ]
        );
      }
      return (this.isAdmin ? adminRequired : userRequired).every((el) =>
        this.$data.hasOwnProperty(`${el.split(".")[1]}Blob`)
          ? this[`${el.split(".")[1]}Blob`] !== null
          : el.split(".").length === 2
          ? !!this[`${el.split(".")[0]}`][`${el.split(".")[1]}`]
          : !!this[`${el.split(".")[0]}`][`${el.split(".")[1]}`][
              `${el.split(".")[2]}`
            ]
      );
    },
    role() {
      return this.user.userRole;
    },
    language2() {
      return this.personalDetails.language2.language;
    },
    language3() {
      return this.personalDetails.language3.language;
    },
  },
  watch: {
    tab() {
      this.handleUpdateBtnDisplay();
    },
    itemToEdit() {
      if (this.isOperating) this.dataViewsHandler();
    },
    language2(newVal) {
      if (!newVal) this.personalDetails.language2.languageLevel = null;
    },
    language3(newVal) {
      if (!newVal) this.personalDetails.language3.languageLevel = null;
    },
    role(newVal) {
      this.adminDetails.dubEditorAccess =
        newVal === "ADMIN"
          ? true
          : this.itemToEdit?.adminDetails?.dubEditorAccess ?? false;
    },
    'hrDetails.homeAddress'(newVal) {
      if (newVal && newVal !== '' && (!this.itemToEdit?.hrDetails?.zipCode || this.itemToEdit?.hrDetails?.homeAddress !== newVal)) {
        this.fetchZipCodeFromGoogleMaps(newVal);
      }
    },
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    addKeyToModified(key, value) {
      const prevModifiedKey = this.modifiedKeys.find((el) =>
        el.hasOwnProperty(key)
      );
      if (prevModifiedKey)
        this.modifiedKeys.splice(
          this.modifiedKeys.indexOf(prevModifiedKey),
          1,
          {
            [key]: value,
          }
        );
      else this.modifiedKeys.push({ [key]: value });
    },
    validateLanguage1Level(value) {
      this.personalDetails.language1.languageLevel = "Native";
      this.addKeyToModified("language1", value);
    },
    handleNestedData(key, value) {
      if (value !== undefined)
        key.split(".").length === 2
          ? (this[key.split(".")[0]][key.split(".")[1]] =
              value === "" ? null : value)
          : (this[key.split(".")[0]][key.split(".")[1]][key.split(".")[2]] =
              value === "" ? null : value);
      else
        return key.split(".").length === 2
          ? this[key.split(".")[0]][key.split(".")[1]]
          : this[key.split(".")[0]][key.split(".")[1]][key.split(".")[2]];

      const capitalizeFirstLetter = (string) => {
          return string.charAt(0).toUpperCase() + string.slice(1);
        },
        modifiedKey = `${key.split(".")[1]}${capitalizeFirstLetter(
          key.split(".")[2]
        )}`;
      this.addKeyToModified(modifiedKey, value);
    },
    formatFileName(fileField) {
      const uniqueNames = {
        taxDocuments: "W9",
        contract: "ICA",
      };
      return uniqueNames[`${fileField}`] ?? toTitleCase(fileField);
    },
    getGenreExpertiseOptions(expertise) {
      return this[`${expertise}Options`];
    },
    getStaffSoftareOptions(software) {
      return this[`${software}Options`];
    },
    getStaffHardwareOptions(hardware) {
      if (hardware === "desktops" || hardware === "laptops")
        return this.deviceBrandOptions;
      else if (hardware === "vrAr") return this[`${hardware}Options`];
      else if (hardware === "watch") return this[`${hardware}eOptions`];
      else return this[`${hardware.slice(0, -1)}Options`];
    },
    getYearsOfExperienceLabel(item) {
      return `${toTitleCase(item)}; Years of Experience`;
    },
    getOsLabel(os) {
      return `${toTitleCase(os)} Version Year`;
    },
    getAddress(input) {
      return new Promise((resolve, reject) => {
        const service = new google.maps.places.AutocompleteService();

        const displaySuggestions = (predictions, status) => {
          if (
            status != google.maps.places.PlacesServiceStatus.OK ||
            !predictions
          ) {
            reject(console.error(status));
            this.loadPredictions = false;
            return;
          }
          resolve((this.addresses = predictions.map((el) => el.description)));
          this.loadPredictions = false;
        };

        if (input) {
          this.loadPredictions = true;
          service.getQueryPredictions({ input }, displaySuggestions);
        }
      });
    },
    handleFormProgress() {
      if (this.isViewMode) this.$refs.form.resetValidation();
      else this.$refs.form.validate();
    },
    handleValidationOnModeChange() {
      if (this.tab === 0 && this.isViewMode) this.tab = 1;
      this.isViewMode = !this.isViewMode;
      this.handleFormProgress();
    },
    handleLogo(value) {
      return (
        this.softwareAndDevices.staffSoftware.musicStreamingServices?.includes(
          value
        ) ||
        this.softwareAndDevices.staffSoftware.filmTVStreamingServices?.includes(
          value
        ) ||
        this.softwareAndDevices.staffSoftware.productivityTools?.includes(
          value
        ) ||
        this.softwareAndDevices.staffSoftware.catTools?.includes(value) ||
        this.softwareAndDevices.staffHardware.desktops?.includes(value) ||
        this.softwareAndDevices.staffHardware.laptops?.includes(value) ||
        this.softwareAndDevices.staffHardware.phones?.includes(value) ||
        this.softwareAndDevices.staffHardware.streamingTvs?.includes(value) ||
        this.softwareAndDevices.staffHardware.tablets?.includes(value) ||
        this.softwareAndDevices.staffHardware.vrAr?.includes(value) ||
        this.softwareAndDevices.staffHardware.watch?.includes(value)
      );
    },
    dataViewsHandler() {
      if (this.isEditMode) {
        this.handleEditMode();
      } else {
        this.$refs.form.reset();
        this.fullName = "";
        // reset nested v-for data
        Object.keys(this.generalExpertise).forEach((key) => {
          this.generalExpertise[key].genre = [];
          this.generalExpertise[key].specialities = [];
          this.generalExpertise[key].years = null;
        });
        Object.keys(this.skill).forEach((key) => {
          this.skill[key] = null;
        });
        Object.entries(this.softwareAndDevices).forEach((entry) => {
          Object.keys(entry[1]).forEach(
            (el) =>
              (this.softwareAndDevices[entry[0]][el] =
                entry[0] === "staffOS" ? null : [])
          );
        });
        // reset file blobs
        this.contractBlob =
          this.ndaBlob =
          this.taxDocumentsBlob =
          this.photoBlob =
          this.portfolioBlob =
            null;
      }
      this.isViewMode = this.isEditMode;
      this.handleFormProgress();
    },
    getDropdowns() {
      getEnum().then((data) => {
        Object.keys(data).forEach((key) => (this[`${key}Options`] = data[key]));
        this.dropdownsLoading = false;
      });
    },
    handleEditMode() {
      const fileFields = [
        "photo",
        "nda",
        "taxDocuments",
        "contract",
        "portfolio",
      ];
      this.filesLoading = true;
      let files = [];

      // loop through fields
      Object.entries(this.itemToEdit).forEach((el) => {
        Object.keys(el[1]).forEach((key) => {
          this[el[0]][key] = this.itemToEdit[el[0]][key];

          // handle timestamp for standardized formatting
          if (key === "startDate" || key === "endDate") {
            this[`${key}NotTimestamp`] = this.itemToEdit[el[0]][key]
              ? this.$moment
                  .unix(this.itemToEdit[el[0]][key])
                  .format("YYYY-MM-DD")
              : "";
          }

          // handle files
          if (fileFields.includes(key)) {
            if (this.itemToEdit[el[0]][key])
              files.push({
                url: this.itemToEdit[el[0]][key],
                name: key,
                isImage: key === "photo",
              });
            else this[`${key}Blob`] = null;
          }
        });
      });

      // get files
      this.handleFiles(files);

      // get hardware & software
      this.getEmployeeHardware();
      this.getEmployeeSoftware();

      // validate address
      this.getAddress(this.hrDetails.homeAddress).then((predictions) => {
        if (!predictions.includes(this.hrDetails.homeAddress))
          this.hrDetails.homeAddress = null;
        this.handleFormProgress();
      });
    },
    handleFile(url, file, isImage) {
      return new Promise((resolve, reject) => {
        getFile({ url }).then(async (string) => {
          this.staffDocument[file] = ""; // indicates to backend the document don't needs to be updated
          const base64Response = await fetch(
            `data:${isImage ? "image/jpg" : "application/pdf"};base64,${string}`
          );
          const blob = await base64Response.blob();
          let newBlob = new Blob([blob], {
            type: isImage ? "image/jpg" : "application/pdf",
          });
          newBlob.name = `${this.fullName}_${this.formatFileName(file)}.${
            isImage ? "jpg" : "pdf"
          }`;
          if (isImage) this.avatar = base64Response.url;
          resolve((this[`${file}Blob`] = newBlob));
        });
      });
    },
    handleFiles(files) {
      const promises = files.map((file) => {
        return this.handleFile(file.url, file.name, file.isImage);
      });
      Promise.all(promises)
        .then(() => (this.filesLoading = false))
        .catch((err) => {
          console.error(err);
          this.filesLoading = false;
        });
    },
    getEmployeeHardware() {
      this.hardwareLoading = true;
      getProjectTools({ tool: "hardware", user: this.user }).then((data) => {
        const getIcon = (category) => {
          switch (category) {
            case "Computer Desktop":
              return "desktop-tower";
            case "Computer Laptop":
              return "laptop";
            case "Phone":
              return "cellphone";
            case "Tablet":
              return "tablet";
            case "Smart TV":
              return "monitor-cellphone-start";
            case "Monitor":
              return "monitor";
            case "Keyboard":
              return "keyboard";
            case "Headphones":
              return "headphones";
            case "Cable":
              return "cable-data";
            case "Wearable":
              return "watch-variant";
            default:
              return "help-circle";
          }
        };
        this.cueHardware = data.length
          ? data.map((el) => {
              return {
                category: el.deviceCategory,
                icon: getIcon(el.deviceCategory),
                onHandDate: `On hand since ${this.$moment
                  .unix(el.onHandStartDate)
                  .format("MM-DD-YYYY")}`,
                mainInfo: `${el.deviceBrand} / ${el.model}`,
              };
            })
          : [
              {
                category: null,
                icon: "power-plug-off",
                onHandDate: null,
                mainInfo: "No devices on hand.",
              },
            ];
        this.hardwareLoading = false;
      });
    },
    getEmployeeSoftware() {
      this.softwareLoading = true;
      getProjectTools({ tool: "software", user: this.user }).then((data) => {
        const getIcon = (software) => {
          if (software.includes("Slack")) return "slack";
          if (
            software.includes("iCloud") ||
            software.includes("cloud") ||
            software.includes("Cloud")
          )
            return "cloud";
          if (software.includes("Office")) return "microsoft-office";
          if (software.includes("Microsoft")) return "microsoft";
          if (software.includes("Dropbox")) return "dropbox";
          if (software.includes("Trello")) return "trello";
          if (software.includes("Apple")) return "food-apple";
          if (software.includes("Linkedin") || software.includes("LinkedIn"))
            return "linkedin";
          return "exit-to-app";
        };
        this.cueSoftware = data.length
          ? data.map((el) => {
              return {
                provider: getIcon(el.serviceName),
                name: el.serviceName,
                type: el.serviceType,
              };
            })
          : [
              {
                provider: "information-outline",
                name: "No software in use.",
                type: null,
              },
            ];
        this.softwareLoading = false;
      });
    },
    downloadFile(fileName) {
      saveAs(
        this[`${fileName}Blob`],
        `${this.fullName}_${this.formatFileName(fileName)}`
      );
    },
    seeOnMap() {
      window.open(
        `http://maps.google.com/?q=${this.hrDetails.homeAddress}`,
        "_blank"
      );
    },
    sendEmail(email) {
      window.open(`mailto:${email}`);
    },
    getDashboardPath(dashboard) {
      return `${DOMAIN}/${ROUTES[dashboard]}`;
    },
    handleCalendar() {
      this.profileView
        ? window.open(`${DOMAIN}/${ROUTES.calendar}`, "_blank")
        : (this.calendarMode = true);
    },
    convertFile(file, key) {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () =>
          (this.staffDocument[key] = reader.result?.split(",")[1]);
        reader.readAsDataURL(file);
      } else {
        this.staffDocument[key] = null;
      }
      this.addKeyToModified(key, file ? ":file_folder:" : null);
    },
    handleStaff() {
      this.$refs.form.validate();
      if (!this.isFormCompleted) {
        this.formProgressSnackbar = true;
      } else {
        if (this.$refs.form.validate()) {
          this.staffHandling = true;
          const dto = [
            "adminDetails",
            "generalExpertise",
            "hrDetails",
            "personalDetails",
            "skill",
            "user",
            "softwareAndDevices",
            "staffDocument",
          ];
          let data = {};
          dto.forEach((dto) => {
            Object.assign(data, {
              [dto]: { ...this[`${dto}`] },
            });
          });
          processUser({ data, id: this.itemToEdit?.user?.id }).then((resp) => {
            this.staffHandling = false;
            this.$emit(
              "refresh-data",
              this.profileView && resp?.status <= 204,
              data,
              this.modifiedKeys
            );
            this.handleFlash({ response: resp, show: true });
          });
        }
      }
    },
    deleteStaff() {
      this.deleteConfirmation = false;
      this.staffHandling = true;
      deleteUser(this.itemToEdit?.user?.id).then((resp) => {
        this.staffHandling = false;
        this.$emit("refresh-data");
        this.handleFlash({ response: resp, show: true });
      });
    },
    getExpIndex(dto, exp) {
      switch (
        dto === "generalExpertise" ? this[dto][exp].years : this[dto][exp]
      ) {
        case ">10":
          return 0;
        case "5-10":
          return 1;
        case "2-5":
          return 2;
        case "<2":
          return 3;
        default:
          4;
      }
    },
    sendPasswordReset() {
      this.resetLoading = true;
      resetPassword(this.itemToEdit?.user?.id).then((resp) => {
        this.resetLoading = false;
        this.handleFlash({ response: resp, show: true });
      });
    },
    handleClose() {
      this.isAdmin || (this.isManager && !this.profileView)
        ? this.$emit("close-form")
        : (this.closingConfirmation = true);
    },
    closeForm() {
      this.$emit("close-form");
      this.closingConfirmation = false;
    },
    handleUpdateBtnDisplay() {
      this.showUpateBtn = this.profileLock
        ? this.tab === this.steps.indexOf(this.steps.at(-1))
        : true;
    },
    // data formatting for backend
    handleEmptyString(value, dto, key) {
      if (value === "") {
        if (key === "language2" || key === "language3")
          this[dto][key].language = null;
        else this[dto][key] = null;
      }
      this.addKeyToModified(key, value);
    },
    handleDate(value, dto, key) {
      this[dto][key] = value ? this.$moment(value).format("X") : null;
    },
    async fetchZipCodeFromGoogleMaps(address) {
      let zipCode = null;
      try {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=AIzaSyCr3wpVVWkvlmH20QH90gyxvexQ_ebnf98`;

        const response = await fetch(url);
        const data = await response.json();

        if (data.status === 'OK' && data.results.length > 0) {
          const postalCode = data.results[0]?.address_components.find(
            component => component.types.includes('postal_code')
          );

          if (postalCode) {
            zipCode = postalCode.long_name;
          } else {
            zipCode = null;
          }
        } else {
          zipCode = null;
        }
      } catch (error) {
        console.error('Error fetching ZIP Code:', error);
        zipCode = null;
      }
      if (zipCode !== null) {
        this.hrDetails.zipCode = zipCode;
        this.zipCodeError = false;
      } else {
        this.hrDetails.zipCode = null;
        this.zipCodeError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.staff-form {
  &__profile {
    &-chip {
      width: 100%;
      height: fit-content;
      justify-content: center;
      line-height: 1rem;
      white-space: normal;
      &s-container {
        width: 35%;
        height: 100%;
      }
      &-extra {
        position: absolute;
        bottom: 15px;
      }
      &.v-chip {
        border: 1px ridge grey !important;
      }
    }
    &-avatar {
      &-container {
        width: 50%;
      }
      &-info {
        width: 100%;
      }
    }
    &-actions-container {
      width: 15%;
    }
  }
  &__return-btn {
    position: absolute;
    bottom: 5px;
    right: 10px;
    z-index: 1;
  }
  &__staff-os-year {
    width: 50%;
  }
  &__close-conf-block {
    width: 40%;
  }
}
.v-badge__badge {
  border-radius: 50%;
  height: 30px;
  min-width: 30px;
}
.v-btn--icon.v-size--x-large .v-icon {
  font-size: 40px;
}
.v-input.disabledPicker {
  pointer-events: none;
}
.expertise-error {
  border: 2px solid #b71c1c;
}
::v-deep {
  .v-skeleton-loader__image {
    height: 350px;
  }
  .v-label--active {
    pointer-events: none;
  }
  .v-btn.slack-btn .v-btn__content {
    justify-content: start;
  }
  .v-icon.v-icon.v-icon--link {
    pointer-events: all;
    color: rgba(0, 0, 0, 0.54) !important;
  }
  .v-input--selection-controls .v-input__slot {
    width: fit-content;
  }
  .v-tab--active {
    color: #fff !important;
  }
  .staff-form__permissions {
    & .v-input__prepend-outer {
      margin-right: 0;
    }
  }
}
</style>
