<template>
  <div>
    <v-data-table
      v-model="selectedRows"
      :loading="isTableUpdating"
      :headers="isAdminMode ? adminHeaders : userHeaders"
      :items="tableData"
      item-key="id"
      :header-props="{ sortIcon: null }"
      show-expand
      show-select
      :single-select="!isAdminMode"
      :footer-props="{
        'items-per-page-options': [10, 50, 100, -1],
      }"
      :options.sync="options"
      :server-items-length="serverItemsLength"
      :class="{
        'earnings-mode': showEarnings,
        'table-updating': isTableUpdating,
        laptop,
        laptopSmaller,
      }"
      @toggle-select-all="handleSelectAll"
      @click:row="handleEditForm($event)"
      @update:page="handleTableOptions"
      @update:sort-by="handleTableOptions('sort-by')"
      @update:sort-desc="handleTableOptions('sort-desc')"
      @update:items-per-page="handleTableOptions"
    >
      <!-- Custom header slot for the main checkbox -->
      <template v-slot:header.data-table-select="{ header }">
        <div class="d-flex align-center">
          <v-checkbox
            class="mt-0 mb-1"
            :disabled="isTableUpdating"
            hide-details
            @change="selectAll"
            v-model="checkedAll"
          >
          </v-checkbox>
          <v-menu offset-y v-if="filterProject && !(tabledataAmount <= tableData.length)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs">mdi-chevron-down</v-icon>
            </template>
            <v-list>
              <v-list-item  @click.prevent="selectAllRowsOnTheScreen" class="hover-effect">
                <v-list-item-title>All on page ({{ tableData.length }})</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="user.user.userRole === 'ADMIN'"
                @click="selectAllDataRecords" class="hover-effect">
                <v-list-item-title>All matching ({{ tabledataAmount }})</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <span v-if="filterProject && !(tabledataAmount <= tableData.length) && showSelectedRowsAmount">({{ showSelectedRowsAmount }})</span>
        </div>
      </template>
      <!-- grouping override -->
      <template v-slot:[`group.header`]="{ group, headers }">
        <td class="pa-0 group-header-row" :colspan="headers.length">
          <h2 class="mx-4 font-weight-bold d-inline">
            {{ `${dueText + formatDate(group)}` }}
          </h2>
          <v-chip
            v-if="handleDuesStats(group)"
            color="font-weight-bold white"
            small
          >
            <v-icon left> mdi-clock-alert-outline </v-icon
            >{{ handleDuesStats(group) }}
          </v-chip>
        </td>
      </template>

      <!-- selection override -->
      <template v-slot:[`item.data-table-select`]="{ item }">
        <v-checkbox
          :disabled="
            setRoleMode(item)
              ? !allowedToUpdateStatuses(item).includes(
                  item.assignmentDetails.assignmentStatus
                ) ||
                (bulkMode !== '' && bulkMode !== setRoleMode(item))
              : false
          "
          v-model="item.checked"
          @change="handleSelectedRows($event, item)"
          @click.stop
        ></v-checkbox>
      </template>

      <!-- Writer cell override -->
      <template v-slot:[`item.primary`]="{ item, value }">
        <v-badge
          :value="handleDueAlert(item, 'primary')"
          :color="
            handleDueAlert(item, 'primary') === 'overdue'
              ? '#E57373'
              : handleDueAlert(item, 'primary') === 'upcoming-due'
              ? '#FFB74D'
              : ''
          "
          icon="mdi-clock-alert-outline"
          overlap
        >
          <div
            :class="{
              'assigned-to-user': isActiveUserEmail(item.primary?.email),
            }"
          >
            <div class="text-container text-center">
              {{ `${value?.firstName ?? ""} ${value?.lastName ?? ""}` }}
            </div>
          </div>
        </v-badge>
      </template>

      <!-- Editor cell override -->
      <template v-slot:[`item.secondary`]="{ item, value }">
        <v-badge
          :value="handleDueAlert(item, 'secondary')"
          :color="
            handleDueAlert(item, 'secondary') === 'overdue'
              ? '#E57373'
              : handleDueAlert(item, 'secondary') === 'upcoming-due'
              ? '#FFB74D'
              : ''
          "
          icon="mdi-clock-alert-outline"
          overlap
        >
          <div
            :class="{
              'assigned-to-user': isActiveUserEmail(item?.secondary?.email),
            }"
          >
            <div class="text-container text-center">
              {{ `${value?.firstName ?? ""} ${value?.lastName ?? ""}` }}
            </div>
          </div>
        </v-badge>
      </template>

      <!-- Reviewer cell override -->
      <template v-slot:[`item.qc`]="{ item, value }">
        <v-badge
          :value="handleDueAlert(item, 'qc')"
          :color="
            handleDueAlert(item, 'qc') === 'overdue'
              ? '#E57373'
              : handleDueAlert(item, 'qc') === 'upcoming-due'
              ? '#FFB74D'
              : ''
          "
          icon="mdi-clock-alert-outline"
          overlap
        >
          <div
            :class="{
              'assigned-to-user': isActiveUserEmail(item.qc?.email),
            }"
          >
            <div class="text-container text-center">
              {{ `${value?.firstName ?? ""} ${value?.lastName ?? ""}` }}
            </div>
          </div>
        </v-badge>
      </template>

      <!-- Due Time cell override -->
      <template v-slot:[`item.dueTime`]="{ value }">
        <span>{{ formatDateForTableRow(value, false, true) }}</span>
      </template>

      <!-- File Location cell override -->
      <template v-slot:[`item.assignmentDetails[fileLocation]`]="{ value }">
        <v-tooltip right color="primary" :disabled="value?.length <= 10">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-if="!isLinkedLocation(value) && value !== '' && value !== null"
              v-bind="attrs"
              v-on="on"
              >{{ ellipseText(value, 10) }}</span
            >
            <span
              v-if="isLinkedLocation(value) && value !== '' && value !== null"
              v-bind="attrs"
              v-on="on"
              :style="{ textDecoration: 'underline' }"
              @click="openLinkedLocation(value)"
              @click.stop
            >
              {{ ellipseText(value.replace("https://", ""), 10) }}
            </span>
          </template>
          <span>{{ value }}</span>
        </v-tooltip>
      </template>

      <!-- Report An Issue cell override -->
      <template v-slot:[`item.issues`]="{ item, value }">
        <v-btn
          small
          icon
          :color="
            value?.length
              ? 'secondary'
              : value?.every((el) => el.issueStatus === 'Resolved')
              ? 'accent'
              : '#494949de'
          "
          outlined
          elevation="1"
          :loading="item.issuesLoading"
          @click="handleBugReportModal(item)"
          @click.stop
          disabled
        >
          <v-icon v-if="value?.every((el) => el.issueStatus === 'Resolved')"
            >mdi-bug-check</v-icon
          >
          <v-icon v-else>mdi-bug</v-icon>
        </v-btn>
      </template>

      <!-- Request Date cell override -->
      <template v-slot:[`item.schedule[requestDate]`]="{ value }">
        <span>{{ formatDateForTableRow(value) }}</span>
      </template>

      <!-- Detail cell override -->
      <template v-slot:[`item.assignmentDetails[assignmentDetail]`]="{ value }">
        <v-tooltip right color="primary" :disabled="value?.length <= 30">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ ellipseText(value, 30) }}</span>
          </template>
          <span>{{ value }}</span>
        </v-tooltip>
      </template>

      <!-- Earnings cell override -->
      <template v-slot:[`item.pricing`]="{ item, value }">
        {{
          `$${
            isActiveUserEmail(item.primary?.email)
              ? value.primaryTotal.toFixed(2)
              : isActiveUserEmail(item.secondary?.email)
              ? value.secondaryTotal.toFixed(2)
              : "00.00"
          }`
        }}
      </template>

      <!-- GEO cell override -->
      <template v-slot:[`item.assignmentDetails[geo]`]="{ value }">
        <v-chip class="geo-chip" v-if="value">{{ value }}</v-chip>
      </template>

      <!-- Status cell override -->
      <template
        v-slot:[`item.assignmentDetails[assignmentStatus]`]="{ item, value }"
      >
        <ADStatusSelector
          :item="item"
          :value="value"
          :options="statuses"
          :optionsLoading="statusesLoading"
          :roleMode="setRoleMode(item)"
          :writerStatuses="writerStatuses"
          :editorStatuses="editorStatuses"
          :reviewerStatuses="reviewerStatuses"
          @refresh-table-data="updateTableDataStatus"
        />
      </template>

      <!-- accepted cell override -->
      <template v-slot:[`item.assignmentDetails`]="{ item }">
        <ADAcceptedSelector
          v-if="item.primary?.email === user.user.email"
          :item="item"
          :value="item.assignmentDetails.primaryAccepted"
          @refresh-table-expansion="updateTableDataAccepted"
        />
        <ADAcceptedSelector
          v-else-if="item.secondary?.email === user.user.email"
          :item="item"
          :value="item.assignmentDetails.secondaryAccepted"
          @refresh-table-expansion="updateTableDataAccepted"
        />
      </template>

      <!-- Inquiry cell override -->
      <template v-slot:[`item.inquiry`]="{ item }">
        <v-btn
          fab
          x-small
          outlined
          elevation="1"
          color="accent"
          :disabled="item.project?.name !== 'iTunes FC'"
          @click="openSlackForm(item)"
          @click.stop
          ><v-icon>mdi-slack</v-icon></v-btn
        >
      </template>

      <!-- Earnings cell override -->
      <template v-slot:[`item.TotalInternal`]="{ value }">
        <span>{{ value ? `$${value.toFixed(2)}` : "-" }}</span>
      </template>

      <!-- Expanded panel -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <ADTableExpansion :assignmentInfo="item" />
        </td>
      </template>
    </v-data-table>
    <!-- issue report dialog -->
    <v-dialog
      max-width="1200"
      v-model="bugReportModal"
      @click:outside="bugReportModal = false"
      @keydown.esc="bugReportModal = false"
    >
      <v-card>
        <v-card-title class="flex-column py-4">
          <span class="text-h5">Report Shakespeare Issue</span>
          <span class="text-subtitle-1">{{
            currentItem.ProjectName +
            " / " +
            currentItem.RequestDate +
            " / " +
            currentItem.AssignmentDetail +
            " / " +
            currentItem.GEO
          }}</span>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-center px-2">Issue Type</th>
                <th class="text-center px-2">Date Reported</th>
                <th class="text-center px-2">Last Updated</th>
                <th class="text-center px-2">Last Updated By</th>
                <th class="text-center px-2">Issue Status</th>
                <th class="text-center px-2">Remove</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in bugReports" :key="i">
                <td class="px-2">
                  <v-autocomplete
                    class="issue-field"
                    dense
                    solo-inverted
                    hide-details
                    :readonly="!isAdminMode && !!item['RecordID#']"
                    :value="item.IssueType"
                    :items="isAdminMode ? issueTypes : issueTypesByRole"
                    @change="handleBugDropdown(i, 'IssueType', $event)"
                  ></v-autocomplete>
                </td>
                <td class="px-2">
                  <v-text-field
                    class="reported-field"
                    dense
                    filled
                    solo-inverted
                    hide-details
                    readonly
                    :value="formatDateForTableRow(item.DateReported)"
                  ></v-text-field>
                </td>
                <td class="px-2">
                  <v-text-field
                    class="updated-field"
                    dense
                    filled
                    solo-inverted
                    hide-details
                    readonly
                    :value="formatDateForTableRow(item.LastUpdated, true)"
                  ></v-text-field>
                </td>
                <td class="px-2">
                  <v-text-field
                    class="updated-by-field"
                    dense
                    filled
                    solo-inverted
                    hide-details
                    readonly
                    :value="item.LastUpdatedBy"
                  ></v-text-field>
                </td>
                <td class="px-2">
                  <v-autocomplete
                    class="status-field"
                    dense
                    solo-inverted
                    hide-details
                    :readonly="!isAdminMode"
                    :value="item.IssueStatus"
                    :items="issueStatuses"
                    @change="handleBugDropdown(i, 'IssueStatus', $event)"
                  ></v-autocomplete>
                </td>
                <td class="text-center">
                  <v-icon
                    :disabled="!!item['RecordID#']"
                    @click="handleRemoveBugReport(i)"
                    >mdi-minus</v-icon
                  >
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <div class="pt-3 pl-4 d-flex align-center">
            <v-btn x-small outlined color="accent" @click="handleNewBugReport">
              <v-icon small>mdi-plus</v-icon>
              add issue</v-btn
            >
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-6 py-4">
          <v-btn
            width="20%"
            color="secondary"
            @click="handleBugReportUpdate"
            :loading="bugReportUpdate"
          >
            Save
          </v-btn>
        </v-card-actions>
        <v-alert
          v-if="bugReportError"
          type="error"
          class="alert mb-0"
          dismissible
        >
          Error updating bug report. Please try again.
        </v-alert>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="1200"
      v-model="slackFormModal"
      @click:outside="slackFormModal = false"
      @keydown.esc="slackFormModal = false"
    >
      <SlackInquiryForm
        v-if="user.user.userRole === 'MANAGER' || user.user.userRole === 'ADMIN'"
        :inquiryItem="inquiryAssignment"
        :mode="'assignmentManagers'"
        :bulkInquiry="bulkInquiry"
        @close-slack-form="slackFormModal = false"
      />
      <SlackInquiryForm
        v-else
        :inquiryItem="inquiryAssignment"
        :mode="'assignment'"
        :bulkInquiry="bulkInquiry"
        @close-slack-form="slackFormModal = false"
      />
    </v-dialog>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// internal
import {
  appIDs,
  getFieldInfosFromDB,
  getBugReports,
  updateBugReports,
} from "@/utils/quickbaseUtils";
import { customBreakpoint, timeUnits } from "@/utils/mixins";
import { publishMessage } from "@/utils/slackUtils";
// components
import ADTableExpansion from "./ADTableExpansion.vue";
import ADStatusSelector from "./ADStatusSelector.vue";
import ADAcceptedSelector from "./ADAcceptedSelector.vue";
import SlackInquiryForm from "../ui/SlackInquiryForm.vue";
import { filter } from "lodash";

export default {
  name: "ADTable",
  components: {
    ADTableExpansion,
    ADStatusSelector,
    ADAcceptedSelector,
    SlackInquiryForm,
  },
  props: {
    tableData: {
      type: Array,
      required: true,
    },
    serverItemsLength: {
      type: Number,
      required: true,
    },
    filterShakespeare: {
      type: Number,
      required: true,
    },
    isAdminMode: {
      type: Boolean,
      required: true,
    },
    isTableUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    statuses: {
      type: Array,
      required: true,
    },
    statusesLoading: {
      type: Boolean,
      required: true,
    },
    showEarnings: {
      type: Number,
      required: true,
    },
    filterProject: {
      type: Object,
      required: false,
    },
    tabledataAmount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [this.isAdminMode ? "schedule[deliveryDate]" : "dueGroup"],
        groupDesc: [true],
        mustSort: false,
        multiSort: false,
      },
      checkedAll: false,
      selectAllMenu: false,
      adminHeaders: [
        {
          text: "Assignment Title",
          value: "assignmentDetails[assignmentTitle]",
        },
        {
          text: "Assignment Detail",
          value: "assignmentDetails[assignmentDetail]",
        },
        { text: "GEO", value: "assignmentDetails[geo]" },
        { text: "File Location", value: "assignmentDetails[fileLocation]" },
        { text: "Word Count", value: "assignmentDetails[wordCount]" },
        { text: "Primary", value: "primary", sortable: false },
        { text: "Secondary", value: "secondary", sortable: false },
        { text: "QC", value: "qc", sortable: false },
        {
          text: "Assignment Status",
          value: "assignmentDetails[assignmentStatus]",
        },
        { text: "Inquiry", value: "inquiry", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
      userHeaders: [
        {
          text: "Assignment Title",
          value: "assignmentDetails[assignmentTitle]",
        },
        {
          text: "Assignment Detail",
          value: "assignmentDetails[assignmentDetail]",
        },
        { text: "Word Count", value: "assignmentDetails[wordCount]" },
        { text: "File Location", value: "assignmentDetails[fileLocation]" },
        { text: "Primary", value: "primary", sortable: false },
        { text: "Secondary", value: "secondary", sortable: false },
        { text: "QC", value: "qc", sortable: false },
        { text: "Due Time", value: "dueTime" },
        {
          text: "Assignment Status",
          value: "assignmentDetails[assignmentStatus]",
        },
        { text: "Inquiry", value: "inquiry", sortable: false },
        {
          text: "Accepted",
          value: "assignmentDetails",
          sortable: false,
        },
        { text: "", value: "data-table-expand" },
      ],
      selectedRows: [],
      bugReportModal: false,
      issueTypes: [],
      issueStatuses: [],
      bugReports: [],
      currentItem: {},
      bugReportUpdate: false,
      bugReportError: false,
      dueControl: 2,
      writerStatuses: ["Assigned", "Rejected", "Primary Complete"],
      editorStatuses: ["Primary Complete", "Secondary Complete"],
      reviewerStatuses: [
        "Assigned",
        "Rejected",
        "Primary Complete",
        "Secondary Complete",
        "QC Complete",
      ],
      bulkMode: "",
      slackFormModal: false,
      inquiryAssignment: {},
      bulkInquiry: false,
      showSelectedRowsAmount: 0,
    };
  },
  mixins: [customBreakpoint, timeUnits],
  computed: {
    ...mapGetters("spreadsheetUpload", ["selectedDashboardRows"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("assignment", ["bulkQueryEdit"]),
    dueText() {
      if (this.isAdminMode) {
        return "Delivery Date: ";
      } else {
        return "Your Due Date: ";
      }
    },
    issueTypesByRole() {
      const specialTypes = [
        "Task disappeared",
        "Cancelled (billed)",
        "Cancelled (not billed)",
      ];
      const retVal = this.issueTypes.filter((el) => !specialTypes.includes(el));
      if (this.isActiveUserEmail(this.currentItem.primary.email))
        retVal.push("Task disappeared");
      return retVal.sort();
    },
  },
  watch: {
    showSelectedRowsAmount(newValue) {
      this.$emit("update-table-amount", newValue);
    },
    checkedAll(newValue) {
      if (!newValue) {
        this.showSelectedRowsAmount = 0;
        this.setBulkQueryEdit(false);
        this.tableData.forEach(item => {
          item.checked = false;
          this.handleSelectedRows(false, item);
        });
      };
    },
    selectedRows(newValues) {
      this.setSelectedDashboardRows(newValues);
    },
    selectedDashboardRows(newValues) {
      this.selectedRows = newValues;
    },
    filterShakespeare(newValue) {
      this.adminHeaders.splice(
        3,
        1,
        newValue
          ? { text: "Shakespeare Issue", value: "issues", sortable: false }
          : this.showEarnings
          ? { text: "Total Internal", value: "pricing", sortable: false }
          : { text: "File Location", value: "assignmentDetails[fileLocation]" }
      );
      this.userHeaders.splice(
        3,
        1,
        newValue
          ? { text: "Shakespeare Issue", value: "issues", sortable: false }
          : this.showEarnings
          ? { text: "Total Internal", value: "pricing", sortable: false }
          : { text: "File Location", value: "assignmentDetails[fileLocation]" }
      );
    },
    showEarnings(newValue) {
      this.options = {
        ...this.options,
        page: 1,
        itemsPerPage: newValue ? -1 : 50,
      };
      this.adminHeaders.splice(
        3,
        1,
        newValue
          ? { text: "Total Internal", value: "pricing", sortable: false }
          : this.filterShakespeare
          ? { text: "Shakespeare Issue", value: "issues", sortable: false }
          : {
              text: "File Location",
              value: "assignmentDetails[fileLocation]",
            }
      );
      this.userHeaders.splice(
        3,
        1,
        newValue
          ? { text: "Total Internal", value: "pricing", sortable: false }
          : this.filterShakespeare
          ? { text: "Shakespeare Issue", value: "issues", sortable: false }
          : {
              text: "File Location",
              value: "assignmentDetails[fileLocation]",
            }
      );
    },
  },
  mounted() {
    this.handleTableHeaders();
    // this.getIssuesDropdowns();
  },
  methods: {
    ...mapActions("spreadsheetUpload", ["setSelectedDashboardRows"]),
    ...mapActions("assignment", [
      "setNewAssignmentDatas",
      "setNewAssignmentModalOpen",
      "setBulkQueryEdit",
    ]),
    handleTableOptions(updatedProp) {
      if (updatedProp === "sort-by" && this.options.sortBy[0] === undefined)
        this.options.sortBy = [];
      else if (
        updatedProp === "sort-desc" &&
        this.options.sortDesc[0] === undefined
      )
        this.options.sortDesc = [];
      else this.$emit("refresh-request", this.options);
    },
    formatDate(data) {
      return this.$moment(data).format("MMM DD, YYYY");
    },
    formatDateForTableRow(data, displayTime, timeOnly) {
      return data
        ? this.$moment(data).format(
            `${
              timeOnly
                ? "hh:mm A"
                : `MM-DD-YYYY ${displayTime ? "hh:mm A" : ""}`
            }`
          )
        : "None";
    },
    isActiveUserEmail(email) {
      return email === this.user.user.email;
    },
    isLinkedLocation(value) {
      const linkedLocations = ["Shakespeare", "http", "Smartling"];
      return linkedLocations.some((el) => value?.includes(el));
    },
    openLinkedLocation(value) {
      if (
        value.includes("Shakespeare") ||
        value.includes("https://wrkflow.app/")
      ) {
        // open link in new tab
        window.open("https://wrkflow.app/worklist", "_blank");
      } else if (value.includes("Smartling")) {
        window.open("https://www.smartling.com", "_blank");
      } else {
        window.open(value, "_blank");
      }
    },
    ellipseText(text, length) {
      if (text?.length > length) {
        return text.substring(0, length) + "...";
      } else {
        return text;
      }
    },
    getIssuesDropdowns() {
      getFieldInfosFromDB({
        fieldID: 14,
        tableID: appIDs.iTunesProjectManager.issueTracker,
      })
        .then((data) => (this.issueTypes = data))
        .catch((err) => console.error(err));
      getFieldInfosFromDB({
        fieldID: 8,
        tableID: appIDs.iTunesProjectManager.issueTracker,
      })
        .then((data) => (this.issueStatuses = data))
        .catch((err) => console.error(err));
    },
    handleBugReportModal(item) {
      item.issuesLoading = true;
      this.currentItem = { ...item };
      getBugReports(item["RecordID#"])
        .then((data) => {
          this.bugReports = data.data;
          item.issuesLoading = false;
          this.bugReportModal = true;
        })
        .catch((err) => {
          console.error(err);
          item.issuesLoading = false;
        });
    },
    handleNewBugReport() {
      this.bugReports.push({
        "RecordID#": null,
        IssueType: "",
        DateReported: new Date(),
        LastUpdated: new Date(),
        LastUpdatedBy: this.user.Name,
        IssueStatus: `Reported by ${this.isAdminMode ? "PM" : "Staff"}`,
      });
    },
    handleBugDropdown(i, key, value) {
      this.bugReports[i][key] = value;
      this.bugReports[i].DateReported = new Date();
      this.bugReports[i].LastUpdated = new Date();
      if (this.isAdminMode) {
        this.bugReports[i].LastUpdatedBy = this.user.Name;
      }
    },
    handleRemoveBugReport(i) {
      this.bugReports.splice(i, 1);
    },
    handleBugReportUpdate() {
      this.bugReportUpdate = true;
      updateBugReports({
        issues: this.bugReports,
        relatedAssgn: this.currentItem["RecordID#"],
      })
        .then(() => {
          this.tableData[this.currentItem.index].HasUnresolvedIssue =
            !this.bugReports.every((el) => el.IssueStatus === "Resolved");
          this.tableData[this.currentItem.index].AllIssuesAreResolved =
            this.bugReports.every((el) => el.IssueStatus === "Resolved");
          const newBugs = this.bugReports.filter(
            (bug) => bug["RecordID#"] === null
          );
          if (newBugs.length)
            newBugs.forEach((bug) => {
              publishMessage({
                user: this.user.SlackFCWorkspaceID
                  ? `<@${this.user.SlackFCWorkspaceID}>`
                  : `*${this.user.Name}*`,
                mode: "shakespeare-bug",
                data: {
                  shkBugType: bug.IssueType,
                },
                item: this.currentItem,
              });
            });
          this.bugReportUpdate = this.bugReportModal = false;
        })
        .catch((err) => {
          this.bugReportUpdate = false;
          this.bugReportError = true;
          console.error(err);
        });
    },
    updateTableDataAccepted(index, value, role) {
      this.tableData[index].assignmentDetails[`${role}Accepted`] = value;
    },
    handleTableHeaders() {
      if (this.$route.query.hasOwnProperty("Shakespeare")) {
        this.adminHeaders.splice(3, 1, {
          text: "Shakespeare Issue",
          value: "issues",
        });
        this.userHeaders.splice(3, 1, {
          text: "Shakespeare Issue",
          value: "issues",
        });
      }
    },
    handleDueAlert(item, flow) {
      if (this.isActiveUserEmail(item?.[flow]?.email)) {
        const statuses = [],
          assgnDue = this.$moment(
            item.schedule[`${flow}DueDate`]?.replace(/-/g, "/") +
              " " +
              item.schedule[`${flow}DueTime`]
          ).format(),
          now = this.$moment(
            new Date().toLocaleString("en-US", {
              timeZone: "America/New_York",
            })
          ).format(),
          dueMeter = this.$moment(assgnDue).diff(
            this.$moment(now),
            "hour",
            true
          );
        if (flow === "primary") statuses.push("Assigned");
        if (flow === "secondary") statuses.push("Primary Complete");
        if (flow === "qc") statuses.push("Secondary Complete");
        if (
          ((dueMeter <= this.dueControl && dueMeter > 0) || dueMeter == 0) &&
          statuses.includes(item.assignmentDetails.assignmentStatus)
        ) {
          return "upcoming-due";
        } else if (
          dueMeter < 0 &&
          statuses.includes(item.assignmentDetails.assignmentStatus)
        ) {
          return "overdue";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    handleDuesStats(group) {
      const groupItems = this.tableData.filter(
          (el) => this.$moment(el.groupDate).format("YYYY-MM-DD") === group
        ),
        flows = ["primary", "secondary", "qc"],
        overdueItems = [],
        upcomingDueItems = [];
      groupItems.forEach((el) => {
        flows.forEach((flow) => {
          if (this.handleDueAlert(el, flow) === "overdue") {
            overdueItems.push(el);
          } else if (this.handleDueAlert(el, flow) === "upcoming-due") {
            upcomingDueItems.push(el);
          }
        });
      });
      return `${
        (overdueItems.length
          ? "Overdue: " + overdueItems.length
          : "") +
        (overdueItems.length && upcomingDueItems.length ? " | " : "") +
        (upcomingDueItems.length
          ? `Due soon: ` +
            upcomingDueItems.length
          : "")
      }`;
    },
    updateTableDataStatus(index, value) {
      this.tableData[index].assignmentDetails.assignmentStatus = value;
    },
    setRoleMode(item) {
      if (this.isActiveUserEmail(item.primary?.email)) {
        return "writer";
      } else if (this.isActiveUserEmail(item?.secondary?.email)) {
        return "editor";
      } else if (this.isActiveUserEmail(item?.qc?.email)) {
        return "reviewer";
      } else {
        return "";
      }
    },
    handleSelectedRows(selected, item) {
      const handleUpdate = () =>
        selected
          ? this.selectedRows.push(item)
          : this.selectedRows.splice(this.selectedRows.indexOf(item), 1);
      handleUpdate();
      if (!this.isAdminMode) {
        if (selected) this.bulkMode = this.setRoleMode(item);
        if (!this.selectedDashboardRows.length) this.bulkMode = "";
        this.$emit(
          "update-allowed-statuses",
          this.bulkMode
            ? this.bulkMode === "reviewer"
              ? this[`${this.bulkMode}Statuses`]
              : this[`${this.bulkMode}Statuses`].slice(-1)
            : []
        );
      }
    },
    handleSelectAll(data) {
      data.items.forEach((item) => {
        this.tableData[item.index].checked = data.value;
        this.handleSelectedRows(data.value, item);
      });
    },
    selectAll() {
      this.showSelectedRowsAmount = this.tableData.length;
      this.tableData.forEach(item => {
        if (!this.checkedAll) {
          item.checked = false;
          this.handleSelectedRows(false, item);
        } else {
          item.checked = true;
          this.handleSelectedRows(true, item);
        }
      });
    },
    selectAllRowsOnTheScreen() {
      this.checkedAll = true;
      this.showSelectedRowsAmount = this.tableData.length;
      this.tableData.forEach(item => {
        item.checked = true;
        this.handleSelectedRows(true, item);
      });
      this.setBulkQueryEdit(false);
    },
    selectAllDataRecords() {
      this.checkedAll = true;
      this.showSelectedRowsAmount = this.tabledataAmount;
      this.tableData.forEach(item => {
          item.checked = true;
          this.handleSelectedRows(true, item);
      });
      this.setBulkQueryEdit(true);
    },
    allowedToUpdateStatuses(item) {
      const allowedStatuses = this[`${this.setRoleMode(item)}Statuses`].filter(
        (el) => el != this[`${this.setRoleMode(item)}Statuses`].slice(-1)
      );
      return this.setRoleMode(item) !== "reviewer"
        ? allowedStatuses
        : this[`${this.setRoleMode(item)}Statuses`];
    },
    openSlackForm(item) {
      this.inquiryAssignment = { ...item };
      this.bulkInquiry = false;
      this.slackFormModal = true;
    },
    callBulkInquiry() {
      this.inquiryAssignment = {};
      this.bulkInquiry = this.slackFormModal = true;
    },
    handleEditForm(item) {
      if (this.isAdminMode) {
        this.setNewAssignmentDatas([
          {
            ...item,
          },
        ]);
        this.setNewAssignmentModalOpen(true);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.group-header-row {
  background-color: #9af9ef70 !important;
  color: #003a35 !important;
}

.assigned-to-user {
  .text-container {
    width: fit-content;
    font-weight: 500;
    border: 2px solid #ee79753f;
    color: #540000;
    padding: 2px 5px;
    margin: 2px 0px;
    border-radius: 10px;
    line-height: 1.5;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  }
}

::v-deep {
  tbody > tr {
    cursor: pointer;
  }
  .v-data-table.laptop .v-data-table__wrapper table {
    & tbody > tr:not(.v-data-table__expanded__content) > td {
      padding: 0 8px;
    }
    & .v-data-table-header tr > th {
      padding: 0 8px;
    }
  }
  .v-data-table.laptopSmaller .v-data-table__wrapper table {
    & tbody > tr:not(.v-data-table__expanded__content) > td {
      padding: 0 4px;
    }
    & .v-data-table-header tr > th {
      padding: 0 4px;
    }
  }
  .v-badge--icon .v-badge__badge {
    min-width: 23px;
    height: 23px;
    display: flex;
    border-radius: 50%;
    padding: 0;
    & .v-icon {
      font-size: 17px;
      height: auto;
      margin: auto;
    }
  }
  .v-data-table.earnings-mode {
    .v-data-footer__select {
      visibility: hidden;
    }
    [class^="v-data-footer__icons-"] {
      visibility: hidden;
    }
  }
}

.v-input--selection-controls {
  padding-left: 8px;
}

.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 0;
}

.table-updating {
  pointer-events: none;
  opacity: 0.5;
}

.issue-field {
  width: 270px;
}

.reported-field {
  width: 110px;
}

.updated-field {
  width: 180px;
}

.updated-by-field,
.status-field {
  width: 220px;
}

.d-inline {
  vertical-align: middle;
}

.hover-effect {
  cursor: pointer;
}

.geo-chip {
  white-space: nowrap;
  overflow: hidden;
}
</style>
