<template>
  <div>
    <v-data-table
      :loading="isTableUpdating"
      :headers="headers"
      :items="tableData"
      item-key="id"
      :header-props="{ sortIcon: null }"
      show-expand
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [10, 50, 100],
      }"
      :options.sync="options"
      :server-items-length="serverItemsLength"
      :class="{ 'table-updating': isTableUpdating, laptop, laptopSmaller }"
      @click:row="$emit('call-edit-form', $event)"
      @update:page="handleTableOptions"
      @update:sort-by="handleTableOptions('sort-by')"
      @update:sort-desc="handleTableOptions('sort-desc')"
      @update:items-per-page="handleTableOptions"
    >
      <!-- Request Date cell override -->
      <template v-slot:[`item.requestDate`]="{ value }">
        <span>{{ formatDateTime(value) }}</span>
      </template>

      <!-- Request Time cell override -->
      <template v-slot:[`item.requestTime`]="{ value }">
        <span>{{ formatDateTime(value, false, true) }}</span>
      </template>

      <!-- Client Requester cell override -->
      <template v-slot:[`item.clientRequester`]="{ value }">
        <span>{{
          value ? `${value.user.firstName} ${value.user.lastName}` : ""
        }}</span>
      </template>

      <!-- Delivery Date cell override -->
      <template v-slot:[`item.deliveryDate`]="{ value }">
        <span>{{ formatDateTime(value) }}</span>
      </template>

      <!-- Delivery Time cell override -->
      <template v-slot:[`item.deliveryTime`]="{ value }">
        <span>{{ formatDateTime(value, false, true) }}</span>
      </template>

      <!-- Item cell override -->
      <template v-slot:[`item.task`]="{ value }">
        <v-tooltip right color="primary" :disabled="value.length <= 40">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ ellipseText(value, 40) }}</span>
          </template>
          <span>{{ value }}</span>
        </v-tooltip>
      </template>

      <!-- Status cell override -->
      <template v-slot:[`item.taskStatus`]="{ item, value }">
        <PMDashboardDropdownSelector
          :item="item"
          :value="value"
          :statusMode="true"
          :dropdownOptions="taskStatuses"
          :dropdownOptionsLoading="dropdownOptionsLoading"
          @refresh-table-data="updateTableData"
          v-on="$listeners"
        />
      </template>

      <!-- DRI cell override -->
      <template v-slot:[`item.dri`]="{ item, value }">
        <PMDashboardDropdownSelector
          :item="item"
          :value="value"
          :driMode="true"
          :dropdownOptions="employees"
          :dropdownOptionsLoading="dropdownOptionsLoading"
          @refresh-table-data="updateTableData"
          v-on="$listeners"
        />
      </template>

      <!-- Note cell override -->
      <template v-slot:[`item.notesToClient`]="{ value }">
        <v-badge overlap color="secondary" :content="value" :value="value">
          <v-icon :disabled="!value" color="accent" large
            >mdi-chat-outline</v-icon
          >
        </v-badge>
      </template>

      <!-- Inquiry cell override -->
      <template v-slot:[`item.inquiry`]="{ item }">
        <v-btn
          fab
          x-small
          outlined
          elevation="1"
          color="accent"
          @click.native.stop.prevent="openSlackForm(item)"
          ><v-icon>mdi-slack</v-icon></v-btn
        >
      </template>

      <!-- QC cell override -->
      <template v-slot:[`item.qc`]="{ item, value }">
        <v-tooltip :disabled="!item.validation" bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <PMDashboardValidationSelector
                :item="item"
                :value="value"
                :leadPMEmail="leadPM?.user?.email"
                @refresh-table-data="updateTableData"
                v-on="$listeners"
              />
            </span>
          </template>
          {{
            item.validation
              ? `${formatDateTime(item.validation.split(" by ")[0], true)} by ${
                  item.validation.split(" by ")[1]
                }`
              : ""
          }}
        </v-tooltip>
      </template>

      <!-- Expanded panel -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="px-0">
          <PMDashboardTableExpansion
            :expansionTableData="item.assignmentResponses"
            :expansionTableTitle="item.cueTaskId"
            @open-slack-form="openSlackForm(item)"
          />
        </td>
      </template>
    </v-data-table>
    <v-dialog
      max-width="1200"
      v-model="slackFormModal"
      @click:outside="slackFormModal = false"
      @keydown.esc="slackFormModal = false"
    >
      <SlackInquiryForm
        :inquiryItem="inquiryTask"
        :mode="'task'"
        :leadPMSlack="leadPM?.adminDetails?.slackIdFc"
        @close-slack-form="slackFormModal = false"
      />
    </v-dialog>
  </div>
</template>

<script>
// internal
import { customBreakpoint } from "@/utils/mixins";
// components
import PMDashboardDropdownSelector from "./PMDashboardDropdownSelector.vue";
import PMDashboardValidationSelector from "./PMDashboardValidationSelector.vue";
import PMDashboardTableExpansion from "./PMDashboardTableExpansion.vue";
import SlackInquiryForm from "../ui/SlackInquiryForm.vue";

export default {
  name: "PMDashboardTable",
  components: {
    PMDashboardDropdownSelector,
    PMDashboardValidationSelector,
    PMDashboardTableExpansion,
    SlackInquiryForm,
  },
  props: {
    tableData: {
      type: Array,
      required: false,
    },
    serverItemsLength: {
      type: Number,
      required: true,
    },
    isTableUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    taskStatuses: {
      type: Array,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
    dropdownOptionsLoading: {
      type: Boolean,
      required: true,
    },
    leadPM: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      headers: [
        { text: "Task", value: "task" },
        { text: "Cue Task ID", value: "cueTaskId" },
        { text: "Request Date", value: "requestDate" },
        { text: "Request Time", value: "requestTime" },
        { text: "Client Requester", value: "clientRequester", sortable: false },
        { text: "DRI", value: "dri", sortable: false },
        { text: "Task Status", value: "taskStatus" },
        { text: "Delivery Date", value: "deliveryDate" },
        { text: "Delivery Time", value: "deliveryTime" },
        { text: "Notes To Client", value: "notesToClient", sortable: false },
        { text: "Inquiry", value: "inquiry", sortable: false },
        { text: "QA Check", value: "qc" },
        { text: "", value: "data-table-expand" },
      ],
      expansionTableData: null,
      slackFormModal: false,
      inquiryTask: {},
    };
  },
  mixins: [customBreakpoint],
  methods: {
    handleTableOptions(updatedProp) {
      if (updatedProp === "sort-by" && this.options.sortBy[0] === undefined)
        this.options.sortBy = [...[]];
      else if (
        updatedProp === "sort-desc" &&
        this.options.sortDesc[0] === undefined
      )
        this.options.sortDesc = [...[true]];
      else this.$emit("refresh-request", this.options);
    },
    formatDateTime(date, withtTime, timeOnly) {
      return date
        ? this.$moment(timeOnly ? `2023-12-05 ${date}` : date, timeOnly ? "YYYY-MM-DD hh:mm A" : "").format(
            `${
              timeOnly ? "hh:mm A" : `MM-DD-YYYY${withtTime ? " hh:mm A" : ""}`
            }`
          )
        : "";
    },
    ellipseText(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + "...";
      } else {
        return text;
      }
    },
    updateTableData(index, key, value, qcValidation, archiving) {
      this.tableData[index][key] = key === "dri" ? { ...value } : value;
      if (key === "qc") {
        this.tableData[index].validation = qcValidation;
        if (archiving) this.tableData[index].taskStatus = "Archived";
      }
    },
    openSlackForm(item) {
      this.inquiryTask = { ...item };
      this.slackFormModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table-header {
    background-color: #9af9ef70;
    & > tr > th {
      color: #003a35 !important;
      padding: 0 12px !important;
      &:hover {
        color: rgba(0, 0, 0, 0.6) !important;
      }
    }
  }
  tbody > tr {
    cursor: pointer;
    & > td {
      padding: 0 12px !important;
      &:nth-child(2) {
        pointer-events: none;
      }
    }
  }
  .v-data-table.laptop .v-data-table__wrapper table {
    & tbody > tr:not(.v-data-table__expanded__content) > td {
      padding: 0 6px !important;
    }
    & .v-data-table-header tr > th {
      padding: 0 6px !important;
    }
  }
  .v-data-table.laptopSmaller .v-data-table__wrapper table {
    & tbody > tr:not(.v-data-table__expanded__content) > td {
      padding: 0 4px !important;
    }
    & .v-data-table-header tr > th {
      padding: 0 4px !important;
    }
  }
}
.v-application .text-start {
  text-align: center !important;
}
.table-updating {
  pointer-events: none;
  opacity: 0.5;
}
</style>
