<template>
  <div class="info-form">
    <div
      class="info-form__header d-flex align-center justify-space-between pb-10"
    >
      <h2>Assign and Import</h2>
      <!-- prepopulation mode -->
      <div
        v-if="shakespeare || backstageCopywriting"
        class="d-flex align-center"
      >
        <span class="overline mr-2">Prepopulate for:</span>
        <v-btn-toggle
          class="elevation-2"
          color="accent"
          v-model="prepopulationMode"
          @change="handlePrepopulation($event)"
        >
          <v-btn>Music Playlists</v-btn>
          <v-btn>FC{{ backstageCopywriting ? "" : " Music" }}</v-btn>
          <v-btn v-if="!backstageCopywriting">FC Video</v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <StaffTable
      :staffDatas="staffDatas"
      :tableRows="tableRows"
      :workType1References="workType1References"
      :workType2References="workType2References"
      :workType3References="workType3References"
      :workType4References="workType4References"
      :workType5References="workType5References"
      :workType6References="workType6References"
    />
    <div class="info-form__body">
      <v-form ref="infoForm" class="info-form__main-form mb-10">
        <h3 v-if="shakespeare" class="mt-2">General Info</h3>
        <!-- related project -->
        <v-row
          v-if="shakespeare || backstageCopywriting || cueLoc || cueCopywriting"
          class="row mt-3"
        >
          <v-autocomplete
            filled
            class="pr-2"
            label="Related Project"
            :items="projects"
            :loading="projectsLoading"
            v-model="formValues.relatedProject"
            item-text="name"
            return-object
            :rules="[rules.requiredObject]"
            @change="updateRelatedProjectSmartFields($event)"
          ></v-autocomplete>
          <!-- related po -->
          <v-autocomplete
            filled
            class="pl-2"
            label="Related Purchase Order"
            :items="relatedPurchaseOrders"
            :loading="projectsLoading"
            v-model="formValues.po"
            item-text="purchaseOrderNumber"
            return-object
            :rules="cueLoc || cueCopywriting ? [] : [rules.requiredObject]"
            @change="setFormValue({ key: 'po', value: $event })"
          ></v-autocomplete>
        </v-row>
        <!-- email subject line -->
        <v-row v-if="shakespeare">
          <v-text-field
            filled
            label="Email Subject Line"
            disabled
            v-model="formValues.emailSubjectLine"
            :rules="[rules.required]"
            @change="setFormValue({ key: 'emailSubjectLine', value: $event })"
          ></v-text-field>
        </v-row>
        <!-- client requester -->
        <v-row v-if="shakespeare || shakespeareLoc" class="row">
          <v-autocomplete
            filled
            class="pr-2"
            label="Related Client Requester"
            :items="clientRequestersSHK"
            v-model="formValues.clientRequester"
            :rules="[rules.required]"
            @change="setFormValue({ key: 'clientRequester', value: $event })"
          ></v-autocomplete>
          <!-- media type -->
          <v-autocomplete
            v-if="shakespeare"
            filled
            class="pl-2"
            label="Media Type"
            :items="mediaTypesSHK"
            v-model="formValues.mediaType"
            :rules="[rules.required]"
            @change="setFormValue({ key: 'mediaType', value: $event })"
          ></v-autocomplete>
        </v-row>
        <!-- assignment title -->
        <v-row v-if="!cueLoc">
          <v-combobox
            :class="{ 'mb-4 mt-3': !shakespeare || !cueCopywriting }"
            filled
            label="Assignment Title"
            :items="cueCopywriting ? [] : assignmentTitles"
            :append-icon="cueCopywriting ? '' : '$dropdown'"
            v-model="formValues.assignmentTitle"
            :rules="[rules.required]"
            @change="setFormValue({ key: 'assignmentTitle', value: $event })"
          ></v-combobox>
        </v-row>
        <!-- note -->
        <v-row class="row">
          <v-textarea
            v-model="formValues.note"
            label="Note"
            rows="2"
            clearable
            filled
            @change="setFormValue({ key: 'note', value: $event })"
          />
        </v-row>
        <h3 v-if="shakespeare" class="mt-1 mb-6">Staff Info</h3>
        <!-- client's delivery date -->
        <v-row class="row">
          <v-menu
            v-if="shakespeare || shakespeareLoc"
            v-model="clientDeliveryDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                filled
                class="pr-2"
                v-model="formattedClientDeliveryDate"
                label="Delivery Date (Client)"
                readonly
                :error="conversionErrors"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="clientDeliveryDate"
              @change="handlePayPeriod($event)"
            ></v-date-picker>
          </v-menu>
          <!-- file location -->
          <v-combobox
            v-else
            filled
            class="pr-2"
            label="File Location"
            :items="fileLocations"
            :loading="fileLocationsLoading"
            v-model="formValues.fileLocation"
            @change="setFormValue({ key: 'fileLocation', value: $event })"
            :rules="cueCopywriting ? [] : [rules.required]"
          ></v-combobox>
          <!-- pay period -->
          <v-menu
            v-model="payPeriodMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="pl-2"
                :class="{ 'mb-4': !shakespeare }"
                v-model="formattedPayPeriod"
                label="Pay Period"
                readonly
                v-bind="attrs"
                v-on="on"
                filled
                :rules="[rules.required]"
              ></v-text-field>
            </template>
            <v-date-picker
              type="month"
              v-model="formValues.payPeriod"
              @input="payPeriodMenu = false"
              @change="setFormValue({ key: 'payPeriod', value: $event })"
            ></v-date-picker>
          </v-menu>
        </v-row>
        <!-- iTunes Film Link Field -->
        <v-row class="row" v-if="formValues.relatedProject?.name === 'iTunes Film/TV Writeups'">
          <v-text-field
            filled
            label="Link"
            v-model="formValues.filmLink"
            @change="setFormValue({ key: 'filmLink', value: $event })"
          ></v-text-field>
        </v-row>
        <!-- delivery due conversion -->
        <v-row class="row" :class="{ 'my-4': !shakespeare }">
          <v-select
            filled
            class="pr-2"
            :items="hoursList"
            v-model="clientDeliveryDue"
            item-text="time"
            label="Delivery Due (Time of Day)"
            :error="conversionErrors"
          ></v-select>
          <v-autocomplete
            filled
            class="pl-2"
            label="Time Zone"
            :error="conversionErrors"
            v-model="timeZone"
            :items="utcTimeZones"
          ></v-autocomplete>
        </v-row>
        <v-row :class="{ 'my-4': !shakespeare }">
          <v-btn
            block
            height="56px"
            color="accent"
            outlined
            @click="convertDeliveryDue"
            >Convert Delivery Due to ET time zone</v-btn
          >
        </v-row>
        <v-row class="row my-4">
          <v-checkbox
            class="pr-2"
            v-model="formValues.override"
            label="Override"
            persistent-hint
            hint="Override Due Dates and Dues (Time of Day)"
            :disabled="!formValues.deliveryDate && !formValues.deliveryDue"
            @change="setFormValue({ key: 'override', value: $event })"
          ></v-checkbox>
          <v-checkbox
            class="pl-2"
            v-model="formValues.urgent"
            label="Urgent"
            persistent-hint
            hint="Convert Delivery data (ET) or enter it manually"
            readonly
            :color="formValues.urgent ? 'red accent-4' : ''"
          ></v-checkbox>
        </v-row>
        <!-- individual geo deadlines -->
        <v-row
          v-if="allGeos.length > 1 && !cueLoc && !cueCopywriting"
          id="indiv-geo"
          class="mb-2 align-center"
        >
          <h3 class="indiv-geo-title">Individual GEO deadlines:</h3>
          <div class="d-flex">
            <v-btn
              width="48px"
              min-width="48px"
              height="48px"
              class="elevation-2 px-0 mx-2"
              color="accent"
              @click="handleIndiviadualGeoDeadlines(false, -1)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              height="48px"
              class="indiv-geo-btn px-1 mr-2"
              :color="
                validateIndiviadualGeoDeadlines(index, Object.keys(dline)[0])
                  ? 'accent'
                  : 'secondary'
              "
              :outlined="
                validateIndiviadualGeoDeadlines(index, Object.keys(dline)[0])
              "
              v-for="(dline, index) in formValues.individualGeosDeadlines"
              :key="index"
              @click="handleIndiviadualGeoDeadlines(true, index)"
            >
              {{ Object.keys(dline)[0] }}
            </v-btn>
          </div>
        </v-row>
        <!-- writer due date -->
        <v-row class="row" :class="{ 'my-4': !shakespeare }">
          <v-menu
            v-model="writerMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="writingDueDateField"
                filled
                class="pr-2"
                v-model="formattedWritingDueDate"
                label="Primary Due Date (ET)"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[rules.required, rules.dateAvailabile('writing')]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formValues.writingDueDate"
              :max="formValues.override ? null : formValues.deliveryDate"
              @input="writerMenu = false"
              @change="handleDueData($event, 'writing')"
            ></v-date-picker>
          </v-menu>
          <!-- writer due time -->
          <v-select
            filled
            class="pl-2"
            v-model="formValues.writingDue"
            :items="writingDueHours"
            item-text="time"
            label="Primary Due Time (ET)"
            :rules="
              cueCopywriting
                ? [rules.timeAvailabile('writing')]
                : [rules.required, rules.timeAvailabile('writing')]
            "
            @change="handleDueData($event, 'writing', true)"
          >
          </v-select>
        </v-row>
        <!-- editor due date -->
        <v-row class="row" :class="{ 'my-4': !shakespeare }">
          <v-menu
            v-model="editorMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="editingDueDateField"
                filled
                clearable
                class="pr-2"
                v-model="formattedEditingDueDate"
                label="Secondary Due Date (ET)"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="handleDueData('', 'editing')"
                :rules="[rules.dateAvailabile('editing')]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formValues.editingDueDate"
              :max="formValues.override ? null : formValues.deliveryDate"
              @input="editorMenu = false"
              @change="handleDueData($event, 'editing')"
            ></v-date-picker>
          </v-menu>
          <!-- editor due time -->
          <v-select
            filled
            clearable
            class="pl-2"
            v-model="formValues.editingDue"
            :items="editingDueHours"
            item-text="time"
            label="Secondary Due Time (ET)"
            :rules="[rules.timeAvailabile('editing')]"
            @change="handleDueData($event, 'editing', true)"
            @click:clear="handleDueData('', 'editing', true)"
          >
          </v-select>
        </v-row>
        <!-- reviewer due date -->
        <v-row class="row" :class="{ 'my-4': !shakespeare }">
          <v-menu
            v-model="reviewerMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="reviewingDueDateField"
                filled
                clearable
                class="pr-2"
                v-model="formattedReviewingDueDate"
                label="QC Due Date (ET)"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="handleDueData('', 'reviewing')"
                :rules="[rules.dateAvailabile('reviewing')]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formValues.reviewingDueDate"
              :max="formValues.override ? null : formValues.deliveryDate"
              @input="reviewerMenu = false"
              @change="handleDueData($event, 'reviewing')"
            ></v-date-picker>
          </v-menu>
          <!-- reviewer due time -->
          <v-select
            filled
            clearable
            class="pl-2"
            v-model="formValues.reviewingDue"
            :items="reviewingDueHours"
            item-text="time"
            label="QC Due Time (ET)"
            :rules="[rules.timeAvailabile('reviewing')]"
            @change="handleDueData($event, 'reviewing', true)"
            @click:clear="handleDueData('', 'reviewing', true)"
          >
          </v-select>
        </v-row>
        <!-- delivery due date -->
        <v-row class="row">
          <v-menu
            v-model="deliveryMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="pr-2"
                v-model="formattedDeliveryDate"
                label="Delivery Due Date (ET)"
                readonly
                v-bind="attrs"
                v-on="on"
                filled
                :rules="[rules.required]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formValues.deliveryDate"
              @input="deliveryMenu = false"
              @change="setFormValue({ key: 'deliveryDate', value: $event })"
            ></v-date-picker>
          </v-menu>
          <v-select
            class="pl-2"
            v-model="formValues.deliveryDue"
            item-text="time"
            label="Delivery Due Time (ET)"
            :items="hoursList"
            filled
            :rules="cueLoc || cueCopywriting ? [] : [rules.required]"
            @change="setFormValue({ key: 'deliveryDue', value: $event })"
          ></v-select>
        </v-row>
      </v-form>
      <AssignmentVisualization
        
      />
    </div>

    <div
      class="info-form__main-form__staff-section__button-container"
      :class="{ 'justify-center': successAlert || failAlert || writersAlert }"
    >
      <v-progress-linear
        v-if="thinking"
        class="thinking-progress"
        indeterminate
      />

      <div
        v-if="
          !successAlert &&
          !failAlert &&
          !writersAlert &&
          !thinking &&
          !xContent &&
          !shakespeare &&
          !shakespeareLoc &&
          !cueLoc &&
          !cueCopywriting
        "
        class="left"
      >
        <v-btn
          :disabled="!formValues.overrideStaff && !overloadingValidated"
          class="import-button mr-3"
          color="accent"
          @click="handleSplitByPerson"
        >
          <template v-if="splitByPersonProcessing">
            <span>{{ splitByPersonSend + "/" + splitByPersonFilesAmount + " file(s) sent!"}}</span>
          </template>
          <template v-else>
            SPLIT BY PERSON
          </template>
        </v-btn>
        <v-btn
          :disabled="!formValues.overrideStaff && !overloadingValidated"
          class="import-button"
          color="accent"
          @click="handleSplitByGeo"
          >SPLIT BY GEO</v-btn
        >
      </div>

      <div
        v-if="!successAlert && !failAlert && !writersAlert && !thinking"
        class="right d-flex"
        :class="{
          'justify-end':
            xContent ||
            shakespeare ||
            shakespeareLoc ||
            cueLoc ||
            cueCopywriting,
        }"
        :style="{
          width:
            xContent ||
            shakespeare ||
            shakespeareLoc ||
            cueLoc ||
            cueCopywriting
              ? '100%'
              : 'fit-content',
        }"
      >
        <v-checkbox
          class="mt-0 mr-3"
          v-model="formValues.overrideStaff"
          label="Override Availability & Capacity"
        ></v-checkbox>
        <v-btn
          :disabled="!formValues.overrideStaff && !overloadingValidated"
          class="import-button mr-3"
          color="secondary"
          @click="handleAddToDB"
          >ADD TO DB</v-btn
        >
        <v-btn
          v-if="!cueLoc && !cueCopywriting"
          :disabled="!formValues.overrideStaff && !overloadingValidated"
          class="import-button"
          color="secondary"
          @click="handleDoGoogle"
          >ADD TO GOOGLE SHEET</v-btn
        >
      </div>

      <v-alert
        v-model="successAlert"
        class="success-alert"
        type="success"
        dismissible
        @input="resetCallers"
      >
        {{ alertMessage }}
        <v-btn
          v-if="googleCall"
          class="ml-2"
          small
          depressed
          light
          :href="spreadsheetPath"
          target="_blank"
          >Open Tool Import</v-btn
        >
        <v-btn
          v-if="dbCall"
          class="ml-2"
          small
          depressed
          light
          :href="exportedPath"
          target="_blank"
          >Check on Dashboard</v-btn
        >
      </v-alert>
      <v-alert
        v-model="failAlert"
        class="fail-alert"
        type="error"
        dismissible
        @input="resetCallers"
        >{{ alertMessage }}</v-alert
      >
      <v-alert
        v-model="writersAlert"
        class="writers-alert"
        type="warning"
        outlined
        color="accent"
        dismissible
        ><p>No primary was added to:</p>
        <p v-for="(item, index) in writersValidated" :key="index">
          <b>
            {{
              xContent && !cueLoc && !cueCopywriting
                ? item.geos
                : item.assignment + " — " + item.geos
            }}</b
          >
        </p></v-alert
      >
    </div>
    <v-dialog
      max-width="650"
      v-model="indiviadualGeoDeadlines"
      @click:outside="closeIndiviadualGeoDeadlines"
      @keydown.esc="closeIndiviadualGeoDeadlines"
    >
      <GEODeadlinesForm
        ref="geoDeadlinesForm"
        :editMode="indiviadualGeoDeadlinesEdit"
        :itemToEditIndex="indiviadualGeoDeadlinesItemIndex"
        :allGeos="allGeos"
        @close-geo-deadlines-form="closeIndiviadualGeoDeadlines"
      />
    </v-dialog>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// internal
import {
  getAllProjects,
  getEnum,
  getAssignments,
  getTasks,
  processTask,
  getEvents,
} from "@/utils/newDbUtils";
import {
  doFileSplit,
  doFileSplitByPerson,
} from "@/utils/spreadsheetUploadUtils";
import {
  addRowsToGoogleSheet,
  SPREADSHEET_ID,
} from "@/utils/googleSheetsUtils";
import { timeUnits } from "@/utils/mixins";
import { DOMAIN, ROUTES } from "@/utils/constants";
import { addAssignmentRowsToDB } from "@/utils/assignmentUtils";
// components
import StaffTable from "@/components/SpreadsheetUpload/StaffTable";
import AssignmentVisualization from "@/components/SpreadsheetUpload/AssignmentVisualization";
import GEODeadlinesForm from "@/components/SpreadsheetUpload/GEODeadlinesForm";

export default {
  name: "InfoForm",
  components: {
    StaffTable,
    AssignmentVisualization,
    GEODeadlinesForm,
  },
  data() {
    return {
      allGeos: [],
      writerMenu: false,
      writingDueDateAvailable: true,
      writingDueHoursAvailable: true,
      editorMenu: false,
      editingDueDateAvailable: true,
      editingDueHoursAvailable: true,
      reviewerMenu: false,
      reviewingDueDateAvailable: true,
      reviewingDueHoursAvailable: true,
      deliveryMenu: false,
      payPeriodMenu: false,
      staffDatas: null,
      successAlert: false,
      failAlert: false,
      writersAlert: false,
      dbCall: false,
      googleCall: false,
      thinking: false,
      splitByPersonProcessing: false,
      alertMessage: "",
      clientDeliveryDue: "",
      timeZone: "",
      conversionErrors: false,
      prepopulationMode: null,
      projectsLoading: false,
      projects: [],
      clientRequestersSHK: ["Shakespeare"],
      mediaTypesSHK: ["Music", "Video"],
      amAssignmentTitles: [
        "P1 Playlist Copy",
        "P3 Playlist Copy",
        "Classical Playlist Copy",
        "Playlist Title",
      ],
      fcAssignmentTitles: [
        "Album Review",
        "Arcade File",
        "Artist Bio",
        "Artist Interview Edit",
        "Artist Interview Prep",
        "Artist Interview Transcript",
        "Book Review",
        "Custom Tag",
        "Custom Title",
        "Discovery File",
        "Editorial Feature",
        "Guidelines",
        "Hero Blurb",
        "Hourly Localization Work",
        "Interview Note",
        "Marketing Copy",
        "MAS File",
        "Meeting",
        "Movie Description",
        "P1 Playlist Copy",
        "P3 Playlist Copy",
        "Storefront Description",
        "Today File",
        "Vision Pro"
      ],
      clientDeliveryDateMenu: false,
      clientDeliveryDate: "",
      workType1References: ["CWR_500-60-1100"],
      workType2References: ["CWR_1000-120-1500"],
      workType3References: ["CWR_1500-180-2800"],
      workType4References: ["CWR_2500-300-3800"],
      workType5References: ["CWR_5000-500-7000"],
      workType6References: ["CWR_300-100-500"],
      fileLocationsLoading: false,
      fileLocations: [],
      indiviadualGeoDeadlines: false,
      indiviadualGeoDeadlinesEdit: false,
      indiviadualGeoDeadlinesItemIndex: null,
      rules: {
        required: (value) => !!value || value === 0 || "Required",
        requiredObject: (value) =>
          (value ? !!Object.keys(value).length : !!value) || "Required",
        dateAvailabile: (operation) =>
          this[`${operation}DueDateAvailable`] ||
          "Selected date is after delivery date for the client.",
        timeAvailabile: (operation) =>
          this[`${operation}DueHoursAvailable`] ||
          "Selected time is after delivery due (time of day) for the client.",
      },
    };
  },
  watch: {
    tableRows(newData) {
      if (this.tableRows) {
        if (this.cueLoc || this.cueCopywriting)
          this.setFormValue({
            key: "urgent",
            value: this.$moment(this.formValues.deliveryDate).isSameOrBefore(
              this.$moment(this.tableRows[0].request_date).add(2, "days")
            ),
          });
        else
          this.setFormValue({
            key: "payPeriod",
            value:
              this.shakespeare || this.shakespeareLoc
                ? null
                : this.$moment(newData[0].due_date.replace(/\./g, "/")).format(
                    "YYYY-MM"
                  ),
          });
        this.allGeos = [];
        this.tableRows.forEach((el) => {
          this.shakespeare || this.cueCopywriting
            ? this.allGeos.push(el.geo)
            : el.geos.forEach((geo) =>
                this.allGeos.push(this.shakespeareLoc ? geo.geo : geo)
              );
        });
      }
      this.$refs.infoForm.resetValidation();
    },
    deliveryDate(newDate) {
      this.handleStaffDuesValidation();
      if (this.tableRows)
        this.setFormValue({
          key: "urgent",
          value: this.$moment(newDate).isSameOrBefore(
            this.$moment(this.tableRows[0].request_date).add(2, "days")
          ),
        });
    },
    relatedProject(newVal) {
      if (this.projects.length && typeof newVal !== "object")
        this.updateRelatedProjectSmartFields(newVal);
    },
    override() {
      this.handleStaffDuesValidation();
    },
    writingDueHours(newVal) {
      this.handleDueHoursValidation("writing", newVal);
    },
    editingDueHours(newVal) {
      this.handleDueHoursValidation("editing", newVal);
    },
    reviewingDueHours(newVal) {
      this.handleDueHoursValidation("reviewing", newVal);
    },
  },
  methods: {
    ...mapActions("spreadsheetUpload", [
      "setFormValue",
      "updateCapacityData",
      "setStaffLoading",
      "setStaffAssignments",
      "setPrepopulation",
    ]),
    handleAddToDB() {
      const writingDate = new Date(this.formValues.writingDueDate + " " + this.formValues.writingDue);
      const editingDate = new Date(this.formValues.editingDueDate + " " + this.formValues.editingDue);
      if (writingDate && editingDate && editingDate <= writingDate) {
        this.showMessage("Secondary Due Date cannot be before Primary Due Date");
        return;
      }
      if (this.formValues.reviewingDueDate && this.formValues.reviewingDue) {
        const reviewingDate = new Date(this.formValues.reviewingDueDate + " " + this.formValues.reviewingDue);
        if (reviewingDate && reviewingDate <= editingDate || reviewingDate <= writingDate) {
          this.showMessage("QC Due Date cannot be before Secondary Due Date");
          return;
        }
      }
      for (let key in this.staffAssignments) {
        for (let secondKey in this.staffAssignments[key]) {
          for (let thirdKey in this.staffAssignments[key][secondKey]) {
            if (thirdKey.includes('workType') && 
              thirdKey.includes('Quantity') &&
              !thirdKey.includes('Overloaded')) {
              let value = this.staffAssignments[key][secondKey][thirdKey];
              if (value < 0) {
                return;
              }
            }
          }
        }
      }
      if (!this.writersValidated.length) {
        if (this.$refs.infoForm.validate()) {
          this.resetCallers();
          this.thinking = this.dbCall = true;
          const requestTime = this.$moment(
            new Date().toLocaleString("en-US", {
              timeZone: "America/New_York",
            })
          ).format("hh:mm A");
          if (!this.cueLoc && !this.cueCopywriting)
            this.setFormValue({
              key: "requestTime",
              value: requestTime,
            });
          addAssignmentRowsToDB(
            this.formValues,
            this.tableRows,
            this.staffAssignments,
            this.staffDatas
          )
          .then((response) => {
              const msg = response;
              // handle task status after upload
              const taskAddCondition = () => {
                  return (
                    (this.shakespeare ||
                      (this.tableRows[0].media_type === "Apps" &&
                        (this.tableRows[0].keyword === "MAS" ||
                          this.tableRows[0].keyword === "Today"))) &&
                    !this.cueCopywriting &&
                    !this.cueLoc
                  );
                },
                cueID =
                  this.$moment(this.tableRows[0].request_date).format(
                    "YYYY-MM-DD"
                  ) +
                  "_" +
                  (this.shakespeare
                    ? requestTime + "_" + this.formValues.assignmentTitle
                    : this.tableRows[0].task_id),
                data = {
                  projectId:
                    this.formValues.relatedProject?.id ??
                    this.projects.find((el) => el.name === "iTunes FC")?.id ??
                    null,
                  clientRequesterId:
                    this.formValues.relatedProject.clientRequesters?.find(
                      (req) =>
                        this.shakespeare || this.shakespeareLoc
                          ? req.user.firstName ===
                              this.formValues.clientRequester ||
                            req.user.lastName ===
                              this.formValues.clientRequester
                          : this.tableRows[0].client_requester.includes(
                              req.user.firstName
                            ) &&
                            this.tableRows[0].client_requester.includes(
                              req.user.lastName
                            )
                    )?.user?.id ?? null,
                  driId: this.user.user.id,
                  cueTaskId: cueID,
                  deliveryDate: this.formValues.deliveryDate,
                  deliveryTime: this.$moment(this.formValues.deliveryDue, [
                    "hh:mm A",
                  ]).format("HH:mm:ss"),
                  task: this.shakespeare
                    ? this.formValues.emailSubjectLine
                    : this.tableRows[0].subject_line,
                  mediaType: this.shakespeare
                    ? this.formValues.mediaType
                    : this.tableRows[0].media_type,
                  priority: null,
                  requestDate: this.$moment(
                    this.tableRows[0].request_date
                  ).format("YYYY-MM-DD"),
                  requestTime: this.$moment(requestTime, ["hh:mm A"]).format(
                    "HH:mm:ss"
                  ),
                  taskId: this.shakespeare
                    ? null
                    : this.$moment(this.tableRows[0].request_date).format(
                        "MM-DD-YYYY"
                      ) +
                      "_" +
                      this.tableRows[0].task_id,
                  taskStatus: "Assigned",
                  qc: false,
                  validation: null,
                };
              getAssignments({ query: { search: cueID } })
                .then((assgnResp) => {
                  // handle task creation after Shakespeare or Apps (MAS or Today) batch upload
                  if (taskAddCondition()) {
                    processTask({ data })
                      .then(() => {
                        this.thinking = false;
                        this.showMessage(msg);
                      })
                      .catch((err) => {
                        console.error(err);
                        this.thinking = false;
                        this.showMessage(err);
                      });
                  } else {
                    const assgnsWithCueIdField = assgnResp.content.filter(
                      (el) => el.cueTaskId === cueID
                    );
                    if (
                      assgnsWithCueIdField.every(
                        (el) =>
                          el.assignmentDetails.assignmentStatus === "Assigned"
                      )
                    ) {
                      getTasks({ query: { search: cueID } })
                        .then((taskResp) => {
                          const task = taskResp.content.find(
                            (el) => el.cueTaskId === cueID
                          );
                          if (task && task.taskStatus !== "Assigned") {
                            processTask({
                              data: {
                                taskStatus: "Assigned",
                              },
                              id: task.id,
                              patch: true,
                            })
                              .then(() => {
                                this.thinking = false;
                                this.showMessage(msg);
                              })
                              .catch((err) => {
                                console.error(err);
                                this.thinking = false;
                                this.showMessage(err);
                              });
                          } else {
                            throw "next";
                          }
                        })
                        .catch((err) => {
                          if (err !== "next") console.error(err);
                          this.thinking = false;
                          this.showMessage(err === "next" ? msg : err);
                        });
                    } else {
                      this.thinking = false;
                      this.showMessage(msg);
                    }
                  }
                })
                .catch((err) => {
                  console.error(err);
                  this.thinking = false;
                  this.showMessage(err);
                });
            })
            .catch((err) => this.showMessage(err));
        }
      } else {
        this.writersAlert = true;
      }
    },
    handleDoGoogle() {
      const writingDate = new Date(this.formValues.writingDueDate + " " + this.formValues.writingDue);
      const editingDate = new Date(this.formValues.editingDueDate + " " + this.formValues.editingDue);
      if (writingDate && editingDate && editingDate <= writingDate) {
        this.showMessage("Secondary Due Date cannot be before Primary Due Date");
        return;
      }
      if (this.formValues.reviewingDueDate && this.formValues.reviewingDue) {
        const reviewingDate = new Date(this.formValues.reviewingDueDate + " " + this.formValues.reviewingDue);
        if (reviewingDate && reviewingDate <= editingDate || reviewingDate <= writingDate) {
          this.showMessage("QC Due Date cannot be before Secondary Due Date");
          return;
        }
      }
      if (!this.writersValidated.length) {
        if (this.$refs.infoForm.validate()) {
          this.resetCallers();
          this.thinking = this.googleCall = true;
          addRowsToGoogleSheet({
            formValues: this.formValues,
            tableRows: this.tableRows,
            staffAssignments: this.staffAssignments,
          })
            .then((result) => {
              this.thinking = false;
              this.showMessage(result);
            })
            .catch((err) => {
              this.thinking = false;
              this.showMessage(err);
            });
        }
      } else {
        this.writersAlert = true;
      }
    },
    resetCallers() {
      this.dbCall = this.googleCall = false;
    },
    handleSplitByGeo() {
      doFileSplit(this.files, this.tableRows);
    },
    handleSplitByPerson() {
      this.splitByPersonProcessing = true;
      doFileSplitByPerson({
        files: this.files,
        staffAssignments: this.staffAssignments,
        staffNameGEOPairs: this.staffDatas,
      })
        .then((msg) => {
          this.showSplitByPersonStatus(msg);
          this.splitByPersonProcessing = false;
        })
        .catch((err) => {
          console.error(err);
          this.showSplitByPersonStatus(err);
          this.splitByPersonProcessing = false;
        });
    },
    showMessage(msg) {
      this.thinking = false;
      this.alertMessage = msg;
      msg === "Import Successful"
        ? (this.successAlert = true)
        : (this.failAlert = true);
    },
    showSplitByPersonStatus(msg) {
      this.alertMessage = msg;
      msg === "File(s) uploaded!"
        ? (this.successAlert = true)
        : (this.failAlert = true);
    },
    checkStaffInvolved(specialist) {
      const staffAssignments = Object.entries(this.staffAssignments);
      const geos = staffAssignments.map((el) => {
        return Object.entries(el[1]);
      });
      const staff = [];
      geos.forEach((geo) => {
        geo.forEach((el) => {
          if (el[0] !== "wordCount") staff.push(el[1]);
        });
      });
      return staff.some((el) => el?.[specialist]);
    },
    convertDeliveryDue() {
      if (!this.clientDeliveryDue || !this.timeZone) {
        this.conversionErrors = true;
      } else {
        this.conversionErrors = false;
        const clientDeliveryData = new Date(
          `${
            this.shakespeare || this.shakespeareLoc
              ? this.clientDeliveryDate
              : this.$moment(this.tableRows[0].due_date).format("YYYY-MM-DD")
          }T${this.$moment(this.clientDeliveryDue, "hh:mm A").format(
            "HH:mm"
          )}:00.000${this.timeZone.split(" ").at(-1)}`
        );
        const convertedDeliveryData = clientDeliveryData.toLocaleString(
          "en-US",
          {
            timeZone: "America/New_York",
          }
        );
        this.setFormValue({
          key: "deliveryDate",
          value: this.$moment(convertedDeliveryData).format("YYYY-MM-DD"),
        });
        this.setFormValue({
          key: "deliveryDue",
          value: clientDeliveryData.toLocaleString("en-US", {
            timeZone: "America/New_York",
            hour: "2-digit",
            minute: "2-digit",
          }),
        });
        if (this.formValues.urgent) {
          if (this.checkStaffInvolved("reviewer")) {
            let convertedReviewerData = this.$moment(
              convertedDeliveryData
            ).subtract(3, "hours");
            this.setFormValue({
              key: "reviewingDueDate",
              value: this.$moment(convertedReviewerData._d).format(
                "YYYY-MM-DD"
              ),
            });
            this.setFormValue({
              key: "reviewingDue",
              value: this.$moment(convertedReviewerData._d).format("hh:mm A"),
            });
          }
          if (this.checkStaffInvolved("editor")) {
            let convertedEditorData = this.$moment(
              convertedDeliveryData
            ).subtract(6, "hours");
            this.setFormValue({
              key: "editingDueDate",
              value: this.$moment(convertedEditorData._d).format("YYYY-MM-DD"),
            });
            this.setFormValue({
              key: "editingDue",
              value: this.$moment(convertedEditorData._d).format("hh:mm A"),
            });
          }
          let convertedWriterData = this.$moment(
            convertedDeliveryData
          ).subtract(9, "hours");
          this.setFormValue({
            key: "writingDueDate",
            value: this.$moment(convertedWriterData._d).format("YYYY-MM-DD"),
          });
          this.setFormValue({
            key: "writingDue",
            value: this.$moment(convertedWriterData._d).format("hh:mm A"),
          });
        } else {
          if (this.checkStaffInvolved("reviewer")) {
            let convertedReviewerData = this.$moment(
              convertedDeliveryData
            ).subtract(6, "hours");
            this.setFormValue({
              key: "reviewingDueDate",
              value: this.$moment(convertedReviewerData._d).format(
                "YYYY-MM-DD"
              ),
            });
            this.setFormValue({
              key: "reviewingDue",
              value: this.$moment(convertedReviewerData._d).format("hh:mm A"),
            });
          }
          if (this.checkStaffInvolved("editor")) {
            let convertedEditorData = this.$moment(
              convertedDeliveryData
            ).subtract(18, "hours");
            this.setFormValue({
              key: "editingDueDate",
              value: this.$moment(convertedEditorData._d).format("YYYY-MM-DD"),
            });
            this.setFormValue({
              key: "editingDue",
              value: this.$moment(convertedEditorData._d).format("hh:mm A"),
            });
          }
          let convertedWriterData = this.$moment(
            convertedDeliveryData
          ).subtract(24, "hours");
          this.setFormValue({
            key: "writingDueDate",
            value: this.$moment(convertedWriterData._d).format("YYYY-MM-DD"),
          });
          this.setFormValue({
            key: "writingDue",
            value: this.$moment(convertedWriterData._d).format("hh:mm A"),
          });
        }
      }
    },
    handleDueData(value, flow, timeMode) {
      this.setFormValue({ key: `${flow}Due${timeMode ? "" : "Date"}`, value });
      if (value)
        timeMode
          ? this.handleDueHoursValidation(flow, this[`${flow}DueHours`])
          : this.handleStaffDuesValidation();
    },
    handleDueHoursValidation(operation, updatedArray) {
      this[`${operation}DueHoursAvailable`] = !updatedArray.find(
        (el) => el.time === this.formValues[`${operation}Due`]
      )?.disabled;
    },
    handleDueHoursAvailability(operation) {
      if (
        this.formValues.deliveryDate &&
        this.formValues[`${operation}DueDate`] ===
          this.formValues.deliveryDate &&
        !this.formValues.override
      ) {
        return this.hoursList.map((el) => {
          if (
            this.hoursList.indexOf(el) >
            this.hoursList.findIndex(
              (el) => el.time === this.formValues.deliveryDue
            )
          ) {
            return Object.assign({}, { time: el.time, disabled: true });
          } else {
            return Object.assign({}, { time: el.time, disabled: false });
          }
        });
      } else if (
        this.$moment(this.formValues[`${operation}DueDate`]).isAfter(
          this.formValues.deliveryDate
        ) &&
        !this.formValues.override
      ) {
        return this.hoursList.map((el) => {
          return Object.assign({}, { time: el.time, disabled: true });
        });
      } else {
        return this.hoursList;
      }
    },
    handleStaffDuesValidation() {
      ["writing", "editing", "reviewing"].forEach((operation) => {
        if (!this.formValues.override) {
          if (
            this.formValues.deliveryDate &&
            this.formValues[`${operation}DueDate`]
          ) {
            this[`${operation}DueDateAvailable`] = this.$moment(
              this.formValues[`${operation}DueDate`]
            ).isSameOrBefore(this.formValues.deliveryDate);
            this.$refs[`${operation}DueDateField`].validate(true);
          }
        } else {
          this[`${operation}DueDateAvailable`] = true;
          this.$refs[`${operation}DueDateField`].validate(true);
        }
      });
    },
    validateIndiviadualGeoDeadlines(index, geo) {
      const source = this.formValues.individualGeosDeadlines[index][geo];
      let validationCheck = [];
      if (source)
        ["writing", "editing", "reviewing"].forEach((operation) => {
          if (!this.formValues.override) {
            if (this.formValues.deliveryDate && source[`${operation}DueDate`]) {
              validationCheck.push(
                this.$moment(source[`${operation}DueDate`]).isSameOrBefore(
                  this.formValues.deliveryDate
                )
              );
            }
          } else {
            validationCheck.push(true);
          }
          validationCheck.push(
            ![`${operation}DueHours`].find(
              (el) => el.time === source[`${operation}Due`]
            )?.disabled
          );
        });
      return validationCheck.every((el) => el === true);
    },
    getDatesRange(startDate, endDate) {
      let datesRange = [],
        dateVar = startDate;
      for (
        dateVar;
        this.$moment(dateVar).isSameOrBefore(this.$moment(endDate));
        dateVar = this.$moment(dateVar).add(1, "days").format("YYYY-MM-DD")
      ) {
        datesRange.push(this.$moment(dateVar).format("YYYY-MM-DD"));
      }
      return datesRange;
    },
    handleWorkTypesPrepopulation(key, value, specialGeo) {
      const specailGeosData = {
          workType2Quantity: {
            "ar-SA": "1",
            "pt-PT": "1",
            "ru-RU": "1",
            "uk-UA": "1",
            "es-ES": "1",
            "es-MX": "1",
            "it-IT": "1",
            "nl-NL": "1",
            "de-DE": "1",
          },
          workType4Quantity: {
            "es-ES": "1",
            "es-MX": "1",
            "it-IT": "1",
            "nl-NL": "1",
            "de-DE": "1",
          },
        },
        handleSpecialGeosData = (key, geo, initialValue) => {
          return specailGeosData?.[key]?.[geo] ?? initialValue;
        };
      let newAssignments = { ...this.staffAssignments },
        idGeoPairs = [];
      Object.entries(newAssignments).forEach((el) => {
        const geo = Object.keys(el[1]).find(
          (key) => key !== "content_id" || key !== "task_name"
        );
        this.backstageCopywriting
          ? Object.keys(el[1]).forEach((geo) => idGeoPairs.push([el[0], geo]))
          : idGeoPairs.push([el[0], geo]);
      });
      idGeoPairs.forEach(
        (el) =>
          (newAssignments[el[0]][el[1]][key] = specialGeo
            ? handleSpecialGeosData(key, el[1], value)
            : value)
      );
      this.setStaffAssignments(newAssignments);
    },
    prepopulationProcessor(el) {
      if (el.key === "relatedProject") {
        const project = this.projects.find((item) => item.name === el.value);
        this.updateRelatedProjectSmartFields(project);
      } else {
        this.setFormValue({
          key: el.key,
          value: el.value,
        });
      }
    },
    handlePrepopulation(prepopulationFlow) {
      this.setPrepopulation(prepopulationFlow);
      switch (prepopulationFlow) {
        case 0:
          return [
            {
              key: "relatedProject",
              value: "iTunes FC",
            },
            {
              key: "mediaType",
              value: "Music",
            },
            {
              key: "clientRequester",
              value: "Shakespeare",
            },
            {
              key: "workType1Reference",
              value: "CWR_500-60-1100",
            },
            {
              key: "workType2Reference",
              value: "CWR_1000-120-1500",
            },
            {
              key: "workType2Quantity",
              value: null,
            },
            {
              key: "workType3Reference",
              value: "CWR_1500-180-2800",
            },
            {
              key: "workType4Reference",
              value: null,
            },
            {
              key: "workType4Quantity",
              value: null,
            },
            {
              key: "workType5Reference",
              value: "CWR_5000-500-7000",
            },
            {
              key: "workType6Reference",
              value: "CWR_300-100-500",
            },
          ].forEach((el) =>
            el.key.startsWith("workType")
              ? this.handleWorkTypesPrepopulation(el.key, el.value, true)
              : this.prepopulationProcessor(el)
          );
        case 1:
          return [
            {
              key: "relatedProject",
              value: "iTunes FC",
            },
            {
              key: "mediaType",
              value: "Music",
            },
            {
              key: "clientRequester",
              value: "Shakespeare",
            },
            {
              key: "workType1Reference",
              value: "CWR_500-60-1100",
            },
            {
              key: "workType2Reference",
              value: "CWR_1000-120-1500",
            },
            {
              key: "workType2Quantity",
              value: null,
            },
            {
              key: "workType3Reference",
              value: "CWR_1500-180-2800",
            },
            {
              key: "workType4Reference",
              value: "CWR_2500-300-3800",
            },
            {
              key: "workType4Quantity",
              value: null,
            },
            {
              key: "workType5Reference",
              value: "CWR_5000-500-7000",
            },
            {
              key: "workType6Reference",
              value: "CWR_300-100-500",
            },
          ].forEach((el) =>
            el.key.startsWith("workType")
              ? this.handleWorkTypesPrepopulation(el.key, el.value)
              : this.prepopulationProcessor(el)
          );
        case 2:
          return [
            {
              key: "relatedProject",
              value: "iTunes FC",
            },
            {
              key: "mediaType",
              value: "Video",
            },
            {
              key: "clientRequester",
              value: "Shakespeare",
            },
            {
              key: "workType1Reference",
              value: "CWR_500-60-1100",
            },
            {
              key: "workType2Reference",
              value: "CWR_1000-120-1500",
            },
            {
              key: "workType2Quantity",
              value: null,
            },
            {
              key: "workType3Reference",
              value: "CWR_1500-180-2800",
            },
            {
              key: "workType4Reference",
              value: "CWR_2500-300-3800",
            },
            {
              key: "workType4Quantity",
              value: null,
            },
            {
              key: "workType5Reference",
              value: "CWR_5000-500-7000",
            },
            {
              key: "workType6Reference",
              value: "CWR_300-100-500",
            },
          ].forEach((el) =>
            el.key.startsWith("workType")
              ? this.handleWorkTypesPrepopulation(el.key, el.value)
              : this.prepopulationProcessor(el)
          );
        default:
          return [
            "relatedProject",
            "po",
            "mediaType",
            "clientRequester",
            "emailSubjectLine",
            "workType1Reference",
            "workType2Reference",
            "workType2Quantity",
            "workType3Reference",
            "workType4Reference",
            "workType4Quantity",
            "workType5Reference",
            "workType6Reference",
          ].forEach((el) => {
            el.startsWith("workType")
              ? this.handleWorkTypesPrepopulation(el)
              : this.setFormValue({
                  key: el,
                  value: null,
                });
          });
      }
    },
    getProjects() {
      this.setStaffLoading(true);
      this.projectsLoading = true;
      getAllProjects()
        .then((data) => {
          this.projects = data;
          this.projectsLoading = false;
          if (typeof this.relatedProject === "string")
            this.updateRelatedProjectSmartFields(this.relatedProject);
        })
        .catch((err) => {
          console.error(err);
          this.setStaffLoading(false);
          this.projectsLoading = false;
        });
    },
    getFileLocations() {
      this.fileLocationsLoading = true;
      getEnum("FileLocation")
        .then((data) => {
          this.fileLocations = data;
          this.fileLocationsLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.fileLocationsLoading = false;
        });
    },
    updateRelatedProjectSmartFields(selectedProject) {
      this.setFormValue({
        key: "relatedProject",
        value:
          typeof selectedProject === "string"
            ? this.projects.find((el) => el.name === selectedProject)
            : selectedProject,
      });
      this.setFormValue({
        key: "po",
        value:
          typeof this.formValues.po !== "object"
            ? this.relatedProject?.purchaseOrders?.find(
                (el) => el.purchaseOrderNumber == this.formValues.po
              )
            : this.relatedProject?.purchaseOrders?.find((el) => el.isActive) ??
              null,
      });
      this.setStaffData();
    },
    handlePayPeriod(dateInput) {
      if (!this.formValues.payPeriod) {
        this.setFormValue({
          key: "payPeriod",
          value: dateInput.slice(0, -3),
        });
      }
    },
    setStaffData() {
      // mutate data according to already implemented API
      this.staffDatas = this.relatedProject.staff
        .map((el) => ({
          ...el,
          disabled: false,
          geos: el.adminDetails.geos,
          initials: el.adminDetails.initials,
          name: `${el.user.firstName} ${el.user.lastName}`,
          slackID: el.adminDetails.slackIdFc,
          cueSlackID: el.adminDetails.slackIdStaff,
        }))
        .sort((a, b) => a.user.firstName.localeCompare(b.user.firstName));
      this.setStaffLoading(false);
    },
    setCapacityDatas() {
      // getReportData({
      //   reportID: 119,
      //   tableID: appIDs.cueStaff.contacts,
      // })
      //   .then((data) => {
      //     this.updateCapacityData({
      //       dataPiece: "defaultCapacityPool",
      //       newData: data,
      //     });
      //   })
      //   .catch((err) => console.error(err));
      // getReportData({
      //   reportID: 13,
      //   tableID: appIDs.masterAssignments.assignments,
      // })
      //   .then((data) => {
      //     this.updateCapacityData({ dataPiece: "capacityPool", newData: data });
      //   })
      //   .catch((err) => console.error(err));
      getEvents()
        .then((data) => {
          const availabilityRecords = data.map((el) => ({
            name: `${el.personName?.firstName} ${el.personName?.lastName}`,
            dates: this.getDatesRange(
              this.$moment(el.startDate),
              this.$moment(el.endDate)
            ),
            adjustedWritingWorkload: el.adjustedDailyLocalization,
            adjustedEditingWorkload: el.adjustedDailyEditing,
          }));
          this.updateCapacityData({
            dataPiece: "adjustedCapacityPool",
            newData: availabilityRecords,
          });
        })
        .catch((err) => console.error(err));
    },
    handleIndiviadualGeoDeadlines(editMode, index) {
      this.indiviadualGeoDeadlinesEdit = editMode;
      this.indiviadualGeoDeadlinesItemIndex = index;
      this.$refs.geoDeadlinesForm?.$refs.form.resetValidation();
      this.indiviadualGeoDeadlines = true;
    },
    closeIndiviadualGeoDeadlines() {
      this.indiviadualGeoDeadlines = false;
      this.indiviadualGeoDeadlinesItemIndex = null;
    },
  },
  mixins: [timeUnits],
  computed: {
    ...mapGetters("spreadsheetUpload", [
      "files",
      "tableRows",
      "formValues",
      "staffAssignments",
      "xContent",
      "shakespeare",
      "shakespeareLoc",
      "backstageCopywriting",
      "cueLoc",
      "cueCopywriting",
      "splitByPersonFilesAmount",
      "splitByPersonSend"
    ]),
    ...mapGetters("auth", ["user"]),
    relatedPurchaseOrders() {
      return this.formValues.relatedProject?.purchaseOrders?.filter(
        (el) => el.isActive
      );
    },
    assignmentTitles() {
      return this.shakespeare || this.backstageCopywriting
        ? [...this.amAssignmentTitles, ...this.fcAssignmentTitles].sort()
        : this.shakespeareLoc
        ? ["Arcade File", "Discovery File", "MAS File", "Today File"]
        : this.fcAssignmentTitles;
    },
    relatedProject() {
      return this.formValues.relatedProject;
    },
    deliveryDate() {
      return this.formValues.deliveryDate;
    },
    override() {
      return this.formValues.override;
    },
    writingDueHours() {
      return this.handleDueHoursAvailability("writing");
    },
    editingDueHours() {
      return this.handleDueHoursAvailability("editing");
    },
    reviewingDueHours() {
      return this.handleDueHoursAvailability("reviewing");
    },
    writersValidated() {
      let writersValidation = [];
      Object.entries(this.staffAssignments).forEach((el) => {
        Object.entries(el[1]).forEach((item) => {
          if (typeof item[1] === "object" && !!!item[1].writer)
            writersValidation.push({
              assignment: this.shakespeare ? el[1].task_name : el[0],
              geos: item[0],
            });
        });
      });
      writersValidation = _.uniqWith(writersValidation, (pre, cur) => {
        if (pre.assignment == cur.assignment) {
          cur.geos = cur.geos + ", " + pre.geos;
          return true;
        }
        return false;
      });
      return writersValidation;
    },
    overloadingValidated() {
      const overloadingValidation = [];
      Object.values(this.staffAssignments).forEach((el) => {
        Object.entries(el).forEach((geo) => {
          if (
            this.shakespeare
              ? geo[0] !== "task_name" && geo[0] !== "content_id"
              : geo[0] !== "wordCount"
          )
            overloadingValidation.push(
              geo[1]?.writerOverloaded,
              geo[1]?.editorOverloaded,
              geo[1]?.reviewerOverloaded
            );
        });
      });
      return overloadingValidation.every((el) => !!el === false);
    },
    exportedPath() {
      return `${DOMAIN}/${ROUTES.assignmentDashboard}?ppm=${this.$moment(
        this.formValues.payPeriod
      ).format("MMMM")}&ppy=${this.$moment(this.formValues.payPeriod).format(
        "YYYY"
      )}&DeliveryDate=${this.formValues.deliveryDate}&Person=${
        this.user.user.firstName
      }${this.user.user.lastName}&Role=Creator${
        this.formValues.assignmentTitle
          ? `&Search=${this.formValues.assignmentTitle}`
          : ""
      }`;
    },
    spreadsheetPath() {
      return `https://docs.google.com/spreadsheets/d/${SPREADSHEET_ID}/edit#gid=71384832`;
    },
    formattedPayPeriod: {
      get() {
        return this.formValues.payPeriod
          ? `${this.$moment(this.formValues.payPeriod).format("MM-YYYY")}`
          : null;
      },
      set() {
        this.formValues.payPeriod = null;
      },
    },
    formattedClientDeliveryDate: {
      get() {
        return this.formatPickerDate(this.clientDeliveryDate);
      },
      set() {
        this.clientDeliveryDate = null;
      },
    },
    formattedDeliveryDate: {
      get() {
        return this.formatPickerDate(this.formValues.deliveryDate);
      },
      set() {
        this.formValues.deliveryDate = null;
      },
    },
    formattedWritingDueDate: {
      get() {
        return this.formatPickerDate(this.formValues.writingDueDate);
      },
      set() {
        this.formValues.writingDueDate = null;
      },
    },
    formattedEditingDueDate: {
      get() {
        return this.formatPickerDate(this.formValues.editingDueDate);
      },
      set() {
        this.formValues.editingDueDate = null;
      },
    },
    formattedReviewingDueDate: {
      get() {
        return this.formatPickerDate(this.formValues.reviewingDueDate);
      },
      set() {
        this.formValues.reviewingDueDate = null;
      },
    },
  },
  mounted() {
    this.getProjects();
    this.getFileLocations();
    this.setCapacityDatas();
  },
};
</script>

<style lang="scss" scoped>
.info-form {
  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }

  &__main-form {
    box-sizing: border-box;
    position: relative;

    .row {
      width: 100%;
      margin-left: 0px;
      border-radius: 4px;
      & * {
        width: 50%;
      }
    }

    &__staff-section {
      &__button-container {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

#indiv-geo .indiv-geo {
  &-title {
    width: fit-content;
  }
  &-badge {
    width: unset;
  }
  &-btn {
    width: fit-content;
    text-transform: none !important;
  }
}

::v-deep {
  .success-alert {
    & .v-alert {
      &__content {
        display: flex;
        align-items: center;
      }
      &__icon {
        align-self: center;
      }
    }
  }
  .v-badge__badge .v-icon {
    margin: -2px -2px 0;
  }
}
</style>
