var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('NavBar'),_c('FlashMessage'),_c('v-main',{class:{ 
      'grey lighten-4': _vm.$route.name !== 'Reimbursement Request Form' 
        || _vm.$route.name !== 'Request an Assignment'
        || _vm.$route.name !== 'Vendor Invoice Form',
      'grey darken-2': _vm.$route.name === 'Reimbursement Request Form' 
        || _vm.$route.name === 'Request an Assignment'
        || _vm.$route.name === 'Vendor Invoice Form'
      }},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }