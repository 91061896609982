import { render, staticRenderFns } from "./PMDashboardTable.vue?vue&type=template&id=900c546c&scoped=true&"
import script from "./PMDashboardTable.vue?vue&type=script&lang=js&"
export * from "./PMDashboardTable.vue?vue&type=script&lang=js&"
import style0 from "./PMDashboardTable.vue?vue&type=style&index=0&id=900c546c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "900c546c",
  null
  
)

export default component.exports