<template>
  <div class="pm-dashboard my-10 px-2">
    <div class="d-flex" :style="{ position: 'relative' }">
      <!-- left -->

      <!-- new task -->
      <div class="text-center">
        <span class="overline"> New </span>
        <div class="mb-4">
          <v-btn
            height="38px"
            :min-width="laptop ? '40px' : '42px'"
            class="elevation-2 px-0"
            color="secondary"
            :loading="dropdownsLoading"
            @click="handleForm({})"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>

      <!-- clear filters -->
      <div class="text-center ml-1">
        <span class="overline"> Clear </span>
        <div class="mb-4">
          <v-btn
            height="38px"
            :min-width="laptop ? '40px' : '42px'"
            class="elevation-2 px-0"
            color="secondary"
            :disabled="showTableLoader || isTableUpdating"
            @click="clearFilter(false)"
          >
            <v-icon>mdi-filter-remove</v-icon>
          </v-btn>
        </div>
      </div>

      <div class="d-flex">
        <!-- period filter -->
        <div class="ml-lg-3 ml-md-2">
          <span class="overline"> Period </span>
          <div class="d-flex mb-4">
            <v-autocomplete
              class="pm-dashboard__month-selector selector mr-1"
              :items="monthOptions"
              v-model="ppm"
              solo
              dense
              hide-details
              :disabled="showTableLoader || isTableUpdating"
              @change="handleFilterQuery('ppm', $event)"
            ></v-autocomplete>
            <v-autocomplete
              class="pm-dashboard__year-selector selector"
              :items="yearOptions"
              v-model="ppy"
              solo
              dense
              hide-details
              :disabled="showTableLoader || isTableUpdating"
              @change="handleFilterQuery('ppy', $event)"
            >
            </v-autocomplete>
          </div>
        </div>
        <!-- project filter -->
        <div class="ml-lg-2 ml-md-1 text-center">
          <span class="overline"> Project </span>
          <div>
            <v-autocomplete
              class="pm-dashboard__project-selector selector"
              :class="{ laptop }"
              :items="projectsList"
              item-text="name"
              return-object
              v-model="filterProject"
              solo
              dense
              hide-details
              :disabled="showTableLoader || isTableUpdating"
              :loading="dropdownsLoading"
              @input="handleFilterQuery('Project', $event)"
            ></v-autocomplete>
          </div>
        </div>
        <!-- request date filter -->
        <div class="ml-lg-2 ml-md-1 text-center">
          <span class="overline"> Request Date </span>
          <div class="d-flex align-end mb-4">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              solo-inverted
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedRequestDate"
                  class="pm-dashboard__date-selector selector"
                  solo
                  dense
                  readonly
                  hide-details
                  clearable
                  :disabled="showTableLoader || isTableUpdating"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="clearFilter('RequestDate')"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterRequestDate"
                @input="handleFilterQuery('RequestDate', $event)"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
        <!-- person filter -->
        <div class="ml-lg-2 ml-md-1 text-center">
          <span class="overline"> DRI </span>
          <div class="d-flex mb-4">
            <v-autocomplete
              :class="{ laptopSmaller }"
              class="pm-dashboard__person-selector selector"
              :items="employees"
              :item-text="fullName"
              return-object
              v-model="filterPerson"
              solo
              dense
              hide-details
              :disabled="showTableLoader || isTableUpdating"
              :loading="dropdownsLoading"
              @input="handleFilterQuery('Person', $event)"
            ></v-autocomplete>
          </div>
        </div>
      </div>
      <!-- task status filter -->
      <transition>
        <div v-show="!expandMenu" class="ml-lg-2 ml-md-1 text-center">
          <span class="overline"> Task Status </span>
          <div class="mb-4">
            <v-autocomplete
              :class="{ laptopSmaller }"
              class="pm-dashboard__main-selector selector"
              :items="taskStatuses"
              v-model="filterStatus"
              item-text="value"
              solo
              dense
              hide-details
              :disabled="showTableLoader || isTableUpdating"
              :loading="dropdownsLoading"
              @input="handleFilterQuery('Status', $event)"
            ></v-autocomplete>
          </div>
        </div>
      </transition>
      <!-- delivery date filter -->
      <transition>
        <div v-show="!expandMenu" class="ml-lg-2 ml-md-1 text-center">
          <span class="overline"> Delivery Date </span>
          <div class="d-flex align-end mb-4">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              solo-inverted
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedDeliveryDate"
                  class="pm-dashboard__date-selector selector"
                  solo
                  dense
                  readonly
                  hide-details
                  clearable
                  :disabled="showTableLoader || isTableUpdating"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="clearFilter('DeliveryDate')"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterDeliveryDate"
                @input="handleFilterQuery('DeliveryDate', $event)"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
      </transition>

      <!-- right -->
      <div class="d-flex flex-column justify-start align-end my-0 ml-auto">
        <!-- search -->
        <span class="overline" :style="{ opacity: 0, userSelect: 'none' }"
          >Search</span
        >
        <div class="mb-4">
          <v-badge
            :content="filterSearchBadge"
            :value="filterSearch && !expandMenu"
            color="secondary"
            overlap
          >
            <v-btn
              height="38px"
              min-width="48px"
              class="elevation-2 px-0"
              outlined
              color="accent"
              @click="expandMenu = !expandMenu"
            >
              <v-icon v-if="expandMenu">mdi-magnify-minus-outline</v-icon>
              <v-icon v-else>mdi-magnify-plus-outline</v-icon>
            </v-btn>
          </v-badge>
          <v-expand-x-transition>
            <v-text-field
              :style="{ position: 'absolute', bottom: '16px', right: '60px' }"
              v-show="expandMenu"
              v-model="filterSearch"
              class="pm-dashboard__search-selector selector"
              solo
              dense
              hide-details
              clearable
              :disabled="showTableLoader || isTableUpdating"
              @click:clear="applySearch(true)"
              @keydown.enter="applySearch(false)"
            />
          </v-expand-x-transition>
        </div>
      </div>
    </div>
    <!-- table -->
    <v-card class="pm-dashboard__table-card">
      <v-skeleton-loader
        v-if="showTableLoader || dropdownsLoading"
        type="table"
        height="100vh"
      ></v-skeleton-loader>
      <PMDashboardTable
        ref="pmDashboardTable"
        v-if="tableData && !showTableLoader && !dropdownsLoading"
        :tableData="tableData"
        :serverItemsLength="serverItemsLength"
        :isTableUpdating="isTableUpdating"
        :leadPM="leadPM"
        :taskStatuses="taskStatuses"
        :employees="employees"
        :dropdownOptionsLoading="dropdownsLoading"
        @call-edit-form="handleForm"
        @get-table-data="getTableData"
        @refresh-request="refreshTableData"
      />
    </v-card>
    <!-- task form -->
    <v-dialog
      v-model="taskForm"
      max-width="1200"
      @click:outside="taskForm = false"
      @keydown.esc="taskForm = false"
    >
      <PMDashboardForm
        :mediaTypes="mediaTypes"
        :taskStatuses="taskStatuses"
        :priorities="priorities"
        :employees="employees"
        :projectsList="projectsList"
        :itemToEdit="itemToEdit"
        @close-task-form="handleFormClosing"
      />
    </v-dialog>
  </div>
</template>

<script>
// vuex
import { mapActions } from "vuex";
// libraries
import _ from "lodash";
// internal
import {
  getTasks,
  getAllStaff,
  getAllProjects,
  getEnum,
  leadPMEmail,
} from "@/utils/newDbUtils";
import { customBreakpoint, timeUnits } from "@/utils/mixins";
import { STATUS_COLORS, ROUTES } from "@/utils/constants";
// components
import PMDashboardTable from "@/components/PMDashboard/PMDashboardTable";
import PMDashboardForm from "@/components/PMDashboard/PMDashboardForm.vue";

export default {
  name: "PMDashboard",
  components: {
    PMDashboardTable,
    PMDashboardForm,
  },
  data() {
    return {
      showTableLoader: true,
      isTableUpdating: false,
      tableData: null,
      serverItemsLength: 0,
      filterDeliveryDate: null,
      filterRequestDate: null,
      filterSearch: null,
      expandMenu: false,
      dropdownsLoading: true,
      employees: [],
      filterPerson: null,
      taskStatuses: [],
      filterStatus: null,
      priorities: [],
      mediaTypes: [],
      projectsList: [],
      filterProject: null,
      taskForm: false,
      itemToEdit: {},
      ppm: null,
      ppy: null,
      itemsPerPage: 50,
      page: 0,
      sortBy: "requestDate&sort=requestTime",
      sorting: "desc",
      leadPM: {},
    };
  },
  mixins: [customBreakpoint, timeUnits],
  computed: {
    filterSearchBadge() {
      return this.filterSearch?.length <= 6
        ? this.filterSearch
        : `${this.filterSearch?.slice(0, 6)}...`;
    },
    formattedRequestDate: {
      get() {
        return this.formatPickerDate(this.filterRequestDate);
      },
      set() {
        this.filterRequestDate = null;
      },
    },
    formattedDeliveryDate: {
      get() {
        return this.formatPickerDate(this.filterDeliveryDate);
      },
      set() {
        this.filterDeliveryDate = null;
      },
    },
    query() {
      return {
        page: this.page,
        size: this.itemsPerPage,
        sort: `${this.sortBy},${this.sorting}`,
        search: this.filterSearch,
        requestDate: this.filterRequestDate,
        deliveryDate: this.filterDeliveryDate,
        ppy: this.ppy,
        ppm: this.ppm?.toUpperCase(),
        projectId: this.filterProject?.id,
        driId: this.filterPerson?.user?.id,
        taskStatus: this.filterStatus ?? "!Archived",
      };
    },
  },
  watch: {
    filterSearch(newValue) {
      const currentQuery = this.$route.query;
      const newQuery =
        newValue !== ""
          ? {
              ...currentQuery,
              Search: newValue,
            }
          : _.omit(currentQuery, ["Search"]);

      this.$router
        .push({ path: `/${ROUTES.pmDashboard}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
  },
  mounted() {
    this.handleQuery();
    this.handleDropdowns();
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    fullName(item) {
      return `${item.user.firstName} ${item.user.lastName}`;
    },
    getTableData(hardReload) {
      if (hardReload) this.showTableLoader = true;
      else this.isTableUpdating = true;
      getTasks({ query: this.query }).then((resp) => {
        if (resp?.status > 204) {
          this.tableData = [];
          this.handleFlash({ response: resp, show: true });
        } else
          this.tableData = resp.content.map((el, i) => ({
            ...el,
            index: i,
          }));

        this.serverItemsLength = resp?.totalElements ?? 0;
        this.showTableLoader = this.isTableUpdating = false;
      });
    },
    refreshTableData(options) {
      const handleSort = () => {
        const sort = options.sortBy[0];
        return sort === undefined || sort === "sortParams" ? this.sortBy : sort;
      };
      this.itemsPerPage = options.itemsPerPage;
      this.page = options.page - 1;
      this.sortBy = handleSort();
      this.sorting =
        options.sortDesc[0] === undefined
          ? "desc"
          : options.sortDesc[0]
          ? "desc"
          : "asc";
      this.getTableData();
    },
    handleFilterQuery(param, data) {
      const query = {
        ...this.$route.query,
        [param]:
          param === "Person" && data
            ? `${data.user.firstName} ${data.user.lastName}`
            : param === "Project" && data
            ? data?.name
            : data,
      };

      this.$router
        .push({ path: `/${ROUTES.pmDashboard}`, query })
        .catch(() => {}); // Avoided redundant navigation error handler

      this[param === "ppy" || param === "ppm" ? param : `filter${param}`] =
        data;

      if (param === "ppm" && !this.ppy) return;

      if (this.$refs.pmDashboardTable?.options?.page === 1) this.getTableData();
      else this.$refs.pmDashboardTable.options.page = 1;
    },
    clearFilter(date) {
      let query = this.$route.query;

      if (date) {
        query = _.omit(query, [date]);
        this[`filter${date}`] = null;
      } else {
        query = {};
        this.filterProject =
          this.filterPerson =
          this.filterStatus =
          this.filterSearch =
          this.ppm =
          this.ppy =
            null;
      }

      if (!date) this.filterRequestDate = this.filterDeliveryDate = null;

      this.$router
        .push({ path: `/${ROUTES.pmDashboard}`, query })
        .catch(() => {}); // Avoided redundant navigation error handler

      if (this.$refs.pmDashboardTable?.options?.page === 1) this.getTableData();
      else this.$refs.pmDashboardTable.options.page = 1;
    },
    handleDropdowns() {
      Promise.all([
        getAllProjects(),
        getEnum("MediaType"),
        getEnum("Priority"),
        getEnum("TaskStatus"),
        getAllStaff(),
      ])
        .then((resp) => {
          [this.projectsList, this.mediaTypes, this.priorities] = resp;
          this.taskStatuses = resp[3].map((el) => ({
            value: el,
            color: STATUS_COLORS.find((color) => color.statuses.includes(el))
              ?.value,
          }));
          this.employees =
            resp[4].filter(
              (el) =>
                el.user.userRole === "ADMIN" || el.user.userRole === "MANAGER"
            ) ?? [];
          this.leadPM = this.employees.find(
            (el) => el.user.email === leadPMEmail
          );
          if (this.$route.query?.Person || this.$route.query?.Project) {
            if (this.$route.query?.Person)
              this.filterPerson = this.employees.find(
                (el) =>
                  this.$route.query.Person.includes(el.user.firstName) &&
                  this.$route.query.Person.includes(el.user.lastName)
              );
            if (this.$route.query?.Project)
              this.filterProject = this.projectsList.find(
                (el) => this.$route.query.Project === el.name
              );
            this.getTableData(true);
          }
          this.dropdownsLoading = false;
        })
        .catch((err) => {
          this.dropdownsLoading = false;
          console.error(err);
        });
    },
    handleQuery() {
      let query = { ...this.$route.query };
      if (_.isEmpty(query)) {
        this.ppm = this.$moment().format("MMMM");
        this.ppy = this.$moment().format("YYYY");
        query = {
          ppm: this.ppm,
          ppy: this.ppy,
        };
      } else {
        Object.keys(query).forEach((param) => {
          this[param === "ppy" || param === "ppm" ? param : `filter${param}`] =
            query[param];
        });
      }
      this.$router
        .push({ path: `/${ROUTES.pmDashboard}`, query })
        .catch(() => {}); // Avoided redundant navigation error handler

      if (!query?.Person && !query?.Project) this.getTableData(true);
    },
    applySearch(reset) {
      if (reset) this.filterSearch = null;
      this.handleFilterQuery("Search", this.filterSearch);
    },
    handleForm(tableRow) {
      this.itemToEdit = { ...tableRow };
      this.taskForm = true;
    },
    handleFormClosing() {
      this.getTableData(true);
      this.taskForm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.pm-dashboard {
  max-width: 1500px;
  margin: 0 auto;

  &__month-selector {
    width: 120px;
  }

  &__year-selector {
    width: 80px;
  }

  &__project-selector {
    width: 200px;
    &.laptop {
      width: 180px;
    }
  }

  &__date-selector {
    width: 130px;
  }

  &__person-selector {
    width: 200px;
    &.laptopSmaller {
      width: 180px;
    }
  }

  &__main-selector {
    width: 150px;
    &.laptopSmaller {
      width: 140px;
    }
  }

  &__search-selector {
    width: 340px;
  }
}

::v-deep {
  .selector.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding-right: 0;
  }
  .selector.v-text-field.v-text-field--solo .v-input__append-inner {
    padding-left: 0;
  }
  .selector.v-autocomplete.v-select.v-input--is-focused input {
    min-width: 0;
  }
  .selector.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 2px 0;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'lg-and-down')} {
  .v-application .overline {
    font-size: 0.6rem !important;
  }
}
</style>
