import axios from "axios";
import router from "@/router";

const baseURL = `https://cocue${
    process.env.VUE_APP_NODE_ENV === "production" ? "hub" : "dev"
  }.com/api`,
  axiosInstance = axios.create({ baseURL });

export const validateToken = async (token) => {
  try {
    const resp = await axios.get(`${baseURL}/token-validate`, {
      headers: {
        Authorization: `Bearer_${token}`,
      },
    });
    return resp;
  } catch (err) {
    return err.response;
  }
};

export const refreshSession = async (refreshToken) => {
  try {
    const resp = await axios.post(`${baseURL}/auth/refresh-token`, {
      refreshToken,
    });
    return resp;
  } catch (err) {
    return err.response;
  }
};

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    if (config.url !== "/auth/login") {
      if (token) {
        await validateToken(token).then(async (validResp) => {
          if (validResp.status === 200) {
            config.headers.Authorization = `Bearer_${token}`;
          } else {
            await refreshSession(refreshToken).then((refResp) => {
              if (refResp.status === 200) {
                localStorage.setItem("token", refResp.data.accessToken);
                config.headers.Authorization = `Bearer_${refResp.data.accessToken}`;
              } else {
                router.app.$store._actions["auth/setIsAdmin"][0](false);
                router.app.$store._actions["auth/setIsManager"][0](false);
                router.app.$store._actions["auth/setIsDubEditor"][0](false);
                router.app.$store._actions["auth/setUser"][0](null);
                if (router.currentRoute.path !== "/login")
                  router.push(`/login?to=${router.currentRoute.fullPath}`);
                router.app.$store._actions["flashMessage/handleFlash"][0]({
                  response: refResp,
                  show: true,
                });
              }
            });
          }
        });
      } else {
        if (router.currentRoute.path !== "/login")
          router.push(`/login?to=${router.currentRoute.fullPath}`);
      }
    }
    // replacing "+" sign in url's search params
    if (config.url && (/\+|\[|\]/.test(config.url)) && config.url.includes('search=')) {
      const urlParts = config.url.split('search=');
      const beforeSearch = urlParts[0];
      const afterSearch = urlParts.slice(1).join('search=');
    
      const modifiedURL = `${beforeSearch}search=${afterSearch.replace(/[+\[\]]/g, (match) => {
        switch (match) {
          case '+':
            return '%2B';
          case '[':
            return '%5B';
          case ']':
            return '%5D';
          default:
            return match;
        }
      })}`;
    
      config.url = modifiedURL;
    }
      
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default axiosInstance;
