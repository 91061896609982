<template>
  <div>
    <v-progress-circular v-if="isUpdating" indeterminate :size="24" />
    <v-autocomplete
      v-if="!isUpdating"
      class="caption selector"
      :class="{
        'dri-selector': driMode,
        'status-selector': statusMode,
        'priority-selector': priorityMode,
      }"
      :no-filter="!driMode"
      :style="{
        backgroundColor: statusColor,
      }"
      :items="dropdownOptions"
      dense
      hide-details
      outlined
      :item-text="driMode ? fullName : 'value'"
      :return-object="driMode"
      :loading="dropdownOptionsLoading"
      :value="currentSelect"
      @change="(selected) => onChange({ item, selected })"
      @click.native.stop.prevent
    ></v-autocomplete>
  </div>
</template>

<script>
// vuex
import { mapActions } from "vuex";
// internal
import { processTask, processAssignment } from "@/utils/newDbUtils";

export default {
  name: "PMDashboardDropdownSelector",
  props: {
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: [Object, String],
      required: true,
    },
    statusMode: {
      type: Boolean,
      required: false,
    },
    priorityMode: {
      type: Boolean,
      required: false,
    },
    driMode: {
      type: Boolean,
      required: false,
    },
    dropdownOptions: {
      type: Array,
      required: true,
    },
    dropdownOptionsLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isUpdating: false,
      currentSelect: null,
    };
  },
  computed: {
    statusColor() {
      return this.dropdownOptions.find((el) => el.value === this.currentSelect)
        ?.color;
    },
    modeKey() {
      if (this.statusMode) return "taskStatus";
      if (this.priorityMode) return "priority";
      if (this.driMode) return "dri";
    },
  },
  watch: {
    value(newValue) {
      this.currentSelect = newValue;
    },
  },
  mounted() {
    this.currentSelect = this.value;
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    fullName(item) {
      return `${item.user.firstName} ${item.user.lastName}`;
    },
    onChange({ item, selected }) {
      this.currentSelect = selected;
      this.isUpdating = true;
      const data = this.driMode
        ? {
            driId: selected.user.id,
          }
        : {
            taskStatus: selected,
          };
      processTask({
        data,
        id: item.id,
        patch: true,
      })
        .then((resp) => {
          if (resp.status > 204) {
            this.isUpdating = false;
            this.handleFlash({ response: resp, show: true });
            console.error(resp);
          } else {
            if (
              selected === "Assigned" ||
              selected === "Delivered" ||
              selected === "Canceled (Billed)" ||
              selected === "Canceled (Not Billed)"
            ) {
              if (item.assignmentResponses.length) {
                const assgnStatusUpdPromises = item.assignmentResponses.map(
                  (assgn) => {
                    return processAssignment({
                      data: {
                        assignmentStatus: selected.includes("Canceled")
                          ? selected.includes("Not")
                            ? "Canceled"
                            : "Delivered"
                          : selected,
                      },
                      id: assgn.id,
                      patch: true,
                    });
                  }
                );
                Promise.all(assgnStatusUpdPromises)
                  .then((res) => {
                    const failedRecords = res.filter((el) => el.status > 204);
                    if (failedRecords.length) {
                      const errMsg = failedRecords
                        .map((el) => {
                          const data = JSON.parse(el.config.data);
                          return `${data.assignmentDetails.assignmentDetail} - ${data.assignmentDetails.geo} / Error: ${el.data.error}`;
                        })
                        .join(".\n");
                      this.handleFlash({
                        response: {
                          data: {
                            message: `Some of statuses of related assignments failed to update:\n${errMsg}`,
                            statusCode: 500,
                          },
                        },
                        show: true,
                      });
                      this.isUpdating = false;
                      return;
                    }
                  })
                  .catch((err) => {
                    this.handleFlash({ response: err, show: true });
                    this.isUpdating = false;
                    return;
                  });
              }
            } else if (selected === "Archived") this.$emit("get-table-data");
            else
              this.$emit(
                "refresh-table-data",
                item.index,
                this.modeKey,
                selected
              );
            this.isUpdating = false;
            this.handleFlash({ response: resp, show: true });
          }
        })
        .catch((err) => {
          this.isUpdating = false;
          this.handleFlash({ response: err, show: true });
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.selector {
  margin: 0 auto;
}

.priority-selector {
  width: 90px;
}

.dri-selector {
  width: 150px;
}

.status-selector {
  width: 120px;
}
</style>
